const ListIconBlack = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='list-filter'>
        <path id='Vector' d='M2 4H14' stroke='#000000' strokeLinecap='round' strokeLinejoin='round' />
        <path
          id='Vector_2'
          d='M4.66669 8H11.3334'
          stroke='#000000'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M6.66669 12H9.33335'
          stroke='#000000'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
  
  export default ListIconBlack;
  