import { useEffect, useRef, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import useOutsideClick from '../hooks/useOutsideClick';
import TickIcon from '../Icons/CheckIcon';
import SearchIcon from '../Icons/SearchIcon';

export interface MultiDropdownItem {
  value: string;
  label: string;
  parentKey: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  selected?: MultiDropdownItem[];
  parentKey: string;
  data: MultiDropdownItem[];
  selectedId?: string;
  onSelect: (selectedItem: MultiDropdownItem) => void;
  clearSubFilter: (parentKey: string) => void;
  searchPlaceholder?: string;
  hideDropdownOptions?: boolean
}

const MultiDropdownWithSearch: React.FC<DropdownProps> = ({
  id,
  title = 'Select',
  data,
  parentKey,
  selectedId,
  onSelect,
  clearSubFilter,
  searchPlaceholder,
  selected,
  hideDropdownOptions,
}: DropdownProps) => {
  useEffect(() => {
    if (!hideDropdownOptions) {
      setIsOpen(true);
    }
    setSearch('');
  }, [data])
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  let isOpenChevronClass = '';
  if (isOpen) {
    isOpenChevronClass = 'rotate-180';
  }
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  const filteredResult =
    search === '' || !search
      ? data
      : data.filter((listItem) => {
        return listItem.label.toLowerCase().includes(search.toLowerCase());
      });

  const arrayIncludes = (list: MultiDropdownItem[], item: string) => {
    let found = false;
    for (const arrayItem of list) {
      if (arrayItem.value == item) {
        found = true;
        break;
      }
    }

    return found;
  };

  return (
    <div ref={dropdownRef} className='relative'>
      <div className='w-52'>
        <button
          id={id}
          aria-label='Toggle dropdown'
          aria-haspopup='true'
          aria-expanded={isOpen}
          type='button'
          onClick={() => setIsOpen(!isOpen)}
          className='flex w-full items-center justify-center rounded-full bg-gray-100 py-2 text-black h-8'
        >
          <div className='ml-2 mr-4 leading-4 font-medium text-sm tracking-wide'>{title}</div>
          <GoChevronDown
            size={20}
            className={`transform duration-500 ease-in-out ${isOpenChevronClass}`}
          />
        </button>
      </div>
      {/* Open */}
      {isOpen && (
        <div
          aria-label='Sub-Dropdown menu'
          className='absolute left-0 top-full z-10 mt-1 w-80  rounded-xl bg-white py-2 shadow-md'
        >
          {searchPlaceholder && searchPlaceholder != '' && (
            <div className='mb-2 flex items-center border-b px-6'>
              <div className='mr-2 grid h-full w-4 place-items-center text-gray-300'>
                <SearchIcon />
              </div>
              <input
                className='peer h-full w-full rounded-lg border-transparent pr-2 text-sm text-gray-700 outline-none focus:border-transparent focus:ring-0'
                type='text'
                id='dropdownsearchinput'
                name='dropdownsearchinput'
                value={search}
                placeholder={searchPlaceholder}
                onChange={(e) => searchData(e)}
              />
            </div>
          )}
          <ul
            role='menu'
            aria-labelledby={id}
            aria-orientation='vertical'
            className='max-h-64 px-3 py-1 leading-10 overflow-y-scroll'
          >

            {filteredResult?.map((item) => {
              const isSelected = selected ? arrayIncludes(selected, item.value) : false;
              return (
                <li
                  key={item.value}
                  // onClick={() => handleChange(item)}
                  onClick={() => onSelect(item)}
                  className={`flex w-full cursor-pointer items-center justify-between px-3 pr-8 hover:rounded-lg hover:bg-gray-200`}
                >
                  <div className='flex items-center'>
                    <input
                      type='checkbox'
                      readOnly
                      checked={isSelected}
                      name='useSameAddress'
                      className='mr-6 mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                    />
                    <span>{item.label}</span>
                  </div>
                  {isSelected && (
                    <div>
                      <TickIcon></TickIcon>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
          <button
            type='button'
            className='ml-2 text-slate-500 mt-2'
            onClick={() => clearSubFilter(parentKey)}
          >
            Clear all
          </button>
        </div>
      )}
    </div>
  );
};

export default MultiDropdownWithSearch;
