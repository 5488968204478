import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Stepper from '../../components/StepperNew';
import AddMessageStep from './AddMessageStep';
import AddRecipientStep from './AddRecipientStep';
import PickGiftStep from './PickGiftStep';
import ConfirmOrderStep from './ConfirmOrderStep';
import GiftFormProvider from './GiftOrderContext';
import Wrapper from '../../components/Wrapper';
import AbandonCart from './AbandonCart';

export const recipientValuesDefault = {
  first_name: '',
  last_name: '',
  company: '',
  title: '',
  email: '',
  internal_id: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
};

const SendGift: React.FC = ({ userQuota }: any) => {
  //Add quota from from user update it via User/Auth Context
  // State to hold the gift data
  const [currentStep, setCurrentStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  const sendGiftSteps = useMemo(
    () => [
      { title: 'Add recipient', route: 'add-recipients' },
      { title: 'Pick gifts', route: 'pick-gift' },
      { title: 'Add message', route: 'add-message' },
      { title: 'Confirm order', route: 'confirm-order' },
    ],
    [],
  );

  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep((prevState: number) => {
        if(prevState < 3)
          prevState = prevState + 1

        return prevState
      });
    } else {
      setCompleted(true);
    }
  };

  useEffect(() => {
    navigate(sendGiftSteps[currentStep].route);
  }, [currentStep, navigate, sendGiftSteps]);

  const handleBack = () => {
    if (currentStep !== 0) setCurrentStep((prevState: number) => prevState - 1);
  };

  return (
    <Wrapper>
      <div className='flex items-center justify-center py-4 text-4xl font-semibold'>
        Send new gift
      </div>
      <div className='w-800 flex items-center justify-center'>
        <Stepper
          steps={sendGiftSteps.map((step) => step.title)}
          currentStep={currentStep}
          complete={completed}
        />
      </div>

      <GiftFormProvider>
        <AbandonCart>
          <Routes>
            <Route
              path='/add-recipients'
              element={
                <AddRecipientStep
                  currentStep={currentStep}
                  handleBack={handleBack}
                  handleNextStep={handleNextStep}
                />
              }
            />
            <Route
              path='/pick-gift'
              element={
                <PickGiftStep
                  currentStep={currentStep}
                  handleBack={handleBack}
                  handleNextStep={handleNextStep}
                />
              }
            />
            <Route
              path='/add-message'
              element={
                <AddMessageStep
                  currentStep={currentStep}
                  handleBack={handleBack}
                  handleNextStep={handleNextStep}
                />
              }
            />
            <Route
              path='/confirm-order'
              element={
                <ConfirmOrderStep
                  currentStep={currentStep}
                  handleBack={handleBack}
                  handleNextStep={handleNextStep}
                />
              }
            />
            <Route
              path='/'
              element={
                <Navigate to={sendGiftSteps[0].route} replace />
              }
            />
          </Routes>
        </AbandonCart>
      </GiftFormProvider>
    </Wrapper>
  );
};

export default SendGift;
