import { useState } from 'react';
import { Description, Label } from '../../../catalyst/fieldset';
import GiftRecipientNotificationModal from './GiftRecipientNotificationModal';

const GiftIncomingNotification = ({ onCheckboxChange, disabled = false, checked = true }: any) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className='grow-0'>
      <Label>Do you want the recipient to receive an email notification?</Label>
      <Description>
        {`Email notification that you are sending a gift with your custom message to the recipient(s). To send it, turn this toggle on. To not send it, turn this toggle off `}
        <span>
          <a
            href='#'
            className='text-blue-600 hover:cursor-pointer hover:underline'
            onClick={() => setShowModal(true)}
          >
            Learn more about notifications here.
          </a>
        </span>
      </Description>
      {showModal && (
        <GiftRecipientNotificationModal
          setShowModal={setShowModal}
          handleUpdate={onCheckboxChange}
          disabled={disabled}
          isChecked={checked}
        />
      )}
    </div>
  );
};

export default GiftIncomingNotification;
