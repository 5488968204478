import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('userData');
    navigate("/login");
  }, [])

  return <></>
}

export default Logout;
