const TrashIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='trash-2'>
      <path
        id='Vector'
        d='M3 6H21'
        stroke='#6B7280'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M19 6V20C19 21 18 22 17 22H7C6 22 5 21 5 20V6'
        stroke='#6B7280'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_3'
        d='M8 6V4C8 3 9 2 10 2H14C15 2 16 3 16 4V6'
        stroke='#6B7280'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_4'
        d='M10 11V17'
        stroke='#6B7280'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_5'
        d='M14 11V17'
        stroke='#6B7280'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default TrashIcon;
