import { ReactNode } from 'react';

type ProductCardProps = {
  title: string;
  imageURL: string;
  children: ReactNode;
};

const ProductCard = ({ title, imageURL, children }: ProductCardProps) => {
  return (
    <div className='flex items-center rounded-lg border border-gray-200 bg-white p-4 shadow-md'>
      <img src={imageURL} alt={title} className='mr-4 h-24 w-24' />
      <div>
        <h2 className='text-base font-medium'>{title}</h2>
        <div className='mt-2'>{children}</div>
      </div>
    </div>
  );
};

export default ProductCard;
