import axios from 'axios';
import { useToast } from '../../components/Toast/ToastProvider';

interface RejectProps {
  quotaRequest: any,
  onReject: () => void,
}

const Reject = ({ quotaRequest, onReject }: RejectProps) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('ACCESS_TOKEN');
  const toast = useToast();

  const rejectQuotaRequest = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .put(baseUrl + '/api/admin/quota/request/' + quotaRequest.id + '/reject', {}, config)
      .then((_response) => {
        onReject()
        toast?.pushSuccess('Quota request rejected successfully!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Quota request reject failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  };

  return <>
    <span
      className='item cursor-pointer px-8 text-gray-500'
      onClick={rejectQuotaRequest}
    >
      Reject
    </span>
  </>
}

export default Reject;
