interface DismissBadgeProps {
  onDismiss: () => void;
  badgeText: string;
}
const DismissBadge = ({ onDismiss, badgeText }: DismissBadgeProps) => (
  <span
    id='badge-dismiss-dark'
    className='leading-4 tracking-wide me-2 inline-flex items-center justify-center bg-gray-100 px-3 py-1 text-sm font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300 h-8 rounded-full'
  >
    {badgeText}
    <button
      type='button'
      className='ms-2 inline-flex items-center rounded-sm bg-transparent p-1 text-sm text-gray-800 dark:hover:bg-gray-600 dark:hover:text-gray-300 h-10 bg-gray-100'
      data-dismiss-target='#badge-dismiss-dark'
      aria-label='Remove'
      onClick={onDismiss}
    >
      <span className="h-5 w-5 flex items-center justify-center border rounded-full bg-gray-200">
        <svg
          className='h-2 w-2 text-black'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 14 14'
        >
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
          />
        </svg>
      </span>
      <span className='sr-only'>Remove badge</span>
    </button>
  </span>
);

export default DismissBadge;
