import React, { useEffect } from 'react';
import { Toast } from './ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faExclamationCircle,
  faCheck,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

const VARIANTS = {
  Info: {
    base: 'bg-white border-blue-500',
    iconstyle: 'text-blue-500 ',
    icon: faInfoCircle,
    name: 'Info',
  },

  Error: {
    base: 'bg-white border-red-500 ',
    iconstyle: 'text-red-500 ',
    icon: faExclamationCircle,
    name: 'Error',
  },

  Warning: {
    base: 'bg-white border-yellow-500',
    iconstyle: 'text-yellow-500 ',
    icon: faExclamationCircle,
    name: 'Warning',
  },

  Success: {
    base: 'bg-white border-green-500',
    iconstyle: 'text-green-500 ',
    icon: faCheck,
    name: 'Success',
  },
};

export type Truncate = 'truncate-1-lines' | 'truncate-2-lines' | 'truncate-3-lines';

export type ToastMessage = {
  id: string;
  lifetime?: number;
  variant?: keyof typeof VARIANTS | undefined;
  onRemove?: (id: string) => void;
  truncate?: Truncate;
} & Toast;

export default function ToastMessage({
  id,
  header,
  message,
  lifetime,
  onRemove,
  truncate = 'truncate-1-lines',
  icon,
  type,
}: ToastMessage) {
  const Var = type
    ? VARIANTS[type]
    : {
        base: 'bg-white border-gray-600 ',
        iconstyle: '',
        icon: icon,
        name: header,
      };

  useEffect(() => {
    if (lifetime && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, lifetime);
    }
  }, [lifetime]);

  return (
    <div
      className={clsx(
        'visible flex w-full flex-row shadow-lg',
        'cursor-pointer rounded-md border-l-4 duration-100',
        'hover:scale-102 transform transition-all',
        Var.base,
        type && 'max-h-40',
      )}
    >
      <div className='flex-no-wrap flex w-full flex-row p-2'>
        {Var.icon && (
          <div className={clsx('flex h-12 w-12 items-center', 'mx-auto select-none text-xl')}>
            <FontAwesomeIcon className={clsx('mx-auto', Var.iconstyle)} icon={Var.icon} />
          </div>
        )}

        <div className='flex-no-wrap flex w-full flex-col px-1'>
          <div className='my-auto flex select-none font-bold'>{Var.name}</div>
          <p
            className={clsx(
              'my-auto -mt-0.5 flex break-all',
              'text-sm text-gray-600',
              typeof message === 'string' && truncate,
            )}
          >
            {message}
          </p>
        </div>
        <div
          onClick={() => onRemove && onRemove(id)}
          className={clsx(
            'mx-auto mr-2 h-12 w-10 items-center',
            'text-center text-lg leading-none',
          )}
        >
          <FontAwesomeIcon
            className={clsx(
              'mx-auto my-auto h-full text-center text-gray-600',
              'transform cursor-pointer hover:scale-105 ',
            )}
            icon={faTimes}
          />
        </div>
      </div>
    </div>
  );
}
