import React, { createContext, useContext, useState } from 'react';

interface RecipientItem {
  first_name: string;
  last_name: string;
  company: string;
  title?: string;
  email: string;
  internal_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state?: string;
  zipcode: string;
  country: string;
  address_id?: string;
  variant_id: string;
  quantity: number;
}

interface VariantData {
  id: string;
  title: string;
  sku: string;
  quantity: number;
}

interface ProductData {
  id: string;
  image_url: string;
  title: string;
  is_digital_gift: boolean;
  thumbnail_url: string;
  line_count: string;
  char_count: string;
  variants: VariantData[];
}

interface MessageData {
  is_custom_email: boolean;
  email_message: string;
  printed_message: string;
  incoming_email: boolean;
}

interface OrderData {
  status: string;
}

type GiftContext = {
  recipients: any;
  giftProduct: ProductData;
  messages: MessageData[];
  order: OrderData;
  setRecipients: React.Dispatch<React.SetStateAction<any>>;
  setGiftProduct: React.Dispatch<React.SetStateAction<ProductData>>;
  setMessages: React.Dispatch<React.SetStateAction<MessageData[]>>;
  setOrder: React.Dispatch<React.SetStateAction<OrderData>>;
};

interface Props {
  children: React.ReactNode;
}

export const recipientValuesDefault = {
  first_name: '',
  last_name: '',
  company: '',
  title: '',
  email: '',
  internal_id: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zipcode: '',
  country: 'US',
  address_id: '',
  phone: '',
  address_warning: [],
  use_same_address: true,
  address_validated: false,
  variant_id: '',
  quantity: 1,
};

export const variantDefault = {
  id: '',
  title: '',
  sku: '',
  quantity: 0,
}

export const productDefault = {
  id: '',
  title: '',
  image_url: '',
  thumbnail_url: '',
  char_count: '',
  line_count: '',
  is_digital_gift: false,
  variants: [variantDefault],
};

export const defaultMessageValues = {
  is_custom_email: false,
  email_message: '',
  printed_message: '',
  incoming_email: false,
};

export const orderDefault = {
  status: '',
}

const GiftContext = createContext<GiftContext>({
  recipients: [{ ...recipientValuesDefault, use_same_address: false }],
  giftProduct: productDefault,
  messages: [defaultMessageValues],
  order: orderDefault,
  setRecipients: () => undefined,
  setGiftProduct: () => undefined,
  setMessages: () => undefined,
  setOrder: () => undefined,
});

export const useGiftForm = () => useContext(GiftContext);

export const GiftFormProvider: React.FC<Props> = ({ children }: Props) => {
  const [recipients, setRecipients] = useState([
    { ...recipientValuesDefault, use_same_address: false },
  ]);
  const [giftProduct, setGiftProduct] = useState(productDefault);
  const [messages, setMessages] = useState([defaultMessageValues]);
  const [order, setOrder] = useState(orderDefault);
  return (
    <GiftContext.Provider
      value={{
        recipients,
        giftProduct,
        messages,
        order,
        setRecipients,
        setGiftProduct,
        setMessages,
        setOrder,
      }}
    >
      {children}
    </GiftContext.Provider>
  );
};

export default GiftFormProvider;
