const CaretUpGreenIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='caret-up'>
      <path
        id='Polygon 1'
        d='M7.24407 4.87287C7.64284 4.41241 8.35716 4.41241 8.75593 4.87287L11.7632 8.34535C12.3241 8.99299 11.864 10 11.0073 10H4.99275C4.136 10 3.67594 8.99299 4.23682 8.34535L7.24407 4.87287Z'
        fill='#14B8A6'
      />
    </g>
  </svg>
);

export default CaretUpGreenIcon;
