import { Button } from '../../components/Button';

const Footer = ({
  currentStep,
  handleBack,
  handleNextStep,
  isNextDisabled,
  loading,
  partialOrder = false,
  footerText = 'Next Step'
}: any) => {
  return (
    <div className='space-between mt-auto flex w-screen justify-end bg-white py-3 sticky bottom-0'>
      {currentStep !== 0 && (
        <Button type='outlineGreen' onClick={handleBack} className='mr-5 hover:text-teal-400 hover:bg-white hover:border-teal-400' disabled={partialOrder}>
          Back
        </Button>
      )}
      {currentStep !== 3 && (
        <Button
          disabled={isNextDisabled}
          htmlType='submit'
          type='solidGreen'
          onClick={handleNextStep}
          className='mr-10 min-w-36 hover:bg-teal-600'
          loading={loading}
        >
          {footerText}
        </Button>
      )}
      {currentStep === 3 && (
        <Button
          disabled={isNextDisabled}
          htmlType='button'
          type='solidGreen'
          onClick={handleNextStep}
          className='mr-10'
        >
          {partialOrder ? 'Submit remaining order' : 'Submit Your Order'}
        </Button>
      )}
    </div>
  );
};

export default Footer;
