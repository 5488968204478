import React from 'react';
import './Timeline.css';
import { format } from 'date-fns';
import ShoppingBasketIcon from '../Icons/ShoppingBasket';
import Ship from '../Icons/Ship';
import Gift from '../Icons/Gift';
import CrossCircle from '../Icons/CrossCircle';
import Avatar from '../Icons/Avatar';
import { OrderDBStatus, ShopifyStatus } from '../../constants/variable';

interface TimelineProps {
    happened_at: string;
    status: string;
    tracking_number?: string;
}

interface TimelineData {
    data: TimelineProps;
    index: number;
    count: number;
    orderID: string | undefined;
    orderPlacedBy: string | undefined;
}


const TimelineItem: React.FC<TimelineData> = ({
    data,
    index,
    count,
    orderID,
    orderPlacedBy,
}) => {
    return (
        <div className='flex justify-between items-center my-3'>
            <div className='w-1/5 text-slate-500 text-right'><time>{data.happened_at ? format(data.happened_at, 'MM/dd/yyyy') : ''}</time></div>
            <div className='w-1/12 flex justify-center items-center circle-box'>
                {index == 0 &&
                    <span className="circle-common" ><ShoppingBasketIcon /></span>
                }
                {index != 0 &&
                    (data.status.toLowerCase() !== OrderDBStatus.SHIPMENT_DELIVERED.toLowerCase() &&
                        data.status.toLowerCase() !== ShopifyStatus.SHIPMENT_DELIVERED.toLowerCase() &&
                        data.status.toLowerCase() !== OrderDBStatus.CANCELLED.toLowerCase() &&
                        data.status.toLowerCase() !== ShopifyStatus.CANCELLED.toLowerCase()) &&
                    <span className="circle-common" ><Ship /></span>
                }
                {index != 0 && 
                    (data.status.toLowerCase() === OrderDBStatus.SHIPMENT_DELIVERED.toLowerCase() ||
                        data.status.toLowerCase() === ShopifyStatus.SHIPMENT_DELIVERED.toLowerCase()) &&
                    <span className="circle-last" ><Gift /></span>
                }
                {index != 0 &&
                    (data.status.toLowerCase() === OrderDBStatus.CANCELLED.toLowerCase() ||
                        data.status.toLowerCase() === ShopifyStatus.CANCELLED.toLowerCase()) &&
                    <span className="circle-common" ><CrossCircle /></span>
                }
            </div>
            <div className="timeline-item w-2/3">
                {index == 0 && (
                    <div className="timeline-item-content">
                        <p className='font-semibold'>Order ID {orderID} Placed</p>
                        <div className='text-slate-500'>
                            {/* <span className='block h-12 w-12 rounded-lg text-base'><Avatar></Avatar></span>  */}
                            <div>{orderPlacedBy}</div>
                        </div>
                    </div>
                )}
                {index != 0 && (data.status.toLowerCase() !== OrderDBStatus.SHIPMENT_DELIVERED.toLowerCase() &&
                    data.status.toLowerCase() !== ShopifyStatus.SHIPMENT_DELIVERED.toLowerCase() &&
                    data.status.toLowerCase() !== OrderDBStatus.CANCELLED.toLowerCase() &&
                    data.status.toLowerCase() !== ShopifyStatus.CANCELLED.toLowerCase()) && (
                        <div className="timeline-item-content">
                            <p className='font-semibold'>Order {data.status}</p>
                            <p className='text-slate-500'>Tracking: {data.tracking_number}</p>
                        </div>
                    )}
                {index != 0 && (
                    data.status.toLowerCase() === OrderDBStatus.SHIPMENT_DELIVERED.toLowerCase() ||
                    data.status.toLowerCase() === ShopifyStatus.SHIPMENT_DELIVERED.toLowerCase() ||
                    data.status.toLowerCase() === OrderDBStatus.CANCELLED.toLowerCase() ||
                    data.status.toLowerCase() === ShopifyStatus.CANCELLED.toLowerCase()
                ) && (
                        <div className="timeline-item-content">
                            <div className='flex flex-col'>
                                <p className='font-semibold'>Order {data.status}</p>
                                {(data.status.toLowerCase() === OrderDBStatus.SHIPMENT_DELIVERED.toLowerCase() ||
                                    data.status.toLowerCase() == ShopifyStatus.SHIPMENT_DELIVERED.toLowerCase()) &&
                                    <p className='text-slate-500'>Finally! you can check it out here</p>
                                }
                            </div>
                        </div>
                    )}
            </div>
        </div>
        // </div>
    )
};

export default TimelineItem;
