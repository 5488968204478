import './stepper.css';
import { TiTick } from 'react-icons/ti';

interface StepperProps {
  steps: string[];
  currentStep: number;
  complete?: boolean;
}

const Stepper = ({ steps, currentStep, complete }: StepperProps) => {
  return (
    <>
      <div className='flex justify-between'>
        {steps?.map((step: string, i: number) => (
          <div
            key={i}
            className={`step-item ${currentStep === i && 'active'} ${
              (i < currentStep || complete) && 'complete'
            } `}
          >
            <div className='step'>{i < currentStep || complete ? <TiTick size={16} /> : i + 1}</div>
            <div className='my-3'>
              <span
                className={`block text-base font-semibold dark:text-white  ${i <= currentStep ? 'text-gray-800' : 'text-gray-500'}`}
              >
                {step}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
