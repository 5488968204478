import Wrapper from '../../components/Wrapper';
import { useEffect, useState } from 'react';
import React from 'react';
import axios from 'axios';
import Pagination from '../../components/tablePagination/Pagination';
import SearchInput from '../../components/SearchInput';
import { format } from 'date-fns';
import { PaginationConstants, QUOTA_REQUEST_SORT_COL_MAP, QuotaRequestStatus } from '../../constants/variable';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';

import { Badge } from '../../catalyst/badge';
import Approve from './Approve';
import Reject from './Reject';

const AdminQuotaRequests = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [quotaRequests, setQuotaRequests] = useState([]);
  const [search, setSearch] = useState<string>();
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [count, setCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [orderBy, setOrderBy] = useState<string>(QUOTA_REQUEST_SORT_COL_MAP.default);
  const [orderByType, setOrderByType] = useState<string>('desc');

  useEffect(() => {
    fetchQuotaRequests(search, true);
  }, [search, orderBy, orderByType]);

  useEffect(() => {
    fetchQuotaRequests(search, false);
  }, [currentPage]);

  const fetchQuotaRequests = async (search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;

    if (orderBy !== '' && orderByType !== '') {
      params.orderBy = orderBy;
      params.orderByType = orderByType;
    }

    const res = await axios
      .get(baseUrl + '/api/admin/quota/request/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setCount(data.data.pagination.total_records);
          setQuotaRequests(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        console.log(error.response.data.error);
      });
  };

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  const applySorting = (columnName: string) => {
    if (orderBy === columnName && orderByType === 'asc') {
      setOrderBy(columnName);
      setOrderByType('desc');
      setCurrentPage(1);
    } else {
      setOrderBy(columnName);
      setOrderByType('asc');
      setCurrentPage(1);
    }
  };

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        <div className='flex justify-between'>
          <div className='flex-auto'>
            <p className='text-left text-2xl font-semibold'>Quota Requests ({count})</p>
          </div>
        </div>
        <div className='py-4'>
          <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
            <TableHead className='overflow-hidden bg-gray-50'>
              <TableRow>
                <TableHeader className='px-2 text-gray-500 '>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(QUOTA_REQUEST_SORT_COL_MAP.sales_rep)}
                  >
                    <span className='mr-1'>SALES REPRESENTATIVE</span>
                    {orderBy === QUOTA_REQUEST_SORT_COL_MAP.sales_rep ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>REASON</TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(QUOTA_REQUEST_SORT_COL_MAP.status)}
                  >
                    <span className='mr-1'>STATUS</span>
                    {orderBy === QUOTA_REQUEST_SORT_COL_MAP.status ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(QUOTA_REQUEST_SORT_COL_MAP.created_at)}
                  >
                    <span className='mr-1'>CREATED AT</span>
                    {orderBy === QUOTA_REQUEST_SORT_COL_MAP.created_at ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(QUOTA_REQUEST_SORT_COL_MAP.updated_at)}
                  >
                    <span className='mr-1'>UPDATED AT</span>
                    {orderBy === QUOTA_REQUEST_SORT_COL_MAP.updated_at ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden bg-white'>
              {quotaRequests.map((data: any) => (
                <TableRow key={data.id}>
                  <TableCell className='text-gray-800'>
                    {data.sales_rep_name}
                  </TableCell>
                  <TableCell className='w-1/4 text-gray-600'>
                    {data.reason}
                  </TableCell>
                  <TableCell className='text-gray-800'>
                    <Badge className='capitalize'
                      color={
                        data.status == QuotaRequestStatus.PENDING
                          ? 'yellow'
                          : data.status == QuotaRequestStatus.APPROVED
                            ? 'greenTxtWht'
                            : 'redTxtWht'
                      }
                    >
                      {data.status}
                    </Badge>
                  </TableCell>
                  <TableCell className='text-gray-800'>
                    {data.created_at ? format(data.created_at, 'MM/dd/yyyy') : ''}
                  </TableCell>
                  <TableCell className='text-gray-800'>
                    {data.updated_at ? format(data.updated_at, 'MM/dd/yyyy') : ''}
                  </TableCell>
                  <TableCell>
                    <div className='inline-flex items-center'>
                    {
                      (data.status == QuotaRequestStatus.PENDING) && <>
                        <Approve
                          quotaRequest={data}
                          onApprove={() => { fetchQuotaRequests(search, false) }}
                        />
                        <Reject
                          quotaRequest={data}
                          onReject={() => { fetchQuotaRequests(search, false) }}
                        />
                      </>
                    }
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {quotaRequests && quotaRequests.length == 0 && (
            <div className='pt-5 text-center text-gray-500'>{'No quota requests found'}</div>
          )}
          {quotaRequests && quotaRequests.length > 0 && (
            <>
              <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
                <Pagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  maxLength={5}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default AdminQuotaRequests;
