import React, { useState, useEffect } from 'react';
import {
  countries,
  AddressFields,
  PHONE_REGEX,
  EMAIL_REGEX,
  US_ZIPCODE_REGEX,
  CANADA_ZIPCODE_REGEX,
} from '../../../constants/variable';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import InputFormField from '../../../components/InputFormField';
import { useGiftForm, recipientValuesDefault } from '../GiftOrderContext';
import pick from 'lodash/pick';
import { Button } from '../../../components/Button';
import Autocomplete from '../../../components/Autocomplete';
import ExclemationCircleFill from '../../../components/Icons/ExclamationCircleFill';
import { formatPhoneNumber, fieldRequired } from '../helper';

type FormValues = {
  first_name: string;
  last_name: string;
  company: string;
  title: string;
  email: string;
  internal_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

const RecipientForm = ({
  recipientIndex,
  recipient,
  onSubmit,
  control,
  resetForm,
  onResetFormComplete,
  validateForm,
  onValidationComplete,
  onCountryChange,
  containerRef,
}: any) => {
  const { recipients, setRecipients } = useGiftForm();
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: recipient['use_same_address']
      ? { ...recipient, ...pick(recipients[0], AddressFields) }
      : recipient,
  });

  const [useSameAddress, setUseSameAddress] = useState(recipient['use_same_address']);
  const [phoneValue, setPhoneValue] = useState('');
  const [showAddressWarning, setAddressWarning] = useState(true);

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  useEffect(() => {
    setPhoneValue(formatPhoneNumber(recipient.phone));
  }, []);

  useEffect(() => {
    if (resetForm) {
      const values = recipientValuesDefault
      if(recipientIndex === 0)
        values['use_same_address'] = false
      reset(values);
      onResetFormComplete(-1);
    }
  }, [resetForm, reset]);

  useEffect(() => {
    if (validateForm) {
      const formValidation = trigger();
      formValidation.then((val) => {
        onValidationComplete(val, recipientIndex);
      });
    }
  }, [validateForm, onValidationComplete]);

  const setAddressFieldsValue = (recipientData: any) => {
    setValue('address_1', recipientData['address_1']);
    setValue('address_2', recipientData['address_2']);
    setValue('city', recipientData['city']);
    setValue('state', recipientData['state']);
    setValue('zipcode', recipientData['zipcode']);
    setValue('country', recipientData['country']);
    onSubmit(getValues(), recipientIndex, true);
    onCountryChange(recipientData['country'], true);
  };

  const handleSameAddressChange = (ev: any) => {
    setRecipients((prevState: any) => {
      recipient = prevState[recipientIndex];
      if (ev.target.checked) {
        prevState[recipientIndex] = {
          ...prevState[recipientIndex],
          ...pick(prevState[0], AddressFields),
        };
      } else {
        prevState[recipientIndex] = {
          ...prevState[recipientIndex],
          ...pick(
            recipientValuesDefault,
            AddressFields.filter((key) => key !== 'country'),
          ),
        };
      }
      setAddressFieldsValue(prevState[recipientIndex]);
      prevState[recipientIndex]['use_same_address'] = ev.target.checked;
      return prevState;
    });
    setUseSameAddress(ev.target.checked);
  };

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;
    if (fieldName === 'phone' && !!fieldValue)
      fieldValue = `+${event.target.value.replace(/\D/g, '')}`; // Remove non-numeric characters
    setValue(fieldName, fieldValue);
    const formValues = getValues();
    onSubmit(formValues, recipientIndex, AddressFields.includes(fieldName));
  };

  const handleCountryChange = (event: any) => {
    onCountryChange(event.target.value, false);
  };

  const isUSCountrySelected = () => {
    const countryValue = getValues('country');
    return !countryValue || countryValue === 'US';
  };

  const formatZipCode = (event: any) => {
    if (!isUSCountrySelected()) {
      let value = event.target.value.replace(/\s/g, ''); // Remove existing spaces
      if (value.length > 3) {
        value = value.slice(0, 3) + ' ' + value.slice(3); // Add space after third character
      }
      event.target.value = value;
      setValue('zipcode', value); // Update form value
    } else {
      setValue('zipcode', event.target.value);
    }
  };

  const validateZipCode = (value: string) => {
    if (isUSCountrySelected()) {
      const regex = US_ZIPCODE_REGEX;
      return regex.test(value) || 'Invalid US zip code';
    } else {
      const regex = CANADA_ZIPCODE_REGEX;
      return regex.test(value) || 'Invalid Canadian postal code';
    }
  };

  const handleAddressChange = (place: any) => {
    const formValues = getValues();
    const country = formValues['country'];
    const state = place.state || formValues['state'];
    const zipcode = place.zipcode || formValues['zipcode'];
    const city = place.city || formValues['city'];
    const address_1 = place.street_line || formValues['address_1'];
    const address_2 = place.secondary || formValues['address_2'];
    setAddressFieldsValue({
      address_1,
      address_2,
      city,
      state,
      country,
      zipcode,
      address_id: place.address_id || recipient.address_id
    });
  };

  const onAddressInputChange = (text: string) => {
    setValue('address_1', text);
    const formValues = getValues();
    onSubmit(formValues, recipientIndex, true);
  };

  const uniqueEmailValidation = (value: string) => {
    if (userData?.role === 'ADMIN' || userData?.role === 'MANAGER') { return }

    if (
      recipients.find(
        (recipient: any, idx: number) => idx !== recipientIndex && recipient.email === value,
      )
    )
      return 'Please enter unique email for recipients';
  };

  const handleNumberChange = (event: any) => {
    setPhoneValue(event.target.value);
  };

  return (
    <div className='mb-5 p-1' ref={containerRef}>
      <form id={`recipient_${recipientIndex}`} key={`recipient_${recipientIndex}`}>
        <div className='my-3 grid grid-flow-col gap-10'>
          <InputFormField
            name='first_name'
            label='First Name'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              validate: {
                charLimit: (value: string) =>
                  value.length <= 100 || 'The first name should not exceed 100 characters',
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
          />
          <InputFormField
            name='last_name'
            label='Last Name'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              validate: {
                charLimit: (value: string) =>
                  value?.length <= 100 || 'The last name should not exceed 100 characters',
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
          />
        </div>
        <div className='my-3 grid grid-flow-col gap-10'>
          <InputFormField
            label='Company'
            name='company'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              validate: {
                charLimit: (value: string) =>
                  value.length <= 100 || 'The company should not exceed 100 characters',
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
          />
          <InputFormField label='Title' name='title' register={register} isRequiredLabel={false} errors={errors} />
        </div>

        <div className='my-3 grid grid-flow-col gap-10'>
          <InputFormField
            label='Recipient’s Email'
            name='email'
            inputType='text'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email address',
              },
              validate: {
                unique: uniqueEmailValidation,
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
            helpText={"We'll send shipping notifications only"}
          />
          <InputFormField
            label='Phone Number'
            name='phone'
            inputType='tel'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              pattern: {
                value: PHONE_REGEX,
                message: 'Invalid Phone number',
              },
              validate: {
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
            placeholder='+1 (123) 456-7890'
            mask='+1 (999) 999-9999'
            phoneCode='+1'
            onChange={handleNumberChange}
            value={phoneValue}
          />
        </div>
        <div className='my-3 w-full'>
          <InputFormField
            label='Internal ID (Found on the Customer Record URL in NSCorp)'
            name='internal_id'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              validate: {
                charLimit: (value: string) =>
                  value.length <= 15 || 'The internal ID should not exceed 15 characters',
                present: fieldRequired
              },
            }}
            errors={errors}
            onBlur={handleBlur}
            helpText={"We'll never share your clients details."}
          />
        </div>
        {recipientIndex !== 0 && (
          <div className='flex'>
            <input
              type='checkbox'
              checked={useSameAddress}
              name='useSameAddress'
              onChange={(ev) => handleSameAddressChange(ev)}
              className='mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
            />
            <label className='ms-3 text-sm text-gray-500 dark:text-neutral-400'>
              Use the same shipping address as Recipient 1
            </label>
          </div>
        )}
        <div style={{ display: useSameAddress ? 'none' : 'block' }} className='my-3'>
          <div className='w-full'>
            <div style={{ display: 'none' }}>
              <InputFormField
                label='Address Line 1'
                name='address_1'
                isRequired={true}
                errors={errors}
                register={register}
                validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
                onBlur={handleBlur}
                onChange={handleAddressChange}
              />
            </div>
            <div className='relative'>
              <div className='flex items-center justify-between'>
                <label className='mb-2 block text-sm font-medium dark:text-white'>
                  Address Line 1
                </label>
                <span className='mb-2 block text-sm text-gray-500 dark:text-neutral-500'>
                  Required
                </span>
              </div>
              {errors && errors['address_1'] && (
                <div className='pointer-events-none absolute inset-y-0 right-2 top-2 flex items-center pl-3'>
                  <ExclemationCircleFill />
                </div>
              )}
              <Autocomplete
                onChange={handleAddressChange}
                onInputChange={onAddressInputChange}
                selectedCountry={recipients[0].country.toLowerCase().trim()}
                value={getValues('address_1')}
                key={`selected-{${getValues('country')}}`}
                onFocus={(evt: any) => setAddressWarning(false)}
                clearField={resetForm}
                classes={
                  errors && errors['address_1']
                    ? 'border-red-500 shadow-md shadow-red-200'
                    : 'border-gray-200'
                }
              />
              {errors && errors['address_1'] && (
                <p className='mt-2 text-xs text-red-500'>{errors['address_1'].message}</p>
              )}
            </div>
          </div>
          <div className='my-3 w-full'>
            <InputFormField
              label='Address Line 2'
              name='address_2'
              register={register}
              onBlur={handleBlur}
              isRequiredLabel={false}
              errors={errors}
              onFocus={(evt: any) => setAddressWarning(false)}
            />
            { showAddressWarning && recipient['address_warning'] && recipient['address_warning'].length > 0 &&
              <div className="bg-orange-100 border border-orange-400 px-4 py-3 rounded relative mt-2" role="alert">
                <strong className="font-bold">Please make sure the address is correct before you proceed</strong>
                <ul className="list-disc list-inside mt-2">
                  { recipient['address_warning'].map((message: string, index: number) => (
                    <li key='number'>{message}</li>
                  ))}
                </ul>
              </div>
            }
          </div>
          <div className='my-3 grid grid-flow-col gap-10'>
            <InputFormField
              label='City'
              name='city'
              isRequired={true}
              register={register}
              validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
              errors={errors}
              onBlur={handleBlur}
            />
            <InputFormField
              inputType='select'
              options={
                countries.find((country) => country.value === getValues('country'))?.states ?? [
                  { name: 'Select country first', value: '' },
                ]
              }
              label={isUSCountrySelected() ? 'State' : 'Province'}
              name='state'
              isRequired={true}
              register={register}
              validation={{ required: 'This field is required', validate: fieldRequired }}
              errors={errors}
              onBlur={handleBlur}
            />
          </div>
          <div className='grid grid-flow-col gap-10'>
            <InputFormField
              label='Zip Code'
              name='zipcode'
              isRequired={true}
              register={register}
              validation={{
                required: 'This field is required',
                validate: {
                  valid: validateZipCode,
                  present: fieldRequired
                }
              }}
              errors={errors}
              onBlur={handleBlur}
              onChange={formatZipCode}
            />
            <InputFormField
              inputType='select'
              options={[{ name: 'Select a country', value: '' }].concat(countries)}
              label='Country'
              name='country'
              isRequired={true}
              register={register}
              validation={{ required: 'This field is required', validate: fieldRequired }}
              errors={errors}
              onBlur={handleBlur}
              onChange={handleCountryChange}
              disabled={recipientIndex !== 0}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecipientForm;
