import { useState } from 'react';
import Card from '../../../components/Card';
import { useGiftForm } from '../GiftOrderContext';
import { useModal } from '../../../hooks/useModal';
import ProductVariants from './ProductVariants'

export const DIGITAL_GIFT = 'Digital gift';

const ProductList = ({
  products,
  loading,
  zoomTargetRef,
  hideOutOfStock,
  setRecipientVariant,
}: any) => {
  const { recipients, giftProduct, setGiftProduct } = useGiftForm();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const selected = selectedProduct || giftProduct;

  const updateProductData = (product: any) => {
    setGiftProduct({
      id: product.id,
      title: product.title,
      image_url: product.image?.url,
      is_digital_gift: product.tags.includes(DIGITAL_GIFT),
      thumbnail_url: product.image?.thumbnail,
      char_count: product.charCount,
      line_count: product.lineCount,
      variants: product.variants,
    });
  };

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <ProductVariants
          product={selectedProduct}
          hideOutOfStock={hideOutOfStock}
          setRecipientVariant={setRecipientVariant}
          updateProductData={updateProductData}
          closeModal={() => { setSelectedProduct(null); closeModal() }}
        />
      </>
    ),
  });

  const totalQty = recipients.reduce((qty: number, r: any) => (qty + parseInt(r.quantity || 0, 10)), 0)

  return (
    <>
      {!!products?.length &&
        products.map((product: any, index: number) => {
          const hasSingleVariant = product?.variants?.length == 1;
          const firstVariantQty = product?.variants[0]?.quantity;
          const productTotalQty = product.variants.reduce((qty: number, v: any) => (qty + v.quantity), 0)
          const stockAvailable = product?.tags?.includes(DIGITAL_GIFT) || (productTotalQty >= totalQty)

          return (
            <div
              key={product?.id}
              className={
                selected?.id === product?.id
                  ? 'rounded-2xl border border-blue-700 bg-blue-200 '
                  : 'rounded-2xl border bg-white shadow-lg'
              }
            >
              <div
                className={stockAvailable ? 'cursor-pointer' : 'cursor-not-allowed'}
                onClick={() => {
                  if (stockAvailable)
                    if (hasSingleVariant) {
                      updateProductData(product);
                      Array.from({ length: recipients.length }, (x, i) => (
                        setRecipientVariant(i, product.variants[0])
                      ))
                    } else {
                      setSelectedProduct(product);
                      openModal()
                    }
                }}
              >
                <Card
                  cardTitle={product?.title}
                  imageUrl={product?.image?.url}
                  selectedProduct={selected?.id === product?.id}
                  tags={product?.tags || []}
                  column={index % 4}
                  zoomTargetRef={zoomTargetRef}
                >
                  <p className='mt-1 flex items-center text-xs font-normal dark:text-neutral-400'>
                    {product?.tags?.includes(DIGITAL_GIFT) || ((hasSingleVariant) && (firstVariantQty >= totalQty)) ? (
                      <p>
                        {product?.tags?.includes(DIGITAL_GIFT)
                          ? 'Unlimited'
                          : `${firstVariantQty} in stock`}
                        {selected?.id === product?.id && (
                          <span className='text-sm font-medium'>{` / ${totalQty} selected`}</span>
                        )}
                      </p>
                    ) : (
                      <span className={`text-xs font-medium ${hasSingleVariant ? "text-red-800" : ''}`}>
                        { hasSingleVariant ? "Not enough products in stock" : "Click to view stock"}
                      </span>
                    )}
                  </p>
                </Card>
              </div>
            </div>
          )
        })
      }
      {modal}
    </>
  );
};

export default ProductList;
