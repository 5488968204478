import React, { useState, useEffect } from 'react';
import ModalBackdrop from '../../../components/ModalRevisited';
import { Button } from '../../../components/Button';

const TemplateSwitchingModal = ({ setShowModal, textToCopy }: any) => {
  const [showAgain, setShowAgain] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setShowAgain(event.target.checked);
    sessionStorage.setItem('skipTemplateCopyModal', event.target.checked);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ModalBackdrop isOpen={true} onClose={handleCloseModal} width='w-1/3'>
      <div className='mb-5 flex flex-row justify-center'>
        <div className='text-center'>
          <div className='flex items-center justify-center'>
            <img src='/assets/asking-question.svg' />
          </div>
          <div className='mt-5 text-xl font-bold'>
            Switching templates alert
          </div>
          <div className='mt-5 text-base font-medium'>
            {`To preserve your previously typed text, it has been copied automatically. You can simply paste it into the text field after switching templates by pressing Ctrl+V (Windows/Linux) or Cmd+V (Mac).`}
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              id="showAgainCheckbox"
              checked={showAgain}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="showAgainCheckbox">{`Don't show this modal again`}</label>
          </div>
          <div className='mt-5 flex justify-around'>
            <Button onClick={handleCloseModal}>Got it!</Button>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default TemplateSwitchingModal;
