export const formatPhoneNumber = (number: string) => {
  const match = number.match(/^(\+\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return number;
}

export const isPresent = (value: string) => {
  return !!value && value.toString().trim().length > 0;
}

export const fieldRequired = (value: string) => {
  if (!isPresent(value)) return 'This field is required';
};

export const isEmojiPresent = (text: string) => {
  return text.match(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu);
}

export const restrictEmoji = (text: string) => {
  if(text && isEmojiPresent(text))
    return 'Emojis are not supported';
}
