import ModalBackdrop from '../../../components/ModalRevisited';
import { Button } from '../../../components/Button';

const EmailSampleModal = ({ onClose }: any) => {
  return (
    <ModalBackdrop
      title='Custom Email Notifications'
      isOpen={true}
      onClose={onClose}
    >
      <div className='mb-5 flex flex-row justify-center'>
        <div className='text-center'>
          <div className='flex items-center justify-center'>
            <img src='/assets/email-note.png' />
          </div>
          <div className='item-center my-5 flex justify-center'>
            <Button onClick={onClose}>Got it!</Button>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
}

export default EmailSampleModal;
