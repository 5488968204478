import { HTMLProps } from 'react';

export type Props = HTMLProps<HTMLAnchorElement> & { active?: boolean, isPrev?: boolean, isNext?: boolean };

export default function PageLink({
    className,
    active,
    disabled,
    children,
    isPrev, 
    isNext,
    ...otherProps
}: Props) {
    let bgClass = ''
    if (active) {
        bgClass = 'bg-gray-200'
    } else {
        bgClass = 'bg-white'
    }

    let boxWidth = 'w-10';
    let borderLeft = 'border-l';
    if (isPrev) {
        boxWidth = 'w-28'
        borderLeft = ''
    } else if (isNext) {
        boxWidth = 'w-20'
    }

    if (disabled) {
        return <span className={`flex items-center justify-center ${boxWidth} h-10 font-medium text-base leading-6 text-gray-500 relative ${bgClass} cursor-pointer hover:bg-gray-200 focus:bg-gray-200 focus:z-20 active:z-10 active:bg-gray-200 ${borderLeft}`}>{children}</span>;
    }

    return (
        <a
            className={`flex items-center justify-center ${boxWidth} h-10 font-medium text-base leading-6 text-gray-500 relative ${bgClass} cursor-pointer hover:bg-gray-200 focus:bg-gray-200 focus:z-20 active:z-10 active:bg-gray-200 ${borderLeft}`}
            aria-current={active ? 'page' : undefined}
            {...otherProps}
        >
            {children}
        </a>
    );
}