import React, { useState, useEffect, useImperativeHandle } from 'react';
import {
  CANADA_ZIPCODE_REGEX,
  countries,
  EMAIL_REGEX,
  PHONE_REGEX,
  TEMPLATE_CHAR_LIMIT_DEFAULT,
  US_ZIPCODE_REGEX,
} from '../../../constants/variable';
import { useFormContext } from 'react-hook-form';
import InputFormField from '../../../components/InputFormField';
import pick from 'lodash/pick';
import Autocomplete from '../../../components/Autocomplete';
import ExclemationCircleFill from '../../../components/Icons/ExclamationCircleFill';
import { recipientValuesDefault, useGiftForm } from '../GiftOrderContext';
import { fieldRequired } from '../helper';

type FormValues = {
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  title: string;
  email: string;
  internal_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  printed_message: string;
  email_message: string;
  quantity: number;
};

export const AddressFields = ['address_1', 'address_2', 'city', 'state', 'zipcode', 'country'];

const ConfirmRecipientForm = ({ recipientIndex, recipients, isMessageForm, isDigital }: any) => {
  const recipient = recipients[recipientIndex];
  const {
    register,
    setValue,
    getValues,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormValues>();

  useEffect(() => {
    setValue('phone', recipient.phone);
  }, []);

  const { messages, giftProduct } = useGiftForm();

  const characterLimit = Number(giftProduct.char_count) || TEMPLATE_CHAR_LIMIT_DEFAULT;
  const lineCountLimit = Number(giftProduct.line_count) || 1000;

  const selectedVariant = giftProduct.variants.find((variant) => (variant.id === recipient.variant_id))

  const [availableQty, setAvailableQty] = useState(selectedVariant?.quantity || 0);

  const [printedMessageLength, setPrintedMessageLength] = useState(0);
  const [showAddressWarning, setAddressWarning] = useState(true);

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  useEffect(() => {
    const selectedQty = recipients.reduce((qty: number, rec: any, index: number) => {
      if (index == recipientIndex) {
        return qty
      } else {
        return (rec.variant_id == recipient.variant_id) ? qty + rec.quantity : qty
      }
    }, 0)

    const userCountry = recipients[0]['country'];
    const remainingQuota = Number(userData?.quota?.[userCountry]?.balance);
    setAvailableQty(Math.min(Math.min(availableQty, remainingQuota) - selectedQty, 10));
  }, [recipients])

  useEffect(() => {
    if (typeof recipientIndex === 'number') {
      if (isMessageForm)
        reset({
          //order of adding props to be not changed for templates to show up
          ...recipient,
          email_message: messages[recipientIndex].email_message,
          ...(!isDigital
            ? { printed_message: messages[recipientIndex].printed_message }
            : { printed_message: '' }),
        });
      else reset(recipient);
    }
  }, []);

  const setAddressFieldsValue = (recipientData: any) => {
    setValue('address_1', recipientData['address_1']);
    setValue('address_2', recipientData['address_2']);
    setValue('city', recipientData['city']);
    setValue('state', recipientData['state']);
    setValue('zipcode', recipientData['zipcode']);
    setValue('country', recipientData['country']);
  };

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;
    if (fieldName === 'phone' && !!fieldValue)
      fieldValue = `+${event.target.value.replace(/\D/g, '')}`; // Remove non-numeric characters
    setValue(fieldName, fieldValue);
    trigger();
  };

  const isUSCountrySelected = () => recipient.country === 'US';

  const formatZipCode = (event: any) => {
    if (!isUSCountrySelected()) {
      let value = event.target.value.replace(/\s/g, ''); // Remove existing spaces
      if (value.length > 3) {
        value = value.slice(0, 3) + ' ' + value.slice(3); // Add space after third character
      }
      event.target.value = value;
      setValue('zipcode', value); // Update form value
    } else {
      setValue('zipcode', event.target.value);
    }
  };

  const validateZipCode = (value: string) => {
    if (isUSCountrySelected()) {
      const regex = US_ZIPCODE_REGEX;
      return regex.test(value) || 'Invalid US zip code';
    } else {
      const regex = CANADA_ZIPCODE_REGEX;
      return regex.test(value) || 'Invalid Canadian postal code';
    }
  };

  const handleAddressChange = (place: any) => {
    const formValues = getValues();
    const country = formValues['country'];
    const state = place.state || formValues['state'];
    const zipcode = place.zipcode || formValues['zipcode'];
    const city = place.city || formValues['city'];
    const address_1 = place.street_line || formValues['address_1'];
    const address_2 = place.secondary || formValues['address_2'];
    setAddressFieldsValue({
      address_1,
      address_2,
      city,
      state,
      country,
      zipcode,
      address_id: place.address_id || recipient.address_id
    });
  };

  const onAddressInputChange = (text: string) => {
    setValue('address_1', text);
  };

  const uniqueEmailValidation = (value: string) => {
    if (userData?.role === 'ADMIN' || userData?.role === 'MANAGER') { return }
    if (
      recipients.find(
        (recipient: any, idx: number) => idx !== recipientIndex && recipient.email === value,
      )
    )
      return 'Please enter unique email for recipients';
  };

  const numberOfLinesExceeded = (value: string) => {
    const lines = value.split('\n');
    return lines.length > lineCountLimit;
  };

  const handlePrintedMessageChange = (ev: any) => {
    let text = ev.target.value;
    if (text.length > characterLimit) {
      text = text.slice(0, characterLimit);
    }
    clearErrors('printed_message');
    setPrintedMessageLength(text.length);
    setValue('printed_message', text);
  };

  const getQuantityOptions = () => {
    const options: any = [];
    for (let index = 0; index < availableQty; index++) {
      options.push({ name: index + 1, value: index + 1})
    }
    return options;
  }

  return (
    <div className='mb-10'>
      <div className='my-3 grid grid-flow-col gap-10'>
        <InputFormField
          name='first_name'
          label='First Name'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            validate: {
              charLimit: (value: string) =>
                value.length <= 100 || 'The first name should not exceed 100 characters',
              present: fieldRequired
            }
          }}
          errors={errors}
          onBlur={handleBlur}
        />
        <InputFormField
          name='last_name'
          label='Last Name'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            validate: {
              charLimit: (value: string) =>
                value?.length <= 100 || 'The last name should not exceed 100 characters',
              present: fieldRequired
            }
          }}
          errors={errors}
          onBlur={handleBlur}
        />
      </div>
      <div className='my-3 grid grid-flow-col gap-10'>
        <InputFormField
          label='Company'
          name='company'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            validate: {
              charLimit: (value: string) =>
                value.length <= 100 || 'The company should not exceed 100 characters',
              present: fieldRequired
            }
          }}
          errors={errors}
          onBlur={handleBlur}
        />
        <InputFormField label='Title' name='title' register={register} isRequiredLabel={false} errors={errors} />
      </div>
      <div className='my-3 grid grid-flow-col gap-10'>
        <InputFormField
          label='Recipient’s Email'
          name='email'
          inputType='text'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email address',
            },
            validate: {
              unique: uniqueEmailValidation,
              present: fieldRequired
            }
          }}
          errors={errors}
          onBlur={handleBlur}
        />
        <InputFormField
          label='Phone Number'
          name='phone'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            pattern: {
              value: PHONE_REGEX,
              message: 'Invalid Phone number',
            },
            validate: {
              present: fieldRequired
            }
          }}
          errors={errors}
          onBlur={handleBlur}
          inputType='tel'
          placeholder='+1 (123) 456-7890'
          mask='+1 (999) 999-9999'
          phoneCode='+1'
        />
      </div>
      <div className='my-3 w-full'>
        <InputFormField
          label='Internal ID (Found on the Customer Record URL in NSCorp)'
          name='internal_id'
          isRequired={true}
          register={register}
          validation={{
            required: 'This field is required',
            validate: {
              charLimit: (value: string) =>
                value.length <= 15 || 'The internal ID should not exceed 15 characters',
              present: fieldRequired
            },
          }}
          errors={errors}
          onBlur={handleBlur}
        />
      </div>
      <div className='my-3'>
        <div className='w-full'>
          <div style={{ display: 'none' }}>
            <InputFormField
              label='Address Line 1'
              name='address_1'
              isRequired={true}
              errors={errors}
              register={register}
              validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
              onBlur={handleBlur}
              onChange={handleAddressChange}
            />
          </div>
          <div className='relative'>
            <div className='flex items-center justify-between'>
              <label className='mb-2 block text-sm font-medium dark:text-white'>
                Address Line 1
              </label>
              <span className='mb-2 block text-sm text-gray-500 dark:text-neutral-500'>
                Required
              </span>
            </div>
            {errors && errors['address_1'] && (
              <div className='pointer-events-none absolute inset-y-0 right-2 top-2 flex items-center pl-3'>
                <ExclemationCircleFill />
              </div>
            )}
            <Autocomplete
              onChange={handleAddressChange}
              onInputChange={onAddressInputChange}
              selectedCountry={recipient?.country?.toLowerCase().trim() ?? ['us', 'ca']}
              value={getValues('address_1')}
              key={`selected-{${getValues('country')}}`}
              onFocus={(evt: any) => setAddressWarning(false)}
              classes={
                errors && errors['address_1']
                  ? 'border-red-500 shadow-md shadow-red-200'
                  : 'border-gray-200'
              }
            />
            {errors && errors['address_1'] && (
              <p className='mt-2 text-xs text-red-500'>{errors['address_1'].message}</p>
            )}
          </div>
        </div>
        <div className='my-3 w-full'>
          <InputFormField
            label='Address Line 2'
            name='address_2'
            register={register}
            onBlur={handleBlur}
            isRequiredLabel={false}
            onFocus={(evt: any) => setAddressWarning(false)}
            errors={errors}
          />
        </div>
        { showAddressWarning && recipient['address_warning'] && recipient['address_warning'].length > 0 &&
            <div className="bg-orange-100 border border-orange-400 px-4 py-3 rounded relative mt-2" role="alert">
              <strong className="font-bold">Please make sure the address is correct before you proceed</strong>
              <ul className="list-disc list-inside mt-2">
                { recipient['address_warning'].map((message: string, index: number) => (
                  <li key='number'>{message}</li>
                ))}
              </ul>
            </div>
          }
        <div className='my-3 grid grid-flow-col gap-10'>
          <InputFormField
            label='City'
            name='city'
            isRequired={true}
            register={register}
            validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
            errors={errors}
            onBlur={handleBlur}
          />
          <InputFormField
            inputType='select'
            options={
              countries.find((country) => country.value === getValues('country'))?.states ?? [
                { name: 'Select country first', value: '' },
              ]
            }
            label={isUSCountrySelected() ? 'State' : 'Province'}
            name='state'
            isRequired={true}
            register={register}
            validation={{ required: 'This field is required', validate: fieldRequired }}
            errors={errors}
            onBlur={handleBlur}
          />
        </div>
        <div className='my-3 grid grid-flow-col gap-10'>
          <InputFormField
            label='Zip Code'
            name='zipcode'
            isRequired={true}
            register={register}
            validation={{
              required: 'This field is required',
              validate: {
                valid: validateZipCode,
                present: fieldRequired
              }
            }}
            errors={errors}
            onBlur={handleBlur}
            onChange={formatZipCode}
          />
          <InputFormField
            inputType='select'
            options={[{ name: 'Select a country', value: '' }].concat(countries)}
            label='Country'
            name='country'
            isRequired={true}
            register={register}
            validation={{ required: 'This field is required', validate: fieldRequired }}
            errors={errors}
            onBlur={handleBlur}
            disabled
          />
        </div>
        <div className="grid grid-flow-col gap-10 ">
          <div className='max-w-[50%]'>
            <InputFormField
              label='Quantity'
              name='quantity'
              inputType='select'
              options={getQuantityOptions()}
              isRequired={true}
              register={register}
              validation={{
                required: 'This field is required',
                validate: {
                  minimum: (value: number) => (value >= 1 || 'Minimum order quantity is 1'),
                  maximum: (value: number) => (value <= availableQty || `Maximum available quantity is ${availableQty}`),
                  present: fieldRequired
                }
              }}
              errors={errors}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      {messages.length > 1 && (
        <>
          {!isDigital && (
            <div className='my-3'>
              <InputFormField
                name='printed_message'
                label='Printed Message'
                register={register}
                errors={errors}
                onBlur={handleBlur}
                inputType={'textarea'}
                validation={{
                  required: 'This field is required',
                  validate: {
                    charLimit: (value: string) =>
                      !numberOfLinesExceeded(value) ||
                      `The gift has ${lineCountLimit} lines limitation for this note`,
                    present: fieldRequired
                  }
                }}
                onChange={handlePrintedMessageChange}
                isRequiredLabel={false}
              />
              <p>
                {characterLimit > printedMessageLength ? characterLimit - printedMessageLength : 0}{' '}
                characters left
              </p>
            </div>
          )}
          <div className='my-3'>
            <InputFormField
              name='email_message'
              label='Your email message'
              register={register}
              errors={errors}
              onBlur={handleBlur}
              inputType={'textarea'}
              validation={isDigital ? { required: 'This field is required', validate: { present: fieldRequired } } : {}}
              isRequiredLabel={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmRecipientForm;
