import { useState, useEffect } from 'react';
import axios from 'axios';
import TemplateForm from './TemplateForm';
import { useGiftForm, recipientValuesDefault } from '../GiftOrderContext';
import Footer from '../Footer';
import DigitalMessage from './DigitalMessage';

const AddMessage = ({ currentStep, handleBack, handleNextStep }: any) => {
  const { recipients, giftProduct, setMessages, messages, setRecipients } = useGiftForm();
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [isMultipleTemplate, setMultipleTemplate] = useState(messages.length !== 1);
  const [validateForm, setValidateForm] = useState(false);
  const [allMessagesValid, setAllMessagesValid] = useState([false]);
  const token = localStorage.getItem('ACCESS_TOKEN');
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (!giftProduct?.is_digital_gift) {
      const source = axios.CancelToken.source();
      fetchMessageTemplates(source);
      return () => {
        source.cancel('Pervious request canceled');
      };
    }
  }, [giftProduct]);

  const fetchMessageTemplates = async (source: any) => {
    try {
      await axios
        .get(baseUrl + '/api/templates/listTemplates?paginated=false', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((res) => {
          setMessageTemplates(res.data.data.data);
        });
    } catch (error: any) {
      if (error?.response?.data?.status === 401) {
        localStorage.removeItem('userData');
        localStorage.removeItem('ACCESS_TOKEN');
        window.location.href = '/login';
        window.location.reload();
      }
      console.error('Error fetching message templates:', error);
    }
  };

  const formatMessages = () => {
    if (messageTemplates.length > 0) {
      const templates = [{ id: '', title: 'Select', body: '' }, ...messageTemplates];
      return templates.map((template: any) => {
        return {
          name: (
            <span>
              {template.title}
              <span className='text-sm text-gray-500 dark:text-neutral-500'>{` ${template.body}`}</span>
            </span>
          ),
          value: template.body,
          ...template,
        };
      });
    }
    return [];
  };

  const handleNext = () => {
    if (currentStep === 2) {
      handleNextStep();
    }
  };

  const handleSubmit = (data: any, messageIndex: any) => {
    const details = giftProduct.is_digital_gift
      ? { printed_message: '', incoming_message: true }
      : {};
    setMessages((prevState: any) => {
      prevState[messageIndex] = { ...prevState[messageIndex], ...data, ...details };
      return prevState;
    });
  };

  const handleCustomizeForEachClick = () => {
    if (isMultipleTemplate) {
      setMessages((prevState: any) => {
        return [prevState[0]];
      });
    }
    setMultipleTemplate(!isMultipleTemplate);
  };

  const validateFormsAndHandleNextStep = () => {
    setValidateForm(true);
    if (allMessagesValid.every((val) => val)) handleNext();
  };

  const onValidationComplete = (formValid: boolean, index: number) => {
    allMessagesValid[index] = formValid;
    setAllMessagesValid(allMessagesValid);
    setValidateForm(false);
    setTimeout(() => {
      if (allMessagesValid.every((val) => val)) handleNext();
    }, 500);
  };

  return (
    <>
      <div className='flex w-full flex-col items-center justify-center p-2'>
        {giftProduct.is_digital_gift && (
          <>
            {isMultipleTemplate ? (
              <>
                {recipients?.map((recipient: any, index: number) => (
                  <DigitalMessage
                    recipient={recipient}
                    messageIndex={index}
                    isMultipleTemplate={true}
                    key={`template_${index}_form`}
                    onSubmit={handleSubmit}
                    messages={messages}
                    validateForm={validateForm}
                    onValidationComplete={onValidationComplete}
                    handleCustomizeForEachClick={handleCustomizeForEachClick}
                  />
                ))}
              </>
            ) : (
              <DigitalMessage
                isMultipleTemplate={false}
                recipient={recipientValuesDefault || recipients[0]}
                onSubmit={handleSubmit}
                messages={messages}
                validateForm={validateForm}
                onValidationComplete={onValidationComplete}
                handleCustomizeForEachClick={handleCustomizeForEachClick}
              />
            )}
          </>
        )}
        <>
          {!!messageTemplates.length && (
            <>
              {isMultipleTemplate ? (
                <>
                  {recipients?.map((recipient: any, index: number) => (
                    <TemplateForm
                      templates={formatMessages()}
                      recipient={recipient}
                      messageIndex={index}
                      isMultipleTemplate={true}
                      key={`template_${index}_form`}
                      onSubmit={handleSubmit}
                      messages={messages}
                      validateForm={validateForm}
                      onValidationComplete={onValidationComplete}
                      handleCustomizeForEachClick={handleCustomizeForEachClick}
                    />
                  ))}
                </>
              ) : (
                <TemplateForm
                  templates={formatMessages()}
                  isMultipleTemplate={false}
                  recipient={recipientValuesDefault || recipients[0]}
                  messageIndex={0}
                  onSubmit={handleSubmit}
                  messages={messages}
                  validateForm={validateForm}
                  onValidationComplete={onValidationComplete}
                  handleCustomizeForEachClick={handleCustomizeForEachClick}
                />
              )}
            </>
          )}
        </>
      </div>
      <Footer
        currentStep={currentStep}
        handleBack={handleBack}
        handleNextStep={validateFormsAndHandleNextStep}
      />
    </>
  );
};

export default AddMessage;
