import axios from 'axios';
import { useEffect, useState } from 'react';

import InputFormField from '../InputFormField';
import { Button } from '../Button';
import { Button as Button1 } from '../../catalyst/button';

import { useForm } from 'react-hook-form';
import { useModal } from '../../hooks/useModal';
import { useToast } from '../../components/Toast/ToastProvider';

type FormValues = {
  reason: string;
};

const defaultFormValues = {
  reason: '',
};

const RequestQuota = () => {
  const toast = useToast();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('ACCESS_TOKEN');

  const [quotaRequest, setQuotaRequest] = useState<any>();

  useEffect(() => {
    myPendingQuotaRequest()
  }, [])

  const onModalClose = () => {
    reset(defaultFormValues);
    closeModal();
  };

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });

  const createQuotaRequest = () => {
    const reason = getValues().reason;

    const quotaRequestData = {
      reason: reason,
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(baseUrl + '/api/quota/request/create', quotaRequestData, config)
      .then((response) => {
        onModalClose();
        setQuotaRequest(response.data);
        toast?.pushSuccess('Request sent successfully!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Send failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  };

  const myPendingQuotaRequest = () => {
    axios
      .get(baseUrl + `/api/quota/request/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setQuotaRequest(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      })
  }

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='relative'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onModalClose()}
            >
              &times;
            </button>
          </div>
          <div className='flex flex-row justify-center'>
            <p className='text-center text-xl font-bold'>
              Request Quota
            </p>
          </div>
          <form onSubmit={handleSubmit(createQuotaRequest)} className='w-full rounded px-8 pb-0 pt-6'>
            <div className='py-3'>
              <InputFormField
                name='reason'
                inputType={'textarea'}
                label='Message'
                isRequired={true}
                isRequiredLabel
                register={register}
                validation={{ required: 'This field is required' }}
                errors={errors}
                className='min-h-28 resize-y'
              />
            </div>
            <div className='border-blueGray-200 mb-3 flex items-center justify-center space-x-4 rounded-b p-6'>
              <Button type='outline' rounded={true} onClick={() => onModalClose()}>
                Cancel
              </Button>
              <Button1
                type='submit'
                color='blue'
                className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
              >
                Send Request
              </Button1>
            </div>
          </form>
        </div>
      </>
    )
  })

  const buttonProps = quotaRequest ? { disabled: true } : { onClick: openModal }

  return <>
    <Button type='outline' size='small' {...buttonProps}>
      { quotaRequest ? "Request Pending" : "Request Quota" }
    </Button>
    {modal}
  </>
}

export default RequestQuota;
