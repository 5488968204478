import exp from 'constants';

const PlusCircle = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='plus-circle' clipPath='url(#clip0_1825_23666)'>
      <path
        id='Vector'
        d='M4.66666 7H9.33333M7 4.66666V9.33333M12.8333 7C12.8333 10.2217 10.2217 12.8333 7 12.8333C3.77834 12.8333 1.16666 10.2217 1.16666 7C1.16666 3.77834 3.77834 1.16666 7 1.16666C10.2217 1.16666 12.8333 3.77834 12.8333 7Z'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1825_23666'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default PlusCircle;
