const FileEditIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='file-edit'>
      <path
        id='Vector'
        d='M2.33333 7.87508V2.33341C2.33333 2.024 2.45624 1.72725 2.67504 1.50846C2.89383 1.28966 3.19058 1.16675 3.49999 1.16675H8.45833L11.6667 4.37508V11.6667C11.6667 11.9762 11.5437 12.2729 11.325 12.4917C11.1062 12.7105 10.8094 12.8334 10.5 12.8334H7.29166'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M8.16666 1.16675V4.66675H11.6667'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_3'
        d='M6.07833 7.35588C6.19209 7.24212 6.32714 7.15189 6.47577 7.09032C6.6244 7.02876 6.7837 6.99707 6.94458 6.99707C7.10546 6.99707 7.26476 7.02876 7.41339 7.09032C7.56202 7.15189 7.69707 7.24212 7.81083 7.35588C7.92459 7.46964 8.01482 7.60469 8.07639 7.75332C8.13795 7.90195 8.16964 8.06126 8.16964 8.22213C8.16964 8.38301 8.13795 8.54231 8.07639 8.69094C8.01482 8.83958 7.92459 8.97463 7.81083 9.08838L4.63749 12.25L2.33333 12.8334L2.91083 10.5292L6.07833 7.35588Z'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default FileEditIcon;
