import React from 'react';
import TimelineItem from './TimelineItem';
import './Timeline.css';

interface TimelienProps {
    inputData: TimelinePropItems[]
    orderID: string | undefined;
    orderPlacedBy: string | undefined;
}

interface TimelinePropItems {
    status: string;
    happened_at: string;
    tracking_number: string;
}

const Timeline: React.FC<TimelienProps> = ({
    inputData,
    orderID,
    orderPlacedBy,
}) => {
    return (
        <div className="timeline-container">
            {inputData.map((data, idx) => (
                <TimelineItem data={data} orderID={orderID} orderPlacedBy={orderPlacedBy} index={idx} key={idx} count={inputData.length} />
            ))}
        </div>
    );
};

export default Timeline;