import NavLinkItem from './nav-link-item';

function formatDate(date: any) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

const dateDefault = {
  startDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
  endDate: formatDate(new Date()),
};

const adminArray = [
  {
    title: 'All Orders',
    icon: '/assets/gift.svg',
    redirect:
      '/admin/order?date_filter=custom&start_date=' +
      dateDefault.startDate +
      '&end_date=' +
      dateDefault.endDate,
  },
  {
    title: 'Templates',
    icon: '/assets/newspaper.svg',
    redirect: '/admin/template',
  },
  {
    title: 'Users',
    icon: '/assets/users.svg',
    redirect:
      '/admin/user?date_filter=custom&start_date=' +
      dateDefault.startDate +
      '&end_date=' +
      dateDefault.endDate,
  },
  {
    title: 'Products',
    icon: '/assets/products.svg',
    redirect: '/admin/product',
  },
  {
    title: 'Quota Requests',
    icon: '/assets/plus.svg',
    redirect: '/admin/quota-request',
  },
];

const managerArray = [
  {
    title: 'All Orders',
    icon: '/assets/gift.svg',
    redirect:
      '/admin/order?date_filter=custom&start_date=' +
      dateDefault.startDate +
      '&end_date=' +
      dateDefault.endDate,
  },
  {
    title: 'Products',
    icon: '/assets/products.svg',
    redirect: '/user/product',
  },
];

const userArray = [
  {
    title: 'My Orders',
    icon: '/assets/gift.svg',
    redirect:
      '/user/order?date_filter=custom&start_date=' +
      dateDefault.startDate +
      '&end_date=' +
      dateDefault.endDate,
  },
  {
    title: 'Products',
    icon: '/assets/products.svg',
    redirect: '/user/product',
  },
];

const NavLinks = (auth: any, user: any) => {
  if (!auth.isAuth) {
    return <div></div>
  }

  const navItems = (auth?.user?.role === 'ADMIN' ? adminArray : (auth?.user?.role === 'MANAGER' ? managerArray : userArray))
  return (
    <ul className='flex justify-between gap-4 px-6'>
      {
        navItems.map((item, index) => {
          return <NavLinkItem imageUrl={item.icon} key={index} {...item} />;
        })
      }
    </ul>
  )
};

export default NavLinks;
