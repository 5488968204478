import React, { useEffect, useRef }  from 'react';
import { useZoomImageHover } from "@zoom-image/react"

import { Badge } from '../catalyst/badge';
import { DIGITAL_GIFT } from '../pages/SendGift/PickGiftStep/ProductList';

interface CardProps {
  children: React.ReactNode;
  cardTitle?: string;
  imageUrl?: string;
  badge?: string;
  selectedProduct?: boolean;
  tags: any[];
  zoomTargetRef: any;
  column: number;
}

const TOP_CHOICE = 'Top choice';

const Card = ({ cardTitle, children, imageUrl, selectedProduct = false, tags = [], zoomTargetRef, column}: CardProps) => {
  const imageHoverContainerRef = useRef<HTMLDivElement>(null)
  const { createZoomImage } = useZoomImageHover()

  useEffect(() => {
    const timer = setTimeout(() => {
      const imageContainer = imageHoverContainerRef.current as HTMLDivElement
      const zoomTarget = zoomTargetRef.current as HTMLDivElement

      createZoomImage(imageContainer, {
        zoomImageSource: imageUrl,
        customZoom: { width: 600, height: 600 },
        zoomTarget,
        zoomTargetClass: `active column-${column + 1}`,
        zoomLensScale: 0.5,
        zoomLensClass: 'hidden',
        scale: 3,
      })
    }, 500);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [column, imageUrl]);

  return (
    <div
      className={`relative overflow-auto rounded-lg p-2 ${selectedProduct} ? 'bg-blue-200' : 'bg-white'`}
    >
      {tags.includes(DIGITAL_GIFT) ? (
        <div className='absolute -top-2 left-3 mt-5'>
          <Badge color='red500'>{DIGITAL_GIFT}</Badge>
        </div>
      ) : tags.includes(TOP_CHOICE) ? (
        <div className='absolute -top-2 left-3 mt-5'>
          <Badge color='blue600'>{TOP_CHOICE}</Badge>
        </div>
      ) : null}
      {!!imageUrl && (
        <div className='flex items-center justify-center rounded-lg bg-white'>
          <div className='' ref={imageHoverContainerRef}>
            <img
              className='w-70 max-h-40 object-cover object-center'
              src={imageUrl}
              alt='Image Description'
            />
          </div>
        </div>
      )}
      <div
        className={
          selectedProduct
            ? 'z-10 m-2 rounded-lg bg-blue-200 md:p-2'
            : 'm-2 rounded-lg bg-white md:p-2'
        }
      >
        {!!cardTitle && (
          <h3 className='text-base font-medium dark:text-white'>{cardTitle ?? ''}</h3>
        )}
        {children}
      </div>
    </div>
  );
};

export default React.memo(Card);
