import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import ProductList, { DIGITAL_GIFT } from './ProductList';
import { useGiftForm, productDefault } from '../GiftOrderContext';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '../../../components/Icons/SearchIcon';
import { Select } from '../../../components/Select';
import SelectCatalyst from '../../../catalyst/select';
import DismissBadge from '../../../components/DismissBadge';
import { Button } from '../../../components/Button';
import Footer from '../Footer';
import Info from '../../../components/Info';
import InfiniteScroll from 'react-infinite-scroll-component';

const PickGift = ({ currentStep, handleBack, handleNextStep }: any) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hideOutOfStock, setHideOutOfStock] = useState(true);
  const [searchProduct, setSearchProduct] = useState('');
  const [productFilters, setProductFilters] = useState<any[]>([]);
  const [productSort, setProductSort] = useState<string>('');
  const [productCount, setProductCount] = useState<number | undefined>(undefined);
  const [enableNext, setEnableNext] = useState<boolean>(false);

  const token = localStorage.getItem('ACCESS_TOKEN');
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [page, setPage] = useState(1);

  const SORT_BY_OPTIONS = useMemo(
    () => [
      { label: 'Sorting', value: '' },
      { label: 'Top Choice', value: 'top' },
      { label: 'By Name (A->Z)', value: 'name' },
      { label: 'By Name (Z->A)', value: 'name_Z' },
      { label: 'In stock count', value: 'stock_count' },
    ],
    [],
  );

  const { recipients, setRecipients, giftProduct, setGiftProduct } = useGiftForm();

  const {
    control,
    formState: { errors },
  } = useForm<any>();

  const zoomTargetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchProducts(source, productSort, hideOutOfStock, searchProduct, productFilters);
    return () => source.cancel();
  }, [page, productSort, hideOutOfStock, searchProduct, productFilters]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchCategories(source);
    return () => source.cancel();
  }, []);

  useEffect(() => {
    const selectedProduct = products.find(
      (pr: any) =>
        pr.productID === giftProduct.id,
    );
    if (
      selectedProduct &&
      !isDigitalProduct(selectedProduct) &&
      selectedProduct['quantity'] < recipients.length
    ) {
      setGiftProduct(productDefault);
    }
  }, [recipients, giftProduct, products]);

  useEffect(() => {
    setEnableNext(recipients.every((r: any) => (r.variant_id)))
  }, [recipients, giftProduct]);

  const isDigitalProduct = (pr: any) => {
    return pr && pr.tags.includes(DIGITAL_GIFT);
  };

  const fetchProducts = async (
    source: any,
    productSort: string,
    hideOutOfStock: boolean,
    searchProduct: string,
    productFilters: any[],
  ) => {
    const selectedCategories = categories
      .filter((category: any) => productFilters.includes(category.value))
      .map((category: any) => category.id);
    setLoading(true);
    try {
      await axios
        .get(baseUrl + '/api/products/listProducts', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            country: recipients[0].country,
            ...(productSort
              ? { orderBy: productSort !== 'name_Z' ? productSort : 'name' }
              : { orderBy: 'name' }),
            ...(productSort && productSort === 'name_Z'
              ? { orderByType: 'desc' }
              : { orderByType: 'asc' }),
            page: page,
            limit: 12,
            instockonly: hideOutOfStock,
            ...(searchProduct ? { search: searchProduct } : {}),
            ...(productFilters.length ? { category: selectedCategories.join() } : {}),
          },
          cancelToken: source.token,
        })
        .then((response) => response.data)
        .then((res) => {
          if (page === 1) setProducts(res.data.products);
          else setProducts((prev: any) => [...new Set([...prev, ...res.data.products])] as any);
          if (page === 1) setProductCount(res.data.count);
        });
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.data?.status === 401) {
        localStorage.removeItem('userData');
        localStorage.removeItem('ACCESS_TOKEN');
        window.location.href = '/login';
        window.location.reload();
      }
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  const fetchCategories = async (source: any) => {
    setLoadingCategories(true);
    try {
      await axios
        .get(baseUrl + '/api/products/listRootCategories', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((response) => {
          setLoadingCategories(false);
          const categories = response.data.data.categories.map((category: any) => {
            return {
              ...category,
              label: category.name,
              value: category.name,
            };
          });
          setCategories(categories);
        });
    } catch (error: any) {
      setLoadingCategories(false);
      if (error?.response?.data?.status === 401) {
        localStorage.removeItem('userData');
        localStorage.removeItem('ACCESS_TOKEN');
        window.location.href = '/login';
        window.location.reload();
      }
      console.error('Error fetching categories:', error);
    }
  };

  const handleNext = () => {
    if (errors.id) return;
    handleNextStep();
  };

  const setRecipientVariant = (index: number, variant: any) => {
    const giftRecipients = [...recipients];

    giftRecipients[index].variant_id = variant.id;
    giftRecipients[index].quantity = 1;

    setRecipients(giftRecipients);
  }

  return (
    <>
      <div className='mx-auto mt-1 w-2/5'>
        <Info>
          <>
            Please be aware that only one gift can be sent to each recipient. Sending different
            gifts to different recipients is not possible.
          </>
        </Info>
      </div>
      <div className='container'>
        <div className='my-10 flex flex-row justify-between'>
          <h2>Gifts {`(${productCount ? productCount : 0})`}</h2>
          <div className='flex justify-between gap-2'>
            <div className='flex items-center'>
              <input
                className='mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                type='checkbox'
                id='stocked'
                name='stocked'
                checked={hideOutOfStock}
                onChange={() => {
                  setHideOutOfStock(!hideOutOfStock);
                  setPage(1);
                }}
              />
              <label htmlFor='stocked' className='ms-3 text-sm text-gray-800 dark:text-neutral-400'>
                Hide out of stock
              </label>
            </div>
            <div className='relative ml-2 flex rounded-lg shadow-sm'>
              <input
                type='text'
                placeholder='Search'
                id='product-search'
                value={searchProduct}
                onChange={(e) => {
                  setSearchProduct(e.target.value);
                  setPage(1);
                }}
                className='block rounded-lg border-gray-200 px-4 py-3 ps-11 text-sm shadow-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
              />
              <div className='pointer-events-none absolute inset-y-0 start-0 z-20 flex items-center ps-4'>
                <SearchIcon />
              </div>
            </div>
            {!!categories.length && (
              <div className='ml-2'>
                <Select
                  options={categories}
                  value='Filters'
                  onChange={(value: any) => {
                    if (!productFilters.find((filter) => filter === value)) {
                      setProductFilters((prevState) => [...prevState, value]);
                      setPage(1);
                    }
                  }}
                />
              </div>
            )}
            <div className='ml-2'>
              <SelectCatalyst
                name='sort_by'
                value={productSort}
                onChange={(event: any) => {
                  setProductSort(event.target.value);
                  setPage(1);
                }}
              >
                {SORT_BY_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectCatalyst>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='mb-10 flex justify-between'>
          <div className='flex gap-2 flex-wrap'>
            {!!productFilters.length && (
              <>
                {productFilters.map((filter) => (
                  <div key={filter} className='flex items-center gap-2'>
                    <DismissBadge
                      badgeText={filter}
                      onDismiss={() =>
                        setProductFilters((prevState) => prevState.filter((item) => item !== filter))
                      }
                    />
                  </div>
                ))}
                <Button
                  className='rounded-full border-gray-200'
                  size='small'
                  onClick={() => setProductFilters([])}
                >
                  Clear All
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="zoom fixed z-50 rounded drop-shadow-2xl" ref={zoomTargetRef}></div>

      <InfiniteScroll
        dataLength={products?.length}
        next={() => setPage((prev) => prev + 1)}
        hasMore={!!productCount && productCount > products?.length}
        loader={null}
      >
        <div className='container mb-10 grid auto-rows-fr grid-cols-4 gap-4'>
          <Controller
            name='product_id'
            defaultValue={giftProduct?.id}
            render={({ onChange, value }: any) => (
              <ProductList
                products={products}
                loading={loading}
                onChange={onChange}
                zoomTargetRef={zoomTargetRef}
                hideOutOfStock={hideOutOfStock}
                setRecipientVariant={setRecipientVariant}
              />
            )}
            rules={{
              required: 'Please select a product',
            }}
            control={control}
          />
        </div>
      </InfiniteScroll>

      {!loading && productCount === 0 && (
        <div className='flex min-h-screen flex-row justify-center'>
          <div className='w-1/3'>
            <div>
              <img src='/assets/product-empty.svg' />
            </div>
            <h1 className='mt-5'>No Results found</h1>
            <div className='mt-5'>
              Oops looks like there are no matches for your search? Possible typo? Try refining your
              search terms or browse our category for inspiration. Happy exploring
            </div>
          </div>
        </div>
      )}
      <Footer
        currentStep={currentStep}
        isNextDisabled={!enableNext}
        handleBack={handleBack}
        handleNextStep={handleNext}
      />
    </>
  );
};

export default PickGift;
