import React from 'react';
import Select from '../catalyst/select';
import ExclemationCircleFill from './Icons/ExclamationCircleFill';
import { cx } from 'cva';
import InputMask from 'react-input-mask';
import { restrictEmoji } from '../pages/SendGift/helper';

const InputFormField = ({
  label,
  placeholder,
  inputType = 'text',
  isRequired = false,
  isRequiredLabel = true,
  errors,
  name,
  register,
  validation,
  options,
  onBlur,
  onChange,
  disabled,
  helpText,
  onHelpTextClick,
  selected = null,
  ...otherProps
}: any) => {
  let INPUT_HTML_ELEMENT;
  if (inputType === 'tel') {
    INPUT_HTML_ELEMENT = InputMask;
  } else if (inputType === 'textarea') {
    INPUT_HTML_ELEMENT = 'textarea';
  } else {
    INPUT_HTML_ELEMENT = 'input';
  }
  const extraProps = onChange ? { onChange: onChange } : {};
  const getErrorClass = (errors: any, name: string) => {
    return errors && errors[name] ? 'border-red-500 shadow-md shadow-red-200' : 'border-gray-200';
  };
  const classes = cx(
    'block w-full rounded-lg px-4 py-3 text-sm drop-shadow-sm disabled:opacity-50',
    getErrorClass(errors, name),
  );

  const helpTextClickHandler = () => {
    if (onHelpTextClick) {
      onHelpTextClick();
    }
  };

  const getFieldValidations = () => {
    if(errors === undefined) {
      return validation
    } else {
      const formValidations = validation || {};
      formValidations['validate'] ||= {};
      formValidations['validate']['restrictEmoji'] = restrictEmoji;
      return formValidations;
    }
  }

  return (
    <div className='relative'>
      <div className='flex items-center justify-between'>
        <label className='mb-2 block text-sm font-medium dark:text-white'>{label}</label>
        {isRequiredLabel && (
          <span className='mb-2 block text-sm text-gray-500 dark:text-neutral-500'>Required</span>
        )}
      </div>
      {inputType === 'select' ? (
        <Select
          id={name}
          {...register(name, validation)}
          onBlur={onBlur}
          {...extraProps}
          key={options.length}
          disabled={disabled}
        >
          {options?.map((option: any) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.name}
            </option>
          ))}
        </Select>
      ) : (
        <>
          {errors && errors[name] && (
            <div className='pointer-events-none absolute right-2 mt-3.5 flex items-center pl-3'>
              <ExclemationCircleFill />
            </div>
          )}
          <INPUT_HTML_ELEMENT
            placeholder={placeholder ?? ''}
            id={name}
            type={inputType}
            {...otherProps}
            {...register(name, getFieldValidations())}
            onBlur={onBlur}
            {...extraProps}
            className={classes}
            disabled={disabled}
            rows='6'
          />
        </>
      )}
      {errors && errors[name] && (
        <p className='mt-2 text-xs text-red-500'>{errors[name].message}</p>
      )}
      {helpText && (
        <span
          className={`my-3 text-sm text-slate-400 ${onHelpTextClick ? 'hover:cursor-pointer hover:underline' : ''}`}
          onClick={helpTextClickHandler}
        >
          {helpText}
        </span>
      )}
    </div>
  );
};

export default InputFormField;
