import { forwardRef, ReactNode } from 'react';

export type ModalProps = {
  children?: ReactNode;
  onBackdropClick?: () => void;
  modalBoxClassName?: string;
  // you can add more classNames as per you level of customisation
};

export const Modal = forwardRef<HTMLDialogElement, ModalProps>(
  ({ children, modalBoxClassName, onBackdropClick }, ref) => {
    return (
      <dialog ref={ref} className='modal w-4/12 rounded-2xl backdrop-blur-3xl transition-shadow '>
        <div className={`modal-box ${modalBoxClassName ?? ''}`}>{children}</div>
      </dialog>
    );
  },
);

Modal.displayName = 'Modal';
export default Modal;
