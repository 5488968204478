import { useState, useEffect, useRef } from 'react';
import RecipientForm from './RecipientForm';
import AccordionGroup from '../../../components/AccordionGroup';
import { Button } from '../../../components/Button';
import { useGiftForm, recipientValuesDefault } from '../GiftOrderContext';
import Footer from '../Footer';
import Info from '../../../components/Info';
import PlusCircle from '../../../components/Icons/PlusCircle';
import { countries, AddressFields, AutofillRecipientFields, GENERAL_ENQUIRY_FORM_URL } from '../../../constants/variable';
import pick from 'lodash/pick';
import axios from 'axios';
import ValidateAddress from './ValidateAddress';
import omit from 'lodash/omit';
import DeleteRecipientModal from '../ConfirmOrderStep/DeleteRecipientNotification';

export const omitProp = ['printed_message', 'email_message'];

const AddRecipientStep = ({ currentStep, handleBack, handleNextStep }: any) => {
  const { recipients, setRecipients } = useGiftForm();
  const [resetFormIndex, setResetFormIndex] = useState(-1);
  const [validateForm, setValidateForm] = useState(false);
  const [confirmAddressWarning, setConfirmAddressWarning] = useState(false);
  const [renderPage, setRenderPage] = useState(false);
  const [allRecipientsValid, setAllRecipientsValid] = useState([false]);
  const [addressValidateModal, setAddressValidateModal] = useState([false]);
  const [showQuotaError, setShowQuotaError] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(-1);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('ACCESS_TOKEN');

  const RECIPIENT_LIMIT = 10;

  const searchParams = new URLSearchParams(location.search);
  const orderID = searchParams.get('orderId');
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const formRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [formCount, setFormCount] = useState(1);

  const selectedCountryQuota = () => {
    const recipientCountry = recipients[0].country;
    return userData?.quota?.[recipientCountry]?.balance || 0;
  };

  const isQuotaExhausted = () => {
    return recipients.length >= selectedCountryQuota();
  };

  useEffect(() => {
    if (formCount !== 1) scrollForm(formCount - 1);
  }, [formCount]);

  useEffect(() => {
    if(!orderID && recipients[0].first_name === '') {
      const recipient = recipients[0]
      Object.keys(recipientValuesDefault).forEach((key: any) => {
        if(!['address_id', 'use_same_address', 'address_validated'].includes(key)) {
          const value = searchParams.get(key)
          if(value)
            recipient[key] = value
        }
      })
      setRecipients([recipient]);
      setRenderPage(!renderPage);
    }
  }, [])

  const scrollForm = (formIndex: number) => {
    if (formRefs.current[formIndex]) {
      formRefs.current[formIndex]!.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const handleAddRecipient = () => {
    if (!recipients[0].country) {
      setValidateForm(true);
    } else if (isQuotaExhausted() || recipients.length === RECIPIENT_LIMIT) {
      setShowQuotaError(true);
      return;
    } else {
      const recipientCount = recipients.length;
      setRecipients((prevState: any) => {
        if (recipientCount == prevState.length) {
          prevState.push({ ...recipientValuesDefault, ...pick(recipients[0], AddressFields), ...pick(recipients[0], AutofillRecipientFields) });
        }

        return prevState;
      });
      setShowQuotaError(false);
      setRenderPage(!renderPage);
      setFormCount((prev) => prev + 1);
    }
  };

  const handleDeleteRecipientClick = (recipientIndex: number) => {
    const recipient = recipients[recipientIndex];
    let anyDataAdded = false;
    if (recipient['use_same_address']) {
      anyDataAdded = Object.keys(recipient).some((key) => {
        return key !== 'use_same_address' && !AddressFields.includes(key) && !!recipient[key];
      });
    } else {
      anyDataAdded = Object.keys(recipient).some((key) => {
        return !!['country', 'state'].includes(key) && !!recipient[key];
      });
    }

    if (anyDataAdded) {
      setDeleteConfirmationModal(recipientIndex);
    } else {
      handleDeleteRecipient(recipientIndex);
    }
  };

  const handleDeleteRecipient = (recipientIndex: number) => {
    setRecipients((prevState: any) =>
      prevState.filter((_: any, indx: number) => indx !== recipientIndex),
    );
    setRenderPage(!renderPage);
    setShowQuotaError(false);
    setFormCount((prev) => prev - 1);
    allRecipientsValid.splice(recipientIndex, 1);
    setAllRecipientsValid(allRecipientsValid);
  };

  const handleClearRecipient = (recipientIndex: number) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex] = recipientValuesDefault;
      return [...prevState];
    });
    setResetFormIndex(recipientIndex);
  };

  const handleRecipientSubmit = (data: any, recipientIndex: any, addressField: boolean) => {
    setRecipients((prevState: any) => {
      if (prevState[recipientIndex]) {
        prevState[recipientIndex] = data;
      } else {
        prevState.push(data);
      }

      if (addressField) {
        prevState[recipientIndex]['address_validated'] = false;
      }

      prevState = prevState.map((rec: any, index: number) => {
        if (rec['use_same_address'] && index !== 0) {
          rec = { ...rec, ...pick(prevState[0], AddressFields) };
          if (addressField) rec['address_validated'] = false;
        }
        return rec;
      });
      return prevState;
    });
  };

  const validateAddress = () => {
    recipients.forEach((recipient: any, index: number) => {
      addressValidateModal[index] = !recipient['use_same_address'];
    });
    setAddressValidateModal(addressValidateModal);
    setRenderPage(!renderPage);
  };

  const setAddressValidatedInRecipients = (
    recipientIndex: number,
    addressValid: boolean,
    placeId: string,
    errorMessages: [],
    addressWarnings: []
  ) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex]['address_validated'] = addressValid;
      prevState[recipientIndex]['address_id'] = placeId;
      prevState[recipientIndex]['address_errors'] = errorMessages;
      prevState[recipientIndex]['address_warning'] = addressWarnings;
      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec['address_validated'] = addressValid;
            rec['address_id'] = placeId;
            rec['address_errors'] = errorMessages;
            rec['address_warning'] = addressWarnings;
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const setAddressInRecipients = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex] = {
        ...prevState[recipientIndex],
        ...newAddress,
        address_errors: [],
        address_warning: addressWarnings,
        address_validated: true,
      };

      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec = { ...rec, ...pick(prevState[0], AddressFields), address_validated: true, address_errors: [], address_warning: addressWarnings };
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const isAddressWarningPresent = () => {
    return recipients.findIndex((rec: any) => rec['address_warning'].length > 0) > -1
  }

  const handleNextStepClick = () => {
    if (window.location.pathname.includes('/send-gift/add-recipients') && currentStep === 0) {
      if(!confirmAddressWarning && isAddressWarningPresent()) {
        setConfirmAddressWarning(true)
      } else {
        handleNextStep();
      }
    }
  };

  const keepAddress = (recipientIndex: number, addressValid: boolean, placeId: string, errorMessages: [], addressWarnings: []) => {
    setAddressValidatedInRecipients(recipientIndex, addressValid, placeId, errorMessages, addressWarnings);
    addressValidateModal[recipientIndex] = false;
    setAddressValidateModal(addressValidateModal);
    setRenderPage(!renderPage);
    setTimeout(() => {
      if (addressValidateModal.every((val) => !val)) {
        handleNextStepClick();
      }
    }, 1000);
  };

  const changeAddress = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    setAddressInRecipients(newAddress, recipientIndex, addressWarnings);
    addressValidateModal[recipientIndex] = false;
    setAddressValidateModal(addressValidateModal);
    setRenderPage(!renderPage);
    setTimeout(() => {
      if (addressValidateModal.every((val) => !val)) {
        handleNextStepClick();
      }
    }, 1000);
  };

  const validateFormsAndHandleNextStep = () => {
    setValidateForm(true);
  };

  const onValidationComplete = (formValid: boolean, index: number) => {
    allRecipientsValid[index] = formValid;
    setAllRecipientsValid(allRecipientsValid);
    setValidateForm(false);
    const errorIndex = allRecipientsValid.findIndex((val) => !val);
    if (errorIndex > -1) scrollForm(errorIndex);
    setTimeout(() => {
      if (allRecipientsValid.every((val) => val)) validateAddress();
    }, 500);
  };

  const onCountryChange = (countryValue: string, stateChangeNotRequired: boolean) => {
    setShowQuotaError(false);
    const statesData = countries.find((country) => country.value === countryValue)?.states;
    setRecipients((prevState: any) => {
      prevState.forEach((recipient: any) => {
        if (!stateChangeNotRequired) recipient['state'] = statesData ? statesData[0]['value'] : '';
        recipient['country'] = countryValue;
        return recipient;
      });
      return prevState;
    });
    setRenderPage(!renderPage);
  };

  const fetchOrderDetails = async () => {
    await axios
      .get(baseUrl + '/api/orders/' + orderID, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          const recipientsData = data.data.recipients.map((recipient: any) => {
            recipient = omit(recipient, omitProp);
            if(recipient['address_warning']) {
              recipient['address_warning'] = recipient['address_warning'].split(',')
            }
            return recipient;
          })
          setRecipients(recipientsData);
          setRenderPage(!renderPage);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  useEffect(() => {
    if (orderID) fetchOrderDetails();
  }, [orderID]);

  const isAddressValidatingModalOpen = () => {
    return addressValidationRecipientIndex() > -1;
  };

  const closeAddressModal = (recipientIndex: any) => {
    addressValidateModal[recipientIndex] = false;
    setAddressValidateModal(addressValidateModal);
    setRenderPage(!renderPage)
  }

  const addressValidationRecipientIndex = () => {
    return addressValidateModal.findIndex((val) => val);
  };


  const renderAddressValidatingModal = () => {
    const recipientIndex = addressValidationRecipientIndex();

    if (recipientIndex > -1) {
      return (
        <ValidateAddress
          recipient={recipients[recipientIndex]}
          recipientIndex={recipientIndex}
          changeAddress={changeAddress}
          keepAddress={keepAddress}
          closeModal={closeAddressModal}
        />
      );
    }
  };

  const handleDeleteConfirmationModal = (open: boolean) => {
    setDeleteConfirmationModal(-1);
  };

  return (
    <>
      <div className='mx-auto mt-1 w-2/5 min-w-96 max-w-2xl ' key={`form-${renderPage}`}>
        <div className='my-5 flex items-center justify-center'>
          <Info>
            <p>
              Before sending any physical products, please verify whether the state offices are
              open, as many are still closed.
            </p>
            <p>
              For compliance reasons, please do not send gifts to any healthcare or public sector
              employees.
            </p>
            <p>
              Please note: Most vendors are unable to ship to PO Boxes.
            </p>
          </Info>
        </div>
        {recipients.map((recipient: any, indx: number) => (
          <AccordionGroup
            key={'recipient' + indx}
            id={'form_recipient' + indx}
            title={''}
            titleClass={'py-[1px] px-0 mt-2'}
            titleBadge={
              <>
                <span className='font-bold'>{`Recipient ${indx + 1}.`} </span>
                <span>{recipient.first_name + ' ' + recipient.last_name}</span>
              </>
            }
            handleDelete={(num: number) => handleDeleteRecipientClick(num)}
            handleClear={(num: number) => handleClearRecipient(num)}
            isClear
            isDelete={indx !== 0}
            listLocation={indx + 1}
            className='shadow-3xla'
            hideChevron={recipients.length === 1}
          >
            <div ref={(el: any) => (formRefs.current[indx] = el)}>
              <RecipientForm
                recipients={recipients}
                recipient={recipient}
                recipientIndex={indx}
                onSubmit={handleRecipientSubmit}
                resetForm={resetFormIndex === indx}
                onResetFormComplete={setResetFormIndex}
                validateForm={validateForm}
                onValidationComplete={onValidationComplete}
                onCountryChange={onCountryChange}
              />
            </div>
          </AccordionGroup>
        ))}
        <div className='mt-5 flex items-center justify-center'>
          <Button
            type='outline'
            onClick={handleAddRecipient}
            disabled={showQuotaError || recipients.length === RECIPIENT_LIMIT}
            className='text-base font-semibold'
          >
            <PlusCircle />
            Add another recipient
          </Button>
        </div>
        {renderAddressValidatingModal()}
      </div>
      {showQuotaError && (
        <div className='mx-auto my-3 w-2/5'>
          <Info color='red'>
            Not enough quota. You’ve run out of gift quotas for this months. Please,{' '}
            <a href={GENERAL_ENQUIRY_FORM_URL} className='text-blue-600' rel='noreferrer' target='_blank'>
              click here to send an email to the admins.
            </a>
          </Info>
        </div>
      )}
      {recipients.length === RECIPIENT_LIMIT && (
        <div className='mx-auto my-3 w-2/5'>
          <Info color='red'>
            <p>Maximum number of recipients limit reached.</p>
          </Info>
        </div>
      )}
      {deleteConfirmationModal > 0 && (
        <DeleteRecipientModal
          setShowModal={handleDeleteConfirmationModal}
          deleteRecipient={handleDeleteRecipient}
          index={deleteConfirmationModal}
        />
      )}
      <Footer
        currentStep={currentStep}
        handleBack={handleBack}
        handleNextStep={validateFormsAndHandleNextStep}
        isNextDisabled={selectedCountryQuota() === 0}
        footerText={confirmAddressWarning ? 'Proceed with warnings' : 'Next Step'}
        loading={isAddressValidatingModalOpen()}
      />
    </>
  );
};

export default AddRecipientStep;
