const ReOrderIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.3334 1.33325L14 3.99992L11.3334 6.66658'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 7.33333V6.66667C2 5.95942 2.28095 5.28115 2.78105 4.78105C3.28115 4.28095 3.95942 4 4.66667 4H14'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.66667 14.6666L2 11.9999L4.66667 9.33325'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 8.66675V9.33341C14 10.0407 13.719 10.7189 13.219 11.219C12.7189 11.7191 12.0406 12.0001 11.3333 12.0001H2'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ReOrderIcon;
