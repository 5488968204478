const GiftIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1580_35228)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.375 1.45833C4.06558 1.45833 3.76883 1.58125 3.55004 1.80004C3.33125 2.01883 3.20833 2.31558 3.20833 2.625C3.20833 2.93442 3.33125 3.23117 3.55004 3.44996C3.76883 3.66875 4.06558 3.79167 4.375 3.79167H6.62239C6.61504 3.76729 6.60721 3.74192 6.59889 3.71566C6.51607 3.45412 6.38617 3.10848 6.19756 2.76556C5.81359 2.06743 5.23364 1.45833 4.375 1.45833ZM6.70833 4.375V6.70833H1.45833V4.375H6.70833ZM3.07062 3.79167C2.78441 3.4717 2.625 3.05657 2.625 2.625C2.625 2.16087 2.80937 1.71575 3.13756 1.38756C3.46575 1.05937 3.91087 0.875 4.375 0.875C5.55803 0.875 6.29057 1.72423 6.70869 2.48444C6.82588 2.69752 6.92211 2.90977 7 3.10517C7.0779 2.90977 7.17412 2.69752 7.29131 2.48444C7.70943 1.72423 8.44197 0.875 9.625 0.875C10.0891 0.875 10.5342 1.05937 10.8624 1.38756C11.1906 1.71575 11.375 2.16087 11.375 2.625C11.375 3.05657 11.2156 3.4717 10.9294 3.79167H12.8333C12.9944 3.79167 13.125 3.92225 13.125 4.08333V7C13.125 7.16108 12.9944 7.29167 12.8333 7.29167H11.9583V12.8333C11.9583 12.9944 11.8277 13.125 11.6667 13.125H2.33333C2.17225 13.125 2.04167 12.9944 2.04167 12.8333V7.29167H1.16667C1.00558 7.29167 0.875 7.16108 0.875 7V4.08333C0.875 3.92225 1.00558 3.79167 1.16667 3.79167H3.07062ZM2.625 7.29167V12.5417H6.70833V7.29167H2.625ZM7.29167 7.29167V12.5417H11.375V7.29167H7.29167ZM12.5417 6.70833V4.375H7.29167V6.70833H12.5417ZM9.625 3.79167C9.93442 3.79167 10.2312 3.66875 10.45 3.44996C10.6687 3.23117 10.7917 2.93442 10.7917 2.625C10.7917 2.31558 10.6687 2.01883 10.45 1.80004C10.2312 1.58125 9.93442 1.45833 9.625 1.45833C8.76636 1.45833 8.18641 2.06743 7.80244 2.76556C7.61383 3.10848 7.48393 3.45412 7.4011 3.71566C7.39279 3.74192 7.38496 3.76729 7.37761 3.79167H9.625Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1580_35228'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default GiftIcon;
