import { useEffect, useState } from 'react';
import ModalBackdrop from '../../../components/ModalRevisited';
import { Button } from '../../../components/Button';
import axios from 'axios';

interface Item {
  componentType: string;
}

const US_ADDRESS_VALIDATION_SMARTY_API_URL = 'https://us-street.api.smarty.com/street-address';
const INTERNATIONAL_ADDRESS_VALIDATION_SMARTY_API_URL = 'https://international-street.api.smarty.com/verify';

const US_VALIDATION_ERROR_CODES_MAPPING = {
  'A1': 'Address not present in USPS data.',
  'C1': 'The submitted secondary information (apartment, suite, etc.) was not recognized. Secondary number IS REQUIRED for delivery.',
  'M1': 'Primary number (e.g., house number) is missing.',
  'M3': 'Primary number (e.g., house number) is invalid.',
  'N1': 'Address is missing secondary information (apartment, suite, etc.) which IS REQUIRED for delivery',
  'PB': 'PO Box street style address', // show form error if possible
  'P1': 'PO, RR, or HC box number is missing.',
  'P3': 'PO, RR, or HC box number is invalid.',
  'R1': 'Confirmed address without private mailbox (PMB) info.',
  'R7': "Confirmed as a valid address that doesn't currently receive US Postal Service street delivery.",
  'F#': 'Address is missing apartment or suite number',
  'H#': 'The address as submitted is missing a secondary number (apartment, suite, etc.).',
  'I#': 'Insufficient/ incorrect address data',
  'S#': 'The secondary information (apartment, suite, etc.) was not recognized as part of the USPS data.',
  'U#': 'The city name in the submitted address is an alternate city name that is not accepted by the US Postal Service.',
  'V#': 'The city and state in the submitted address could not be verified as corresponding to the given 5-digit ZIP Code.',
}

let ADDRESS_SUGGESTIONS: string[] = [];

const ValidateAddress = ({ recipient, recipientIndex, changeAddress, keepAddress, closeModal }: any) => {
  const [suggestedAddress, setSuggestedAddress] = useState({
    address_1: '',
    address_2: '',
    state: '',
    city: '',
    country: '',
    zipcode: '',
    address_id: '',
  });
  const [validating, setValidating] = useState(true);
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    recipient.country === 'US' ? usAddressSmartyRequest() : canadaAddressSmartyRequest();
  }, []);

  const usAddressSmartyRequest = () => {
    try {
      const response = axios.get(US_ADDRESS_VALIDATION_SMARTY_API_URL, {
        params: {
          key: process.env.REACT_APP_SMARTY_KEY,
          street: `${recipient.address_1} ${recipient.address_2}`,
          city: recipient.city,
          state: recipient.state,
          zipcode: recipient.zipcode,
          match: 'enhanced'
        }
      });

      response.then((res) => {
        console.log(res)
        if(res.status === 200) {
          const data = res.data[0]
          const responseAddress = {
            address_1: data.delivery_line_1 || recipient.address_1,
            address_2: '' || recipient.address_2,
            state: data.components.state_abbreviation || recipient.state,
            city: data.components.city_name || recipient.city,
            country: recipient.country,
            zipcode: data.components.zipcode || recipient.zipcode,
            address_id: data.smarty_key
          }
          setSuggestedAddress(responseAddress)
          if(isUSAddressSame(responseAddress, data)) {
            keepAddressClick(true, responseAddress.address_id)
          } else {
            setValidating(false);
          }
        } else {
          keepAddressClick(false, '');
        }
      })
    } catch (error) {
      console.error('Error validating address:', error);
    }
  }

  const canadaAddressSmartyRequest = () => {
    try {
      const response = axios.get(INTERNATIONAL_ADDRESS_VALIDATION_SMARTY_API_URL, {
        params: {
          key: process.env.REACT_APP_SMARTY_KEY,
          address1: `${recipient.address_1} ${recipient.address_2}`,
          locality: recipient.city,
          administrative_area: recipient.state,
          postal_code: recipient.zipcode,
          country: recipient.country
        }
      });

      response.then((res) => {
        console.log(res)
        if(res.status === 200) {
          const data = res.data[0]
          const responseAddress = {
            address_1: data.address1 || recipient.address_1,
            address_2: '' || recipient.address_2,
            state: data.components.administrative_area || recipient.state,
            city: data.components.locality || recipient.city,
            country: recipient.country,
            zipcode: data.components.postal_code || recipient.zipcode,
            address_id: recipient.address_id
          }
          setSuggestedAddress(responseAddress)
          if(isCanadaAddressSame(responseAddress, data)) {
            keepAddressClick(true, responseAddress.address_id)
          } else {
            setValidating(false);
          }
        } else {
          keepAddressClick(false, '');
        }
      });
    } catch (error) {
      console.error('Error validating address:', error);
    }
  }

  const isCanadaAddressSame = (newAddress: any, data: any) => {
    const errorMessages : string[] = [];
    let sameAddress = true;
    const addressChanges = data.analysis?.changes.components;

    if(addressChanges.premise !== 'Verified-NoChange' || addressChanges.thoroughfare !== 'Verified-NoChange') {
      sameAddress = false
      errorMessages.push(`Street address should be ${newAddress.address_1}`)
    }

    if(addressChanges.locality !== 'Verified-NoChange') {
      sameAddress = false
      errorMessages.push(`City should be ${newAddress.city}`)
    }

    if(addressChanges.administrative_area !== 'Verified-NoChange') {
      sameAddress = false
      errorMessages.push(`Province should be ${newAddress.state}`)
    }

    if(addressChanges.postal_code !== 'Verified-NoChange') {
      sameAddress = false
      errorMessages.push(`Postal Code should be ${newAddress.zipcode}`)
    }

    setMessages(errorMessages)
    return sameAddress
  }

  const isUSAddressSame = (newAddress: any, data: any) => {
    const errorMessages : string[] = [];
    let sameAddress = true

    if((`${newAddress.address_1} ${newAddress.address_2}`).trim() != (`${recipient.address_1} ${recipient.address_2}`).trim()) {
      sameAddress = false
      errorMessages.push(`Street address should be ${newAddress.address_1}`)
    }

    if(newAddress.city !== recipient.city) {
      sameAddress = false
      errorMessages.push(`City should be ${newAddress.city}`)
    }

    if(newAddress.state !== recipient.state) {
      sameAddress = false
      errorMessages.push(`State should be ${newAddress.state}`)
    }

    if(newAddress.zipcode !== recipient.zipcode) {
      sameAddress = false
      errorMessages.push(`Zipcode should be ${newAddress.zipcode}`)
    }

    const addressSuggestions : string[] = [],
          codes = (data?.analysis.dpv_footnotes || '') + (data?.analysis.footnotes || ''),
          errorCodes = (codes || '').match(/(..?)/g) || [];

    errorCodes.forEach((code: string) => {
      const suggestion = US_VALIDATION_ERROR_CODES_MAPPING[code as keyof typeof US_VALIDATION_ERROR_CODES_MAPPING]
      if(suggestion) {
        addressSuggestions.push(suggestion)
      }
    })

    ADDRESS_SUGGESTIONS = addressSuggestions
    setMessages(errorMessages)
    return sameAddress
  }

  const keepAddressClick = (addressValid: boolean, placeId: string) => {
    setValidating(true);
    keepAddress(recipientIndex, addressValid, placeId, messages, ADDRESS_SUGGESTIONS);
  };

  const newAddressClick = () => {
    setValidating(true);
    changeAddress(suggestedAddress, recipientIndex, ADDRESS_SUGGESTIONS);
  };

  if (validating) return <div />;

  return (
    <ModalBackdrop isOpen={open} width='w-1/3'>
      <div className='fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75'>
        <div className='w-3/4 max-w-2xl rounded-lg bg-white p-6 shadow-xl'>
          <div className="flex justify-between items-center mb-4 mt-5">
            <span className='flex-1 text-center text-xl font-bold text-gray-800'>Did you mean this?</span>
            {
              closeModal &&
              <button className="modal-close-button font-bold mr-3" onClick={() => closeModal(recipientIndex)}>
                &times;
              </button>
            }
          </div>
          <div className='flex justify-between'>
            <div className='mr-2 flex-1 rounded-lg border p-4'>
              <h3 className='mb-2 text-lg font-medium'>Provided address</h3>
              <p className='mb-1'>
                {recipient.first_name} {recipient.last_name}
              </p>
              <p className='mb-1'>
                {recipient.address_1} {recipient.address_2}
              </p>
              <p className='mb-1'>
                {recipient.city}, {recipient.state}, {recipient.zipcode}
              </p>
              <p className='mb-4'>{recipient.country}</p>
              <button
                className='rounded bg-gray-200 px-4 py-2 text-black'
                onClick={keepAddressClick.bind(this, false, '')}
              >
                Use this address
              </button>
            </div>
            <div className='ml-2 flex-1 rounded-lg border p-4'>
              <h3 className='mb-2 text-lg font-medium'>Recommended address</h3>
              <p className='mb-1'>
                {recipient.first_name} {recipient.last_name}
              </p>
              <p className='mb-1'>
                {suggestedAddress.address_1} {suggestedAddress.address_2}
              </p>
              <p className='mb-1'>
                {suggestedAddress.city}, {suggestedAddress.state}, {suggestedAddress.zipcode}
              </p>
              <p className='mb-4'>{suggestedAddress.country}</p>
              <button
                onClick={newAddressClick}
                className='rounded bg-blue-600 px-4 py-2 text-white'
              >
                Use this address
              </button>
            </div>
          </div>
          { messages.length > 0 &&
            <div className="bg-orange-100 border border-orange-400 px-4 py-3 rounded relative mt-2" role="alert">
              <strong className="font-bold">Please make sure the address is correct before you proceed</strong>
              <ul className="list-disc list-inside mt-2">
                { messages.map((message: string, index: number) => (
                  <li key='number'>{message}</li>
                ))}
              </ul>
            </div>
          }
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default ValidateAddress;
