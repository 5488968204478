const CaretDownRedIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='caret-down'>
      <path
        id='Polygon 1'
        d='M8.75593 11.1271C8.35716 11.5876 7.64284 11.5876 7.24407 11.1271L4.23682 7.65465C3.67594 7.00701 4.136 6 4.99275 6L11.0073 6C11.864 6 12.3241 7.00701 11.7632 7.65465L8.75593 11.1271Z'
        fill='#EF4444'
      />
    </g>
  </svg>
);

export default CaretDownRedIcon;
