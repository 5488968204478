import React from 'react';
import SearchIcon from '../components/Icons/SearchIcon';

interface SearchInputProps {
    search?: string;
    placeholder?: string;
    onChange: (event: any) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
    search = '',
    placeholder = '',
    onChange,
}) => {
    return (
        <>
            <div className='grid h-full w-12 place-items-center text-gray-300'>
                <SearchIcon />
            </div>
            <input
                className='peer h-full w-full pr-2 text-sm text-gray-700 outline-none rounded-lg border-transparent focus:border-transparent focus:ring-0'
                type='text'
                id='searchInput'
                name='searchInput'
                value={search}
                placeholder={placeholder}
                onChange={(e) => onChange(e)}
            />
        </>
    );
};

export default SearchInput;