import React, { useEffect, useRef } from 'react';
import { cx } from 'cva';

const ModalRevisited = ({
  title,
  isOpen,
  onClose,
  children,
  actions,
  backdrop = true,
  height = 'h-128',
  width = 'w-1/3',
}: any) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (modalRef.current && modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const backdropClasses = backdrop ? 'fixed inset-0 backdrop-blur-sm' : '';

  const classes = cx('bg-white p-2 z-50 rounded-lg mb-5', width, height);

  const modalClasses = isOpen
    ? 'fixed top-0 left-0 w-full h-full flex items-center justify-center'
    : 'hidden';

  return (
    <div className={modalClasses}>
      {backdrop && <div className={backdropClasses} ref={modalRef}></div>}
      <div className={classes}>
        <div className="flex justify-between items-center mb-4 mt-5">
          <span className='flex-1 text-center text-xl font-bold text-gray-800'>{title ?? ''}</span>
          <button className="modal-close-button font-bold mr-3" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className='container'>{children}</div>
      </div>
    </div>
  );
};

export default ModalRevisited;
