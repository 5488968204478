import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGiftForm } from '../GiftOrderContext';
import InputFormField from '../../../components/InputFormField';
import { Button } from '../../../components/Button';
import { Button as Button1 } from '../../../catalyst/button';

export interface Variant {
  id: string;
  quantity: any;
}
export interface VariantOption {
  name: string;
  value: string;
  disabled: boolean;
}

const ProductVariants = ({
  product,
  updateProductData,
  setRecipientVariant,
  closeModal,
}: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { recipients } = useGiftForm();
  const [productVariants, setProductVariants] = useState<VariantOption[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<Variant[]>([]);

  useEffect(() => {
    resetSelectedVariants()
  }, [recipients])

  useEffect(() => {
    refreshProductVariants();
  }, [selectedVariants, product])

  const setSelectedVariant = (event: any, index: number) => {
    const id = event.target.value;
    const variants = [...selectedVariants];

    variants[index] = { id: id, quantity: 1 }
    setSelectedVariants(variants)

    setValue(`variant_${index}`, id)
    refreshProductVariants()
  }

  const resetSelectedVariants = () => {
    const variants = recipients.map((r: any, i: number) => {
      setValue(`variant_${i}`, r.variant_id);
      return { id: r.variant_id, quantity: r.quantity }
    })
    setSelectedVariants(variants);
  }

  const refreshProductVariants = () => {
    if (product && product.variants) {
      const variants = product.variants.map(({ id, title, quantity }: any) => {
        const selectedQty = selectedVariants.reduce((qty, v) => (v.id === id ? parseInt(v.quantity || 0, 10) + qty : qty ), 0)

        if (quantity == 0) {
            return { value: id, name: `${title} (out of stock)`, disabled: true }
        } else {
          if (selectedQty == 0 ) {
            return { value: id, name: `${title} (${quantity} in stock)`, disabled: false }
          } else {
            return { value: id, name: `${title} (${quantity} in stock / ${selectedQty} selected)`, disabled: (quantity === selectedQty) }
          }
        }
      })

      setProductVariants(variants);
    }
  }

  const updateGiftVariants = () => {
    updateProductData(product)

    selectedVariants.map((variant: any, idx: number) => {
      setRecipientVariant(idx, { id: variant.id })
    })

    onModalClose();
  };

  const onModalClose = () => {
    resetSelectedVariants()
    closeModal();
  };

  return <>
    <div className='relative'>
      <div className='flex justify-end'>
        <button
          type='button'
          className='pr-5 pt-3 text-xl font-light'
          onClick={() => onModalClose()}
        >
          &times;
        </button>
      </div>
    </div>
    <div className='flex flex-row justify-center'>
      <p className='text-center text-xl font-bold'>
        Pick variants for each recipient
      </p>
    </div>
    <form onSubmit={handleSubmit(updateGiftVariants)} className='w-full rounded px-8 pb-0 pt-6'>
      {recipients.map((recipient: any, index: any) => (
        <div className='py-3' key={index}>
          <InputFormField
            inputType='select'
            options={productVariants}
            label={`Recipient ${index + 1}: ${recipient.first_name} ${recipient.last_name}`}
            name={`variant_${index}`}
            isRequired={true}
            register={register}
            validation={{ required: 'This field is required' }}
            errors={errors}
            onChange={(e: any) => { setSelectedVariant(e, index) }}
          />
        </div>
      ))}
      <div className='border-blueGray-200 mb-3 flex items-center justify-center space-x-4 rounded-b p-6'>
        <Button type='outline' rounded={true} onClick={() => onModalClose()}>
          Cancel
        </Button>
        <Button1
          type='submit'
          color='blue'
          className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
        >
          Update
        </Button1>
      </div>
    </form>
  </>
}

export default ProductVariants;
