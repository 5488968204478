import Wrapper from '../components/Wrapper';
import { Button } from '../catalyst/button';
import StrongPasswordItem, { CorrectIcon, WrongIcon } from '../components/StrongPasswordItem';
import { useEffect, useState } from 'react';
import InputGroupField from '../components/InputGroupField';
import { useForm } from 'react-hook-form';
import InputFormField from '../components/InputFormField';
import ExclemationCircleFill from '../components/Icons/ExclamationCircleFill';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '../components/Toast/ToastProvider';
import { PASSWORD_REGEX } from '../constants/variable';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    const source = axios.CancelToken.source();
    validateToken(source);
    return () => source.cancel();
  }, []);

  const validateToken = async (source: any) => {
    const token = searchParams.get('token');
    if (!token) {
      toast?.pushError('Invalid token. Please try again!', 5000);
      navigate('/forgot-password');
      return;
    }

    const payload = {
      token: token,
    };
    const res = await axios
      .get(baseUrl + `/api/users/isActiveToken`, {
        params: payload,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (!data.data) {
          toast?.pushError('Token expired. Please try again!', 5000);
          navigate('/forgot-password');
          return;
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  async function onSubmit(values: any) {
    const payload = {
      password: getValues().password.trim(),
      password_confirmation: getValues().confirmPassword.trim(),
      type: searchParams.get('type'),
      token: searchParams.get('token'),
    };

    const res = await axios
      .post(baseUrl + '/api/users/updatePassword', payload)
      .then(function (response) {
        if (response.status === 200) {
          toast?.pushSuccess('Password updated successfully! Please login now', 5000);
          navigate('/login');
        } else {
          toast?.pushError('Error setting up the password. Please try again!', 5000);
        }
      })
      .catch(function (error) {
        toast?.pushError('Error setting up the password. Please try again!', 5000);
      });
  }

  const validatePassword = (value: string) => {
    if (getValues().password !== getValues().confirmPassword) {
      return 'Passwords do not match';
    } else {
      return true;
    }
  };

  const validatePasswordStrength = (value: string) => {
    if (getValues().password) {
      const regex = PASSWORD_REGEX;
      return regex.test(value) || 'Invalid Password format! Allowed special chars #?!@$%^&*-';
    } else {
      return true;
    }
  };

  function redirectLogin() {
    navigate('/login');
  }

  return (
    <>
      <Wrapper className='flex flex-col items-center justify-center'>
        <div className='flex min-h-screen items-center justify-center'>
          <div className='flex h-24 items-center justify-between rounded-lg'>
            <div className='flex flex-col px-4'>
              <img src='/assets/reset-password.svg' alt='reset-password' />
            </div>
            <div className='grid justify-between gap-6'>
              {searchParams.get('type') == 'Invite' ? (
                <div className='flex justify-center px-4 text-2xl font-semibold'>
                  Activate your account
                </div>
              ) : (
                <div className='flex justify-center px-4 text-2xl font-semibold'>
                  Reset your password
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex min-w-96 flex-col p-4'>
                  <InputFormField
                    id='password'
                    label='Your new password'
                    name='password'
                    type='password'
                    register={register}
                    validation={{
                      required: 'This field is required',
                      validate: validatePasswordStrength,
                    }}
                    errors={errors}
                    isRequired={true}
                    isRequiredLabel={false}
                  />
                  <div
                    id='hs-strong-password'
                    data-hs-strong-password='{
                      "target": "#password",
                      "hints": "#hs-strong-password-hints",
                      "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1"
                    }'
                    className='-mx-1 mt-2 flex'
                  ></div>
                  <div id='hs-strong-password-hints' className='mb-3'>
                    <div>
                      <span className='text-sm text-gray-800 dark:text-gray-200'>Level:</span>
                      <span
                        data-hs-strong-password-hints-weakness-text='["Empty", "Weak", "Medium", "Strong", "Very Strong", "Super Strong"]'
                        className='text-sm font-semibold text-gray-800 dark:text-gray-200'
                      ></span>
                    </div>

                    <h4 className='my-2 text-sm font-semibold text-gray-800 dark:text-white'>
                      Your password must contain:
                    </h4>

                    <ul className='space-y-1 text-sm text-gray-500'>
                      <li
                        data-hs-strong-password-hints-rule-text='min-length'
                        className='flex items-center gap-x-2 hs-strong-password-active:text-teal-500'
                      >
                        <span className='hidden' data-check>
                          <CorrectIcon />
                        </span>
                        <span data-uncheck>
                          <WrongIcon />
                        </span>
                        Minimum number of characters is 6.
                      </li>
                      <li
                        data-hs-strong-password-hints-rule-text='lowercase'
                        className='flex items-center gap-x-2 hs-strong-password-active:text-teal-500'
                      >
                        <span className='hidden' data-check>
                          <CorrectIcon />
                        </span>
                        <span data-uncheck>
                          <WrongIcon />
                        </span>
                        Should contain lowercase.
                      </li>
                      <li
                        data-hs-strong-password-hints-rule-text='uppercase'
                        className='flex items-center gap-x-2 hs-strong-password-active:text-teal-500'
                      >
                        <span className='hidden' data-check>
                          <CorrectIcon />
                        </span>
                        <span data-uncheck>
                          <WrongIcon />
                        </span>
                        Should contain uppercase.
                      </li>
                      <li
                        data-hs-strong-password-hints-rule-text='numbers'
                        className='flex items-center gap-x-2 hs-strong-password-active:text-teal-500'
                      >
                        <span className='hidden' data-check>
                          <CorrectIcon />
                        </span>
                        <span data-uncheck>
                          <WrongIcon />
                        </span>
                        Should contain numbers.
                      </li>
                      <li
                        data-hs-strong-password-hints-rule-text='special-characters'
                        className='flex items-center gap-x-2 hs-strong-password-active:text-teal-500'
                      >
                        <span className='hidden' data-check>
                          <CorrectIcon />
                        </span>
                        <span data-uncheck>
                          <WrongIcon />
                        </span>
                        Should contain special characters. eg: #?!@$%^&*-
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='flex min-w-96 flex-col p-4'>
                  <InputFormField
                    id='confirmPassword'
                    label='Confirm new password'
                    name='confirmPassword'
                    type='password'
                    register={register}
                    validation={{ required: 'This field is required', validate: validatePassword }}
                    errors={errors}
                    isRequired={true}
                    isRequiredLabel={false}
                  />
                </div>
                <div className='flex min-w-24 justify-center p-4'>
                  <Button
                    type='submit'
                    color='blue'
                    className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
                  >
                    Save my new password
                  </Button>
                </div>
              </form>

              <div
                style={{ color: '#6B7280' }}
                className='flex cursor-pointer justify-center'
                onClick={() => redirectLogin()}
              >
                Login
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ResetPassword;
