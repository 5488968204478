import Wrapper from '../../components/Wrapper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button as Button1 } from '../../catalyst/button';
import React from 'react';
import { useModal } from '../../hooks/useModal';
import axios from 'axios';
import Pagination from '../../components/tablePagination/Pagination';
import SearchInput from '../../components/SearchInput';
import { format } from 'date-fns';
import { PaginationConstants, TEMPLATE_SORT_COL_MAP } from '../../constants/variable';
import { Button } from '../../components/Button';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';

import EditIcon from '../../components/Icons/EditIcon';
import InputFormField from '../../components/InputFormField';
import { Badge } from '../../catalyst/badge';
import { useToast } from '../../components/Toast/ToastProvider';
import Tooltip from '../../components/Tooltip';

type FormValues = {
  title: string;
  body: string;
};

const defaultAddTemplateValues = {
  title: '',
  body: '',
};

const TemplateList = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const [templates, setTemplates] = useState([]);
  const [search, setSearch] = useState<string>();
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [count, setCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [charCount, setCharCount] = useState(0);
  const [lineCount, setLineCount] = useState(0);
  const [orderBy, setOrderBy] = useState<string>(TEMPLATE_SORT_COL_MAP.default);
  const [orderByType, setOrderByType] = useState<string>('desc');

  const [state, setState] = useState({
    id: '',
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultAddTemplateValues,
  });

  useEffect(() => {
    fetchTemplates(search, true);
  }, [search, orderBy, orderByType]);

  useEffect(() => {
    fetchTemplates(search, false);
  }, [currentPage]);

  const submitTemplate = () => {
    const title = getValues().title.trim();
    const body = getValues().body;
    postTemplate(title, body);
  };

  const postTemplate = (title: string, body: string) => {
    const templateData = {
      title: title,
      body: body,
      char_count: charCount,
      line_count: lineCount,
      id: state.id,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (state.id == '') {
      axios
        .post(baseUrl + '/api/admin/template/create', templateData, config)
        .then((response) => {
          onModalClose();
          fetchTemplates(search, true);
          setCharCount(0);
          setLineCount(0);
          toast?.pushSuccess('Template added successfully!', 5000);
        })
        .catch((error) => {
          toast?.pushError('Template added failed! Please try again.', 5000);
          console.log(error.response.data.error);
        });
    } else {
      axios
        .put(baseUrl + '/api/admin/template/edit/' + state.id, templateData, config)
        .then((response) => {
          onModalClose();
          fetchTemplates(search, false);
          setCharCount(0);
          setLineCount(0);
          toast?.pushSuccess('Template updated successfully!', 5000);
        })
        .catch((error) => {
          toast?.pushError('Template update failed! Please try again.', 5000);
          console.log(error.response.data.error);
        });
    }
  };

  const onModalClose = () => {
    reset(defaultAddTemplateValues);
    closeModal();
    setCharCount(0);
    setLineCount(0);
  };

  const handleCharCount = (ev: any) => {
    setValue('body', ev.target.value);
    setCharCount(ev.target.value.length);
    const line = ev.target.value.split(/\r|\r\n|\n/);
    setLineCount(line.length);
  };
  const fetchTemplates = async (search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;

    if (orderBy !== '' && orderByType !== '') {
      params.orderBy = orderBy;
      params.orderByType = orderByType;
    }

    const res = await axios
      .get(baseUrl + '/api/admin/template/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setCount(data.data.pagination.total_records);
          setTemplates(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        console.log(error.response.data.error);
      });
  };

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='relative'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onModalClose()}
            >
              &times;
            </button>
          </div>
          <div className='flex flex-row justify-center'>
            <p className='text-center text-xl font-bold'>
              {state.id != '' ? 'Update template' : 'Add new template'}
            </p>
          </div>
          <form onSubmit={handleSubmit(submitTemplate)} className='w-full rounded px-8 pb-0 pt-6'>
            <div className='py-3'>
              <InputFormField
                name='title'
                label='Title'
                isRequired={true}
                isRequiredLabel
                register={register}
                validation={{ required: 'This field is required' }}
                errors={errors}
              />
            </div>
            <div className='py-3'>
              <InputFormField
                name='body'
                inputType={'textarea'}
                label='Message'
                isRequired={true}
                isRequiredLabel
                register={register}
                validation={{ required: 'This field is required' }}
                errors={errors}
                onChange={handleCharCount}
                className='min-h-28 resize-y'
              />
              <p className='px-1 py-1 text-xs'>{charCount} characters</p>
            </div>
            <div className='border-blueGray-200 mb-3 flex items-center justify-center space-x-4 rounded-b p-6'>
              <Button type='outline' rounded={true} onClick={() => onModalClose()}>
                Cancel
              </Button>
              <Button1
                type='submit'
                color='blue'
                className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
              >
                {state.id != '' ? 'Update template' : 'Add new template'}
              </Button1>
            </div>
          </form>
        </div>
      </>
    ),
  });

  function disable(data: any, flag: boolean): void {
    const templateEnabledData = {
      enabled: flag,
    };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .put(baseUrl + '/api/admin/template/toggle/' + data.id, templateEnabledData, config)
      .then((response) => {
        onModalClose();
        fetchTemplates(search, false);
        toast?.pushSuccess('Template updated successfully!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Template update failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  }

  function openEditModal(data: any): void {
    setValue('title', data.title);
    setValue('body', data.body);
    setCharCount(data.body.length);
    setLineCount(data.line_count);
    setState(() => ({
      id: data.id,
    }));
    openModal();
  }

  function openEmptyStateModal() {
    setState((prevState) => ({
      ...prevState,
      id: '',
    }));
    setCharCount(0);
    reset(defaultAddTemplateValues);
    openModal();
  }

  const applySorting = (columnName: string) => {
    if (orderBy === columnName && orderByType === 'asc') {
      setOrderBy(columnName);
      setOrderByType('desc');
      setCurrentPage(1);
    } else {
      setOrderBy(columnName);
      setOrderByType('asc');
      setCurrentPage(1);
    }
  };

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        <div className='flex justify-between'>
          <div className='flex-auto'>
            <p className='text-left text-2xl font-semibold'>Templates ({count})</p>
          </div>
          <div className='relative mr-5 flex h-12 w-1/3 items-center overflow-hidden rounded-lg border border-slate-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
            <SearchInput
              onChange={searchData}
              search={search}
              placeholder='Search by key words'
            ></SearchInput>
          </div>
          <div>
            <Button
              type='outlineGreen'
              className='text-green-600 outline-teal-500'
              rounded={true}
              onClick={() => openEmptyStateModal()}
            >
              Add new template
            </Button>
          </div>
        </div>
        <div className='py-4'>
          <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
            <TableHead className='overflow-hidden bg-gray-50'>
              <TableRow>
                <TableHeader className='w-1/4 px-2 text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(TEMPLATE_SORT_COL_MAP.title)}
                  >
                    <span className='mr-1'>TITLE</span>
                    {orderBy === TEMPLATE_SORT_COL_MAP.title ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='w-2/4 text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(TEMPLATE_SORT_COL_MAP.content)}
                  >
                    <span className='mr-1'>CONTENT</span>
                    {orderBy === TEMPLATE_SORT_COL_MAP.content ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>
                  <button
                    className='flex items-center'
                    onClick={() => applySorting(TEMPLATE_SORT_COL_MAP.updated_at)}
                  >
                    <span className='mr-1'>LAST MODIFIED</span>
                    {orderBy === TEMPLATE_SORT_COL_MAP.updated_at ? (
                      orderByType === 'asc' ? (
                        <CaretUp />
                      ) : (
                        <CaretDown />
                      )
                    ) : (
                      <CaretUpDown />
                    )}
                  </button>
                </TableHeader>
                <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className='overflow-hidden bg-white'>
              {templates.map((data: any) => (
                <TableRow key={data.handle}>
                  <TableCell className='w-1/4 text-gray-800'>
                    {data.title} {data.disabled ? <Badge color='red'>Disabled</Badge> : ''}
                  </TableCell>
                  <TableCell className='w-2/4 text-gray-600'>{data.body}</TableCell>
                  <TableCell className='text-gray-800'>
                    {data.updated_at ? format(data.updated_at, 'MM/dd/yyyy') : ''}
                  </TableCell>
                  <TableCell>
                    <span className='inline-flex items-center'>
                      <span onClick={() => openEditModal(data)} className='cursor-pointer'>
                        <Tooltip message='Edit'>
                          <EditIcon />
                        </Tooltip>
                      </span>
                      {data.disabled ? (
                        <span
                          className='item cursor-pointer px-8 text-gray-500'
                          onClick={() => disable(data, true)}
                        >
                          Enable
                        </span>
                      ) : (
                        <span
                          className='item cursor-pointer px-8 text-gray-500'
                          onClick={() => disable(data, false)}
                        >
                          Disable
                        </span>
                      )}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {templates && templates.length == 0 && (
            <div className='pt-5 text-center text-gray-500'>{'No templates found'}</div>
          )}
          {templates && templates.length > 0 && (
            <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                maxLength={5}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </Wrapper>
      {modal}
    </>
  );
};

export default TemplateList;
