import { useState, useEffect } from 'react';
import Select from '../../../components/SelectInput';
import { useGiftForm, defaultMessageValues } from '../GiftOrderContext';
import { useForm } from 'react-hook-form';
import AccordionGroup from '../../../components/AccordionGroup';
import InputFormField from '../../../components/InputFormField';
import TemplateSwitchingModal from './TemplateSwitchingModal';
import { Button } from '../../../components/Button';
import FileEditIcon from '../../../components/Icons/FileEdit';
import { TEMPLATE_CHAR_LIMIT_DEFAULT } from '../../../constants/variable';
import EmailSampleModal from './EmailSampleModal';
import { fieldRequired } from '../helper';

type FormValues = {
  printed_message: string;
  is_custom_email: boolean;
  email_message: string;
};

export default function TemplateForm({
  templates,
  isMultipleTemplate,
  messageIndex,
  recipient,
  messages,
  onSubmit,
  validateForm,
  onValidationComplete,
  handleCustomizeForEachClick,
}: any) {
  const [printedMessageLength, setPrintedMessageLength] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [emailSampleModal, setEmailSampleModal] = useState(false);
  const [existingPrintedMessage, setExistingPrintedMessage] = useState(
    messages[messageIndex] ? messages[messageIndex]['printed_message'] : '',
  );
  const { recipients, giftProduct, setMessages } = useGiftForm();
  const characterLimit = Number(giftProduct.char_count) || TEMPLATE_CHAR_LIMIT_DEFAULT;
  const lineCountLimit = Number(giftProduct.line_count) || 1000;

  const [isCustomEmail, setCustomEmail] = useState(
    messages[messageIndex] ? messages[messageIndex]['is_custom_email'] : false,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm<FormValues>({
    defaultValues: messages[messageIndex] || defaultMessageValues,
  });

  useEffect(() => {
    if (validateForm) {
      const formValidation = trigger();
      formValidation.then((val) => {
        onValidationComplete(val, messageIndex);
      });
    }
  }, [validateForm, onValidationComplete]);

  const resetForm = () => {
    reset(defaultMessageValues);
    const formValues = getValues();
    formValues['is_custom_email'] = isCustomEmail;
    onSubmit(formValues, messageIndex);
  };

  const handleTemplateChange = (ev: any) => {
    if (ev.target.value.length > characterLimit) {
      setError('printed_message', {
        type: 'manual',
        message: 'The template will exceed character limit for the gift. Please select another',
      });
      return;
    } else {
      clearErrors('printed_message');
      const existingMessage = getValues()['printed_message'];
      if (existingMessage) {
        setExistingPrintedMessage(existingMessage);
        if(isTemplateSwitchingModalRequired()) {
          setShowModal(true);
        } else {
          copyContent(existingMessage);
        }
      }
      setValue('printed_message', ev.target.value);
      setPrintedMessageLength(ev.target.value.length);
      onSubmit(getValues(), messageIndex);
    }
  };

  const isTemplateSwitchingModalRequired = () => {
    return !sessionStorage.getItem('skipTemplateCopyModal');
  }

  const copyContent = (existingMessage: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(existingMessage)
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  }

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    const fieldType = event.target.type;
    const fieldValue = fieldType === 'checkbox' ? event.target.checked : event.target.value;
    setValue(fieldName, fieldValue);
    const formValues = getValues();
    onSubmit(formValues, messageIndex);
  };

  const handleCustomEmailCheckbox = (ev: any) => {
    const isChecked = ev.target.checked;
    setValue('is_custom_email', isChecked);
    const formValues = getValues();
    onSubmit(formValues, messageIndex);
    setCustomEmail(isChecked);
  };

  const numberOfLinesExceeded = (value: string) => {
    const lines = value.split('\n');

    return lines.length > lineCountLimit;
  };

  const handlePrintedMessageChange = (ev: any) => {
    let text = ev.target.value;
    if (text.length > characterLimit) {
      text = text.slice(0, characterLimit);
    }
    clearErrors('printed_message');
    setPrintedMessageLength(text.length);
    setValue('printed_message', text);
  };

  const openEmailSampleModal = () => {
   setEmailSampleModal(true)
  }

  return (
    <>
      <div className='grid-row grid w-2/5 gap-4 p-2'>
        <form name='send-gift-form'>
          <div className='ml-4 mt-4'>
            <AccordionGroup
              title={
                isMultipleTemplate || recipients.length === 1
                  ? `Recipient ${messageIndex + 1}. ${recipient.first_name + ' ' + recipient.last_name}`
                  : `Recipients(${recipients.length})`
              }
              handleClear={(num: number) => resetForm()}
              hideChevron={!isMultipleTemplate}
              isClear={true}
              listLocation={messageIndex + 1}
            >
              <div style={{ display: giftProduct.is_digital_gift ? 'none' : 'block' }}>
                <Select
                  label='Select a template'
                  options={templates}
                  value=''
                  onChange={handleTemplateChange}
                />
                <InputFormField
                  name='printed_message'
                  label='Printed Message'
                  register={register}
                  errors={errors}
                  onBlur={handleBlur}
                  inputType={'textarea'}
                  validation={{
                    required: 'This field is required',
                    validate: {
                      charLimit: (value: string) =>
                        !numberOfLinesExceeded(value) ||
                          `The gift has ${lineCountLimit} lines limitation for this note`,
                      present: fieldRequired
                    }
                  }}
                  onChange={handlePrintedMessageChange}
                  isRequiredLabel={false}
                />
                <p>
                  {characterLimit > printedMessageLength
                    ? characterLimit - printedMessageLength
                    : 0}{' '}
                  characters left
                </p>
              </div>
              <div
                className='mt-4 flex pl-1'
                style={{ display: giftProduct.is_digital_gift ? 'none' : 'block' }}
              >
                <input
                  type='checkbox'
                  checked={isCustomEmail}
                  name='isCustomEmail'
                  onChange={(ev) => handleCustomEmailCheckbox(ev)}
                  className='mt-0.5 shrink-0 rounded border-gray-200 text-blue-600 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-800 dark:checked:border-blue-500 dark:checked:bg-blue-500 dark:focus:ring-offset-gray-800'
                />
                <label className='ms-3 text-sm text-gray-500 dark:text-neutral-400'>
                  Add custom email message
                </label>
              </div>
              {(isCustomEmail || giftProduct.is_digital_gift) && (
                <div className='mt-4'>
                  <InputFormField
                    name='email_message'
                    label='Your email message'
                    register={register}
                    errors={errors}
                    onBlur={handleBlur}
                    inputType={'textarea'}
                    validation={isCustomEmail ? { required: 'This field is required', validate: { present: fieldRequired } } : {}}
                    isRequiredLabel={false}
                    helpText={'Custom email note will be sent along with the gift incoming email notification. See example here.'}
                    onHelpTextClick={openEmailSampleModal}
                  />
                </div>
              )}
              {recipients.length > 1 && !isMultipleTemplate && (
                <div className='container mt-5 flex justify-center'>
                  <Button type='outline' onClick={handleCustomizeForEachClick}>
                    <FileEditIcon />
                    Customise template for each recipient
                  </Button>
                </div>
              )}
            </AccordionGroup>
          </div>
        </form>
      </div>
      {showModal && (
        <TemplateSwitchingModal
          showModal={true}
          setShowModal={setShowModal}
          textToCopy={existingPrintedMessage}
        />
      )}
      { emailSampleModal &&
        <EmailSampleModal
          onClose={() => setEmailSampleModal(false)}
        />
      }
    </>
  );
}
