const CaIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Avatar'>
      <g clipPath='url(#clip0_952_25623)'>
        <path id='Vector' d='M2.53436 0H13.8562V16H2.53436V0Z' fill='white' />
        <path
          id='Vector_2'
          d='M-3.125 0H2.53438V16H-3.125V0ZM13.8563 0H19.5156V16H13.8563V0ZM4.22813 7.73125L3.79063 7.88125L5.83438 9.67813C5.99063 10.1406 5.78125 10.275 5.64688 10.5188L7.86563 10.2375L7.80938 12.4719L8.27188 12.4563L8.16875 10.2406L10.3937 10.5031C10.2562 10.2125 10.1344 10.0594 10.2594 9.59688L12.3031 7.89375L11.9469 7.76562C11.6531 7.5375 12.0719 6.67812 12.1344 6.13438C12.1344 6.13438 10.9438 6.54375 10.8656 6.32812L10.5562 5.75L9.475 6.9375C9.35625 6.96875 9.30625 6.91875 9.27813 6.81875L9.77813 4.32812L8.98438 4.775C8.91875 4.80312 8.85313 4.775 8.80938 4.7L8.04375 3.16875L7.25625 4.75938C7.19688 4.81563 7.1375 4.82188 7.0875 4.78438L6.33125 4.35938L6.78438 6.83437C6.75 6.92812 6.6625 6.95937 6.5625 6.90625L5.52188 5.725C5.3875 5.94375 5.29375 6.3 5.11563 6.38125C4.9375 6.45312 4.33437 6.22813 3.93125 6.14062C4.06875 6.6375 4.5 7.4625 4.22813 7.73438V7.73125Z'
          fill='#D52B1E'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_952_25623'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z'
          fill='white'
        />
      </clipPath>
    </defs>
  </svg>
);

export default CaIcon;
