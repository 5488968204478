import Wrapper from '../components/Wrapper';
import { Button } from '../catalyst/button';
import { useForm } from 'react-hook-form';
import InputFormField from '../components/InputFormField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '../components/Toast/ToastProvider';
import { EMAIL_REGEX } from '../constants/variable';

type FormValues = {
  email: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm();

  async function onSubmit(values: any) {
    const payload = {
      email: getValues().email.trim().toLowerCase(),
    };

    const res = await axios
      .post(baseUrl + '/api/users/resetPassword', payload)
      .then(function (response) {
        if (response.status === 200) {
          toast?.pushSuccess('Password reset email sent.', 5000);
          navigate('/login');
        } else {
          toast?.pushError('Error sending password reset email. Please try again!', 5000);
        }
      })
      .catch(function (error) {
        toast?.pushError('Error sending password reset email. Please try again!', 5000);
      });
  }

  function redirectLogin() {
    navigate('/login');
  }

  return (
    <>
      <Wrapper className='flex flex-col items-center justify-center'>
        <div className='flex min-h-screen items-center justify-center'>
          <div className='flex h-24 items-center justify-between rounded-lg'>
            <div className='flex flex-col px-4'>
              <img src='/assets/reset-password.svg' alt='reset-password' />
            </div>
            <div className='grid w-64 justify-between gap-4'>
              <div className='flex justify-center px-4 text-2xl font-semibold'>
                Forgot your password?
              </div>
              <div className='flex justify-center px-4 text-base font-normal'>
                Enter your email address and we will send you instructions to reset your password.
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex min-w-96 flex-col p-4'>
                  <InputFormField
                    id='email'
                    label='Your email'
                    name='email'
                    type='text'
                    register={register}
                    validation={{
                      required: 'This field is required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter a valid email address',
                      },
                    }}
                    errors={errors}
                    isRequired={true}
                    isRequiredLabel={false}
                  />
                </div>
                <div className='justify-bewtween flex'>
                  <div className='flex-1 px-5 py-6'>
                    <div
                      style={{ color: '#6B7280' }}
                      className='mt-5 flex cursor-pointer'
                      onClick={() => redirectLogin()}
                    >
                      Login
                    </div>
                  </div>
                  <div className='px-5 py-6 '>
                    <Button
                      type='submit'
                      color='blue'
                      className='m-2 h-12 w-32 cursor-pointer rounded-lg px-6 text-base'
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;
