import Wrapper from '../../components/Wrapper';
import { useSearchParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Tabs, Tab } from '../../components/TabsItem';
import React from 'react';
import axios from 'axios';
import SearchInput from '../../components/SearchInput';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';
import { useToast } from '../../components/Toast/ToastProvider';
import Pagination from '../../components/tablePagination/Pagination';
import { PaginationConstants, ORDER_SORT_COL_MAP, ORDER_ENQUIRY_FORM_URL } from '../../constants/variable';
import ReOrderIcon from '../../components/Icons/ReOrderIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import ListIconBlack from '../../components/Icons/ListIconBlack';
import SupportIcon from '../../components/Icons/SupportIcon';
import { Avatar } from '../../catalyst/avatar';
import { Badge } from '../../catalyst/badge';
import { format } from 'date-fns';
import { useModal } from '../../hooks/useModal';
import Datepicker from 'react-tailwindcss-datepicker';
import DropdownComponent, {
  DropdownItem,
} from '../../components/DropdownComponent/DropdownComponent';
import MultiDropdownWithSearch, {
  MultiDropdownItem,
} from '../../components/MultiDropdownWithSearch/MultiDropdownWithSearch';
import DismissBadge from '../../components/DismissBadge';
import CaretDownRedIcon from '../../components/Icons/CaretDownRedIcon';
import CaretUpGreenIcon from '../../components/Icons/CaretUpGreenIcon';
import { Line, LineChart } from 'recharts';
import Timeline from '../../components/Timeline';
import Tooltip from '../../components/Tooltip';
import ProductCard from '../../components/ProductCard';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';
import Loader from '../../components/Icons/Loader';

const FormData = require('form-data');

type OrderFilterOptions = {
  value: string;
  label: string;
};

type OrderFilter = {
  key: string;
  label: string;
  type: string;
  options: OrderFilterOptions[];
};

type OrderEvent = {
  status: string;
  happened_at: string;
  tracking_number: string;
};

const OrdersListAdmin = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const [orders, setOrders] = useState([]);
  const [myOrders, setMyOrders] = useState([]);
  const [orderCount, setOrderCount] = useState([]);
  const [myOrderCount, setMyOrderCount] = useState([]);
  const [search, setSearch] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [myOrdersCurrentPage, setMyOrdersCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [myOrdersLastPage, setMyOrdersLastPage] = useState(1);
  const [orderDetail, setOrderDetail] = useState<any>({});
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [dateValue, setDateValue] = useState<any>({});
  const [orderPlacedBy, setOrderPlacedBy] = useState<string>();
  const [orderEvents, setOrderEvents] = useState<OrderEvent[]>([]);
  const [orderFilterData, setOrderFilterData] = useState<OrderFilter[]>();
  const [preFilterData, setPreFilterData] = useState<DropdownItem[]>();
  const [subFilterData, setSubFilterData] = useState<MultiDropdownItem[]>();
  const [selectedFilter, setSelectedFilter] = useState<DropdownItem | undefined>();
  const [selectedSubFilter, setSelectedSubFilter] = useState<MultiDropdownItem[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalOrdersWidget, setTotalOrdersWidget] = useState<any>();
  const [ordersDeliveredWidget, setOrdersDeliveredWidget] = useState<any>();
  const [ordersPendingWidget, setOrdersPendingWidget] = useState<any>();
  const [ordersCompanyWidget, setOrdersCompanyWidget] = useState<any>();
  const [ordersGiftWidget, setOrdersGiftWidget] = useState<any>();
  const [allOrdersOrderBy, setAllOrdersOrderBy] = useState<string>(ORDER_SORT_COL_MAP.default);
  const [allOrdersOrderByType, setAllOrdersOrderByType] = useState<string>('desc');
  const [myOrdersOrderBy, setMyOrdersOrderBy] = useState<string>(ORDER_SORT_COL_MAP.default);
  const [myOrdersOrderByType, setMyOrdersOrderByType] = useState<string>('desc');
  const token = localStorage.getItem('ACCESS_TOKEN');
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const [hideDropdownOptions, setHideDropdownOptions] = useState<boolean>(false);
  const [bulkUploadFile, setBulkUploadFile] = useState<any>(null);
  const [bulkUploadError, setBulkUploadError] = useState<string>('');
  const [bulkUploadResponse, setBulkUploadResponse] = useState<any>({});
  const [bulkUploadInProgress, setBulkUploadInProgress] = useState(false);
  const [dateType, setDateType] = useState('updated_at');

  const filterTypePlaceholderMap = new Map<string, string>();
  filterTypePlaceholderMap.set('sales_rep', 'Search by name or email');
  filterTypePlaceholderMap.set('recepients', 'Search by name or email');
  filterTypePlaceholderMap.set('company', 'Search by company');
  filterTypePlaceholderMap.set('gift', 'Search by product');

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchPreFilterData(source);
    prefillData();
    setHideDropdownOptions(true);
    return () => source.cancel();
  }, []);

  const prefillData = async () => {
    if (searchParams.size > 0) {
      const subFilterList: MultiDropdownItem[] = [];
      const selectedDateValue: any = {};
      const selectedSubFilterObj: any = { id: '', name: '' };
      searchParams.forEach((value, key) => {
        switch (key) {
          case 'start_date':
            selectedDateValue.startDate = decodeURI(value);
            break;
          case 'end_date':
            selectedDateValue.endDate = decodeURI(value);
            break;
          case 'date_filter':
            break;
          case 'date_type':
              break;
          default: {
            if (selectedSubFilterObj?.id == '') {
              selectedSubFilterObj.id = key;
            }
            const itemList = value.split(',');
            for (const val of itemList) {
              const valueList = val.split('|');
              const selectedItemObj: MultiDropdownItem = {
                parentKey: key,
                label: valueList[1],
                value: valueList[0],
              };
              subFilterList.push(selectedItemObj);
            }
          }
        }
      });
      setSelectedSubFilter(() => {
        return subFilterList;
      });
      if (selectedSubFilterObj.id != '') {
        setSelectedFilter(selectedSubFilterObj);
      }
      if (selectedDateValue.startDate != '' && selectedDateValue.endDate != '') {
        setDateValue(selectedDateValue);
      }
    }
  };

  useEffect(() => {
    if (!selectedFilter || !preFilterData) {
      return;
    }
    if (selectedFilter.id != '' && selectedFilter.name == '') {
      for (const orderFilterObj of preFilterData) {
        if (orderFilterObj.id == selectedFilter.id) {
          resetSubFilterData(orderFilterObj.id);
          setSelectedFilter(orderFilterObj);
          setShowFilters(true);
          break;
        }
      }
    }
  }, [prefillData]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    // widgets
    fetchTotalOrdersWidgets(source);
    fetchOrdersDeliveredWidgets(source);
    fetchOrdersPendingWidgets(source);
    fetchOrdersCompanyWidgets(source);
    fetchOrdersGiftWidgets(source);

    return () => source.cancel();
  }, [searchParams]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchAllOrders(source, search, true);
    fetchMyOrders(source, search, true);
    return () => source.cancel();
  }, [search, searchParams]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchAllOrders(source, search, true);
    return () => source.cancel();
  }, [allOrdersOrderBy, allOrdersOrderByType]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchMyOrders(source, search, true);
    return () => source.cancel();
  }, [myOrdersOrderBy, myOrdersOrderByType]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchAllOrders(source, search, false);
    return () => source.cancel();
  }, [currentPage]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchMyOrders(source, search, false);
    return () => source.cancel();
  }, [myOrdersCurrentPage]);

  useEffect(() => {
    updateSearchParams();
  }, [selectedSubFilter, dateValue, dateType]);

  const updateSearchParams = () => {
    const paramMap = new Map<string, string>();
    if (selectedSubFilter) {
      for (const item of selectedSubFilter) {
        const mapValue = paramMap.get(item.parentKey);
        if (mapValue) {
          const newValue = `${mapValue},${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        } else {
          const newValue = `${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        }
      }
    }

    if (dateValue.startDate != null && dateValue.endDate != null) {
      paramMap.set('date_filter', 'custom');
      paramMap.set('date_type', dateType);
      paramMap.set('start_date', dateValue.startDate);
      paramMap.set('end_date', dateValue.endDate);
    }
    if (paramMap.size > 0) {
      const searchParamObj: any = {};

      for (const [key, value] of paramMap) {
        searchParamObj[key] = value;
      }

      setSearchParams(searchParamObj);
    } else {
      setSearchParams({});
    }
  };

  const downloadXls = async () => {
    const params: any = {};
    if (search) {
      params.search = search;
    }
    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key !== 'date_filter' && key !== 'start_date' && key !== 'end_date' && key !== 'date_type') {
          const itemList = value.split(',');
          const updatedValues = [];
          for (const val of itemList) {
            const valueList = val.split('|');
            updatedValues.push(valueList[0]);
          }
          value = updatedValues.join(',');
        }
        params[key] = value;
      });
    }
    const res = await axios
      .get(baseUrl + `/api/admin/order/exportXls`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        openInNewTab(data.data);
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        console.log(error);
      });
  };

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const onToggle = (isOpen: boolean) => {
    setShowFilters(!isOpen);
    // If isOpen=true then it means close action is triggered
    if (isOpen) {
      setSelectedSubFilter([]);
      setSubFilterData([]);
      setSelectedFilter(undefined);
    }
  };

  const fetchTotalOrdersWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const dateType = searchParams.get('date_type');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setTotalOrdersWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.date_type = dateType;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/order/widget/total-orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setTotalOrdersWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchOrdersDeliveredWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const dateType = searchParams.get('date_type');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setOrdersDeliveredWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.date_type = dateType;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/order/widget/order-delivered`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setOrdersDeliveredWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchOrdersPendingWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const dateType = searchParams.get('date_type');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setOrdersPendingWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.date_type = dateType;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/order/widget/order-pending`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setOrdersPendingWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchOrdersCompanyWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const dateType = searchParams.get('date_type');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setOrdersCompanyWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.date_type = dateType;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/order/widget/company`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setOrdersCompanyWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchOrdersGiftWidgets = async (source: any) => {
    const params: any = {};
    const dateFilter = searchParams.get('date_filter');
    const dateType = searchParams.get('date_type');
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');
    if (!dateFilter || !startDate || !endDate) {
      setOrdersGiftWidget(undefined);
      return;
    }
    params.date_filter = dateFilter;
    params.date_type = dateType;
    params.start_date = startDate;
    params.end_date = endDate;

    const res = await axios
      .get(baseUrl + `/api/admin/order/widget/gift`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data && data.data) {
          setOrdersGiftWidget(data.data);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const fetchAllOrders = async (source: any, search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;

    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key !== 'date_filter' && key !== 'start_date' && key !== 'end_date' && key !== 'date_type') {
          const itemList = value.split(',');
          const updatedValues = [];
          for (const val of itemList) {
            const valueList = val.split('|');
            updatedValues.push(valueList[0]);
          }
          value = updatedValues.join(',');
        }
        params[key] = value;
      });
    }

    if (allOrdersOrderBy !== '' && allOrdersOrderByType !== '') {
      params.orderBy = allOrdersOrderBy;
      params.orderByType = allOrdersOrderByType;
    }

    const res = await axios
      .get(baseUrl + '/api/admin/order/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setOrderCount(data.data.pagination.total_records);
          setOrders(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        if (axios.isCancel(error)) return;
      });
  };

  const fetchMyOrders = async (source: any, search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = myOrdersCurrentPage;
    params.search = search;
    params.sales_rep = userData.email;

    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key !== 'date_filter' && key !== 'start_date' && key !== 'end_date' && key !== 'date_type') {
          const itemList = value.split(',');
          const updatedValues = [];
          for (const val of itemList) {
            const valueList = val.split('|');
            updatedValues.push(valueList[0]);
          }
          value = updatedValues.join(',');
        }
        params[key] = value;
      });
    }

    if (myOrdersOrderBy !== '' && myOrdersOrderByType !== '') {
      params.orderBy = myOrdersOrderBy;
      params.orderByType = myOrdersOrderByType;
    }

    const res = await axios
      .get(baseUrl + '/api/orders/getMyOrder', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setMyOrderCount(data.data.pagination.total_records);
          setMyOrders(data.data.data);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setMyOrdersLastPage(totalPageCount);
          if (resetPage) {
            setMyOrdersCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        if (axios.isCancel(error)) return;
      });
  };

  const orderInfo = async (orderId: string | undefined) => {
    const res = await axios
      .get(baseUrl + '/api/orders/' + orderId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setOrderDetail(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOrderEvents = async (orderId: string) => {
    const res = await axios
      .get(baseUrl + '/api/orders/getOrderEvents/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          orderID: orderId,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setOrderEvents(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  function formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  const dateDefault = {
    startDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    endDate: formatDate(new Date()),
  };

  useEffect(() => {
    const paramMap = new Map<string, string>();
    paramMap.set('date_filter', 'custom');
    return setDateValue(dateDefault);
  }, []);

  const handleValueChange = (newValue: any) => {
    setDateValue(newValue);
  };

  const handleDateTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDateType(event.target.value);
  };

  function sendEmail(orderId: string): void {
    const url = ORDER_ENQUIRY_FORM_URL + `&orderid=${orderId}`;
    window.open(url, "_blank");
  }

  function sendGift(orderId: string) {
    navigate({
      pathname: '/send-gift/add-recipients',
      search: `?orderId=${orderId}`,
    });
  }

  function openOrderDetail(data: any): void {
    orderInfo(data.id);
    fetchOrderEvents(data.id);
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    if (userData && userData.email == data.sales_rep_email) {
      setOrderPlacedBy('YOU');
    } else if (userData) {
      setOrderPlacedBy(data.sales_rep_name);
    }

    openModal();
  }

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='relative max-h-[640px]'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => closeModal()}
            >
              &times;
            </button>
          </div>
          <div className='flex flex-row justify-center'>
            <p className='text-center text-xl font-bold'>ORDER ID {orderDetail.order_number}</p>
          </div>
          <div className='mx-10'>
            <Tabs>
              <Tab label='History'>
                <div>
                  <Timeline
                    orderID={orderDetail.order_number}
                    orderPlacedBy={orderPlacedBy}
                    inputData={orderEvents}
                  ></Timeline>
                  <div className='border-blueGray-200 flex items-center justify-center space-x-4 rounded-b p-6'>
                    <Button type='outline' rounded={true} onClick={() => sendEmail(orderDetail.order_number)}>
                      Contact Support
                    </Button>
                    <Button onClick={() => sendGift(orderDetail.id)}>Send another gift</Button>
                  </div>
                </div>
              </Tab>
              <Tab label='Information'>
                <div>
                  <ProductCard
                    title={orderDetail.product_name}
                    imageURL={orderDetail.product_image_uri}
                  >
                    <p></p>
                  </ProductCard>
                  {
                    orderDetail.address_warning &&
                    <div className='mt-6'>
                      <h3 className='font-bold'>Address Suggestions</h3>
                      <span className='text-sm'>{orderDetail.address_warning}</span>
                    </div>
                  }
                  <div className='m-auto mt-10 grid grid-cols-2 items-stretch gap-x-2'>
                    <div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Name:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.first_name} {orderDetail.last_name}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Email:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.email}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Company:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.company}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Title:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.title}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Address</span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.company}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.address_1}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.address_2}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.city}, {orderDetail.zipcode}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.country}
                        </span>
                      </div>
                    </div>
                  </div>
                  {!orderDetail.is_digital_gift && orderDetail.printed_message && (
                    <div className='mt-6'>
                      <div className='text-sm text-gray-500'>Your printed message</div>
                      <div className='text-base font-normal text-gray-800'>
                        &quot;{orderDetail.printed_message}&quot;
                      </div>
                    </div>
                  )}
                  {orderDetail.email_message && (
                    <div className='mt-6'>
                      <div className='text-sm text-gray-500'>Your email message</div>
                      <div className='text-base font-normal text-gray-800'>
                        &quot;{orderDetail.email_message}&quot;
                      </div>
                    </div>
                  )}
                  <div className='border-blueGray-200 flex items-center justify-center space-x-4 rounded-b p-6'>
                    <Button type='outline' rounded={true} onClick={() => sendEmail(orderDetail.order_number)}>
                      Contact Support
                    </Button>
                    <Button onClick={() => sendGift(orderDetail.id)}>Send another gift</Button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    ),
  });

  const fetchPreFilterData = async (source: any) => {
    const res = await axios
      .get(baseUrl + '/api/config/filter/order', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setOrderFilterData(response.data.data);
        const dataList: DropdownItem[] = [];
        for (const data of response.data.data) {
          const filterObj = {
            id: data.key,
            name: data.label,
          };
          dataList.push(filterObj);
        }
        setPreFilterData(dataList);
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const resetSubFilterData = (selectedKey: string) => {
    if (orderFilterData) {
      const dataList: MultiDropdownItem[] = [];
      for (const data of orderFilterData) {
        if (data.key === selectedKey) {
          for (const opt of data.options) {
            const dataTempObj: MultiDropdownItem = {
              value: opt.value,
              label: opt.label,
              parentKey: selectedKey,
            };
            dataList.push(dataTempObj);
          }
          break;
        }
        if (dataList.length > 0) {
          break;
        }
      }
      setSubFilterData(dataList);
    }
  };

  const handleFilter = (selectedItem: DropdownItem) => {
    resetSubFilterData(selectedItem.id);
    setSelectedFilter(selectedItem);
    setHideDropdownOptions(false);
  };

  const toggleOption = (selectedItem: MultiDropdownItem) => {
    setSelectedSubFilter((prevSelected) => {
      // if it's in, remove
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }

      const found = arrayIncludes(newArray, selectedItem);

      if (found) {
        return newArray.filter(
          (item) =>
            item.value != selectedItem.value ||
            (item.value == selectedItem.value && item.parentKey != selectedItem.parentKey),
        );
        // else, add
      } else {
        newArray.push(selectedItem);
        return newArray;
      }
    });
  };

  const clearSubFilter = (parentKey: string) => {
    setSelectedSubFilter((prevSelected) => {
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }
      return newArray.filter((item) => item.parentKey != parentKey);
    });
  };

  const arrayIncludes = (list: MultiDropdownItem[], item: MultiDropdownItem) => {
    let found = false;
    for (const arrayItem of list) {
      if (arrayItem.value == item.value && arrayItem.parentKey == item.parentKey) {
        found = true;
        break;
      }
    }

    return found;
  };

  const clearAll = () => {
    setSelectedSubFilter([]);
    setSubFilterData([]);
    setSelectedFilter(undefined);
  };

  const applySortingToAllOrders = (columnName: string) => {
    if (allOrdersOrderBy === columnName && allOrdersOrderByType === 'asc') {
      setAllOrdersOrderBy(columnName);
      setAllOrdersOrderByType('desc');
      setCurrentPage(1);
    } else {
      setAllOrdersOrderBy(columnName);
      setAllOrdersOrderByType('asc');
      setCurrentPage(1);
    }
  };

  const applySortingToMyOrders = (columnName: string) => {
    if (myOrdersOrderBy === columnName && myOrdersOrderByType === 'asc') {
      setMyOrdersOrderBy(columnName);
      setMyOrdersOrderByType('desc');
      setMyOrdersCurrentPage(1);
    } else {
      setMyOrdersOrderBy(columnName);
      setMyOrdersOrderByType('asc');
      setMyOrdersCurrentPage(1);
    }
  };

  const bulkUploadOrders = () => {
    const form = new FormData();

    form.append('file', bulkUploadFile);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    };

    setBulkUploadInProgress(true)

    axios
      .post(baseUrl + '/api/admin/order/bulkUpload', form, config)
      .then((response) => {
        setBulkUploadInProgress(false)
        setBulkUploadResponse(response.data.data);
        const source = axios.CancelToken.source();
        fetchAllOrders(source, search, true);
        fetchMyOrders(source, search, true);
      })
      .catch((error) => {
        console.log(error);
        onBulkUploadModalClose()
      });
  }

  const handleFileChange = (event: any) => {
    setBulkUploadFile(event.target.files[0])
    setBulkUploadError('')
  }

  const resetBulkUploadModal = () => {
    setBulkUploadFile(null);
    setBulkUploadError('');
    setBulkUploadInProgress(false);
    setBulkUploadResponse({});
  }

  const onBulkUploadModalOpen = () => {
    resetBulkUploadModal();
    openBulkUploadModal();
  }

  const onBulkUploadModalClose = () => {
    resetBulkUploadModal();
    closeBulkUploadModal();
  }

  const onBulkUploadOrders = () => {
    console.log(bulkUploadFile)
    if (bulkUploadFile) {
      if (bulkUploadFile.type === 'text/csv') {
        bulkUploadOrders()
      } else {
        setBulkUploadError('Only CSV files are allowed')
      }
    } else {
      setBulkUploadError('Please select a file')
    }
  }

  const { modal: bulkUploadModal, openModal: openBulkUploadModal, closeModal: closeBulkUploadModal } = useModal({
    children: (
      <>
        <div>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onBulkUploadModalClose()}
              disabled={bulkUploadInProgress}
            >
              &times;
            </button>
          </div>
          <p className='text-center text-xl font-bold'>
            Bulk upload orders
          </p>
          {bulkUploadResponse.status ? <>
            <div className='container'>
              <p className="mt-5 text-l">The file was successfully uploaded.</p>
              <p>{bulkUploadResponse.processed || 0} order(s) were placed.</p>
              {(Object.keys(bulkUploadResponse.errors).length > 0) &&
                <>
                  <p className="mt-5 text-red-500">There was an error with the following rows:</p>
                  <ul className="mb-5">
                    {Object.keys(bulkUploadResponse.errors).map((key: string) => (
                      <li key={key} className='list-inside list-disc text-red-500'>{`Row ${Number(key) + 1} - ${bulkUploadResponse.errors[key]}`}</li>
                    ))}
                  </ul>
                </>
              }
              <div className='py-6 text-center'>
                <Button type='outline' rounded={true} onClick={() => onBulkUploadModalClose()} disabled={bulkUploadInProgress}>
                  Close
                </Button>
              </div>
            </div>
          </> : <>
          <form className='w-full mt-2 px-8 pb-8 pt-6'>
            <label className="mb-2 block text-sm font-medium dark:text-white">Please select the orders file to upload</label>
            <div className="flex items-center justify-between">
              <input
                id="file"
                type="file"
                accept=".csv,text/csv"
                name="file"
                className="block w-full rounded-lg py-3 text-sm drop-shadow-sm disabled:opacity-50 border-gray-200"
                onChange={handleFileChange}
                disabled={bulkUploadInProgress}
              />
              <span className="mb-2 block text-sm text-gray-500 dark:text-neutral-500">Required</span>
            </div>
            {bulkUploadError && (
              <p className="mt-2 text-xs text-red-500">{bulkUploadError}</p>
            )}
            <p className="mt-5 text-sm">
              Download a sample CSV from <Link to={`${process.env.PUBLIC_URL}/samples/orders.csv`} className='text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950' target='blank'>here</Link>
            </p>
          </form>
          <div className='justify-between flex'>
            <div className='flex-1 px-5 py-6'>
            </div>
            <div className='px-5 py-6 flex items-center'>
              <Button type='outline' rounded={true} onClick={() => onBulkUploadModalClose()} disabled={bulkUploadInProgress}>
                Cancel
              </Button>
              <Button className='ml-3' onClick={() => onBulkUploadOrders()} disabled={bulkUploadInProgress}>
                {bulkUploadInProgress ? <>
                  <Loader/> Uploading
                </>: <>Upload</>
                }
              </Button>
            </div>
          </div>
          </>
          }
        </div>
      </>
    )
  })

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        <div className='flex justify-between'>
          <Tabs>
            <Tab label='All orders' count={orderCount}>
              <div className='flex items-center justify-end space-x-4'>
                <div className='w-48 items-center'>
                  <select id="dateType" value={dateType} onChange={handleDateTypeChange} className='relative transition-all duration-300 py-2.5 pl-4 pr-8 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed drop-shadow-lg font-semibold tracking-tighter leading-4 text-gray-800'>
                    <option value="created_at">Created Date</option>
                    <option value="updated_at">Updated Date</option>
                  </select>
                </div>
                <div className='w-64 items-center'>
                  <Datepicker
                    inputClassName={
                      'relative transition-all duration-300 py-2.5 pl-4 pr-8 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed ${border} ${ring} font-semibold tracking-tighter leading-4 text-gray-800 drop-shadow-lg'
                    }
                    showShortcuts={true}
                    onChange={handleValueChange}
                    value={dateValue}
                  />
                </div>
                <div className='flex gap-2 items-center'>
                  <Button
                    type='outlineGreen'
                    className='text-green-600 outline-teal-500'
                    rounded={true}
                    onClick={() => downloadXls()}
                  >
                    Export to XLSX
                  </Button>
                  {(userData?.role === 'ADMIN') && (
                    <Button
                      type='outlineGreen'
                      className='text-green-600 outline-teal-500'
                      rounded={true}
                      onClick={() => onBulkUploadModalOpen()}
                    >
                      Bulk upload orders
                    </Button>
                  )}
                </div>
              </div>
              <div className='flex w-full justify-between pt-2.5'>
                <div className='flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Total orders
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {totalOrdersWidget && totalOrdersWidget?.curCount}
                        </span>

                        {totalOrdersWidget && totalOrdersWidget?.change_percent > '0' ? (
                          <>
                            <span className=''>
                              <CaretUpGreenIcon></CaretUpGreenIcon>
                            </span>
                            <span className='text-xs text-teal-500'>
                              {totalOrdersWidget && totalOrdersWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <span className=''>
                              <CaretDownRedIcon></CaretDownRedIcon>
                            </span>
                            <span className='text-xs text-red-500'>
                              {totalOrdersWidget && totalOrdersWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={totalOrdersWidget && totalOrdersWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Delivered
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {ordersDeliveredWidget && ordersDeliveredWidget?.curCount}
                        </span>

                        {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent > '0' ? (
                          <>
                            <span className=''>
                              <CaretUpGreenIcon></CaretUpGreenIcon>
                            </span>
                            <span className='text-xs text-teal-500'>
                              {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <span className=''>
                              <CaretDownRedIcon></CaretDownRedIcon>
                            </span>
                            <span className='text-xs text-red-500'>
                              {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={ordersDeliveredWidget && ordersDeliveredWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Pending
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {ordersPendingWidget && ordersPendingWidget?.curCount}
                        </span>

                        {ordersPendingWidget && ordersPendingWidget?.change_percent > '0' ? (
                          <>
                            <CaretUpGreenIcon></CaretUpGreenIcon>
                            <span className='text-xs text-teal-500'>
                              {ordersPendingWidget && ordersPendingWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <CaretDownRedIcon></CaretDownRedIcon>
                            <span className='text-xs text-red-500'>
                              {ordersPendingWidget && ordersPendingWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={ordersPendingWidget && ordersPendingWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white px-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Most popular recipient company
                    </div>
                    <div className='col-span-12 pb-3'>
                      <div className='flex items-center'>
                        <div className='pl-2 text-lg'>
                          {ordersCompanyWidget && ordersCompanyWidget?.count}
                        </div>
                        <div className='ml-2 text-xs'>
                          {ordersCompanyWidget && ordersCompanyWidget?.change_percent > '0' ? (
                            <>
                              <div className='flex text-teal-500'>
                                <CaretUpGreenIcon></CaretUpGreenIcon>
                                {ordersCompanyWidget && ordersCompanyWidget?.change_percent}%
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='flex text-red-500'>
                                <CaretDownRedIcon></CaretDownRedIcon>
                                {ordersCompanyWidget && ordersCompanyWidget?.change_percent}%
                              </div>
                            </>
                          )}
                        </div>
                        <div className='col-span-2 px-2 text-xs font-medium leading-4 text-gray-800'>
                          gifts to {ordersCompanyWidget && ordersCompanyWidget?.company}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/4 flex-col justify-end rounded-xl bg-white px-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-3 content-center items-center'>
                    <div className='col-span-12 pt-3 text-xs font-medium leading-4 text-gray-800'>
                      Most popular gift
                    </div>
                    <div className='col-span-5 row-span-2 justify-self-center'>
                      {/* <img
                        src={ordersGiftWidget && ordersGiftWidget.product_thumbnail_uri}
                        className='h-10'
                      ></img> */}
                      <div className='rounded-xl border bg-gray-200'>
                        <Avatar
                          square
                          src={ordersGiftWidget && ordersGiftWidget.product_thumbnail_uri}
                          className='size-10'
                        />
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-start pt-1'>
                      <div className='text-xs font-normal leading-4 text-black'>
                        {ordersGiftWidget && ordersGiftWidget.product_name}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-start'>
                      <div className='pb-3 text-xs font-medium leading-4 text-black'>
                        {ordersGiftWidget && ordersGiftWidget?.count} Gifted
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between py-3'>
                <div className='relative mr-5 flex h-12 w-full items-center overflow-hidden rounded-lg border border-gray-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
                  <SearchInput
                    onChange={searchData}
                    search={search}
                    placeholder='Search by order id, recipient, gift or company'
                  ></SearchInput>
                </div>
                <div className='w-fit'>
                  <Button
                    type='solidWithBorder'
                    className='focus:none border border-gray-200 bg-white text-gray-800 drop-shadow-lg hover:bg-gray-200'
                    rounded={true}
                    onClick={() => onToggle(showFilters)}
                  >
                    <ListIconBlack></ListIconBlack> <span className='text-gray-800'>Filter</span>
                  </Button>
                </div>
              </div>
              {showFilters && (
                <div className='z-100 flex flex-row flex-wrap items-center pb-2'>
                  {preFilterData && (
                    <div className='mr-2 w-36 items-center'>
                      <DropdownComponent
                        id='filterdropdown'
                        title='Add Filter'
                        data={preFilterData}
                        onSelect={handleFilter}
                        listHeader='CATEGORIES'
                        selectedId={selectedFilter?.id}
                      />
                    </div>
                  )}
                  {subFilterData && subFilterData.length > 0 && (
                    <div className='mr-2 w-52 items-center'>
                      <MultiDropdownWithSearch
                        id='subfilterdropdown'
                        title={selectedFilter?.name}
                        data={subFilterData}
                        parentKey={selectedFilter ? selectedFilter?.id : ''}
                        onSelect={toggleOption}
                        clearSubFilter={clearSubFilter}
                        selected={selectedSubFilter}
                        searchPlaceholder={filterTypePlaceholderMap.get(
                          selectedFilter ? selectedFilter.id : '',
                        )}
                        hideDropdownOptions={hideDropdownOptions}
                      />
                    </div>
                  )}
                  {selectedSubFilter && !!selectedSubFilter.length && (
                    <>
                      {selectedSubFilter.map((filter) => (
                        <div key={filter.value} className='flex items-center gap-2 py-2'>
                          <DismissBadge
                            badgeText={filter.label}
                            onDismiss={() => toggleOption(filter)}
                          />
                        </div>
                      ))}
                      <button
                        className='h-8 w-28 rounded-full bg-white px-2 py-1 text-sm font-medium leading-4 tracking-wide text-gray-800'
                        onClick={() => clearAll()}
                      >
                        Clear all
                      </button>
                    </>
                  )}
                </div>
              )}
              <div>
                <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
                  <TableHead className='overflow-hidden bg-gray-50'>
                    <TableRow>
                      <TableHeader className='text-gray-500'>ORDER ID</TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToAllOrders(ORDER_SORT_COL_MAP.sales_rep)}
                        >
                          <span className='mr-1'>SALES REPRESENTATIVE</span>
                          {allOrdersOrderBy === ORDER_SORT_COL_MAP.sales_rep ? (
                            allOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToAllOrders(ORDER_SORT_COL_MAP.recepient)}
                        >
                          <span className='mr-1'>RECIPIENT</span>
                          {allOrdersOrderBy === ORDER_SORT_COL_MAP.recepient ? (
                            allOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>GIFT</TableHeader>
                      <TableHeader className='text-gray-500'>QTY</TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToAllOrders(ORDER_SORT_COL_MAP.status)}
                        >
                          <span className='mr-1'>STATUS</span>
                          {allOrdersOrderBy === ORDER_SORT_COL_MAP.status ? (
                            allOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToAllOrders(ORDER_SORT_COL_MAP.created_at)}
                        >
                          <span className='mr-1'>CREATED AT</span>
                          {allOrdersOrderBy === ORDER_SORT_COL_MAP.created_at ? (
                            allOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToAllOrders(ORDER_SORT_COL_MAP.updated_at)}
                        >
                          <span className='mr-1'>UPDATED AT</span>
                          {allOrdersOrderBy === ORDER_SORT_COL_MAP.updated_at ? (
                            allOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody className='overflow-hidden bg-white'>
                    {orders.map((data: any) => (
                      <TableRow key={data.handle}>
                        <TableCell className='px-2 text-gray-800'>{data.order_number}</TableCell>
                        <TableCell className='text-zinc-500'>
                          <div className='flex items-center gap-4'>
                            <div>
                              <div className='font-medium text-gray-800'>
                                {data.sales_rep_name}{' '}
                                <Badge
                                  color={
                                    data.sales_rep_account_type == 'Direct'
                                      ? 'red'
                                      : data.sales_rep_account_type == 'SDR'
                                        ? 'emerald'
                                        : data.sales_rep_account_type == 'Channel'
                                          ? 'yellow'
                                          : data.sales_rep_account_type == 'Unassigned'
                                            ? 'zinc'
                                            : 'blue'
                                  }
                                >
                                  {data.sales_rep_account_type}
                                </Badge>
                              </div>
                              <div className='text-gray-500'>
                                <a href='#' className='hover:text-gray-700'>
                                  {data.sales_rep_email}
                                </a>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className='text-zinc-500'>
                          <div className='flex items-center gap-4'>
                            <div>
                              <div className='font-medium text-gray-800'>{data.recepient_name}</div>
                              <div className='text-gray-500'>
                                <a href='#' className='hover:text-gray-700'>
                                  {data.recepient_company}
                                </a>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className='text-black'>
                          <div className='flex items-center gap-4'>
                            <div className='rounded-xl border bg-gray-200 '>
                              <Avatar square src={data.product_image_uri} className='size-12' />
                            </div>
                            <div>
                              <div className='font-medium'>{data.product_name}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className='text-black'>
                          {data.quantity}
                        </TableCell>
                        <TableCell className='text-zinc-500'>
                          <Badge
                            color={
                              data.order_status == 'Processing'
                                ? 'blue'
                                : data.order_status == 'Shipped'
                                  ? 'yellowTxtWht'
                                  : data.order_status == 'Delivered'
                                    ? 'greenTxtWht'
                                    : 'red'
                            }
                          >
                            {data.order_status}
                          </Badge>
                        </TableCell>
                        <TableCell className='text-black'>
                          <Tooltip message={format(data.created_at, 'MM/dd/yyyy HH:MM:SS')}>
                            {data.created_at ? format(data.created_at, 'MM/dd/yyyy') : ''}
                          </Tooltip>
                        </TableCell>
                        <TableCell className='text-black'>
                          <Tooltip message={format(data.updated_at, 'MM/dd/yyyy HH:MM:SS')}>
                            {data.updated_at ? format(data.updated_at, 'MM/dd/yyyy') : ''}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <span className='inline-flex items-baseline'>
                            <span onClick={() => sendGift(data.id)} className='cursor-pointer px-2'>
                              <Tooltip message='Send a new gift'>
                                <ReOrderIcon />
                              </Tooltip>
                            </span>
                            <span
                              onClick={() => openOrderDetail(data)}
                              className='cursor-pointer px-2'
                            >
                              <Tooltip message='View details'>
                                <EyeIcon />
                              </Tooltip>
                            </span>
                            <span onClick={() => sendEmail(data.order_number)} className='cursor-pointer px-2'>
                              <Tooltip message='Contact support'>
                                <SupportIcon />
                              </Tooltip>
                            </span>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {orders && orders.length == 0 && (
                  <div className='pt-5 text-center text-gray-500'>
                    {'No data found. Please change your search filters'}
                  </div>
                )}
                {orders && orders.length > 0 && (
                  <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
                    <Pagination
                      currentPage={currentPage}
                      lastPage={lastPage}
                      maxLength={5}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                )}
              </div>
            </Tab>
            <Tab label='My orders' count={myOrderCount}>
              <div className='flex items-center justify-end space-x-4'>
                <div className='w-64 items-center'>
                  <Datepicker
                    inputClassName={
                      'relative transition-all duration-300 py-2.5 pl-4 pr-8 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed ${border} ${ring} font-semibold tracking-tighter leading-4 text-gray-800 drop-shadow-lg'
                    }
                    showShortcuts={true}
                    onChange={handleValueChange}
                    value={dateValue}
                  />
                </div>
                <div className='flex gap-2 items-center'>
                  <Button
                    type='outlineGreen'
                    className='text-green-600 outline-teal-500'
                    rounded={true}
                    onClick={() => downloadXls()}
                  >
                    Export to XLSX
                  </Button>
                  {(userData?.role === 'ADMIN') && (
                    <Button
                      type='outlineGreen'
                      className='text-green-600 outline-teal-500'
                      rounded={true}
                      onClick={() => onBulkUploadModalOpen()}
                    >
                      Bulk upload orders
                    </Button>
                  )}
                </div>
              </div>
              <div className='flex w-full pt-2.5'>
                <div className='flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Total orders
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {totalOrdersWidget && totalOrdersWidget?.curCount}
                        </span>

                        {totalOrdersWidget && totalOrdersWidget?.change_percent > '0' ? (
                          <>
                            <span className=''>
                              <CaretUpGreenIcon></CaretUpGreenIcon>
                            </span>
                            <span className='text-xs text-teal-500'>
                              {totalOrdersWidget && totalOrdersWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <span className=''>
                              <CaretDownRedIcon></CaretDownRedIcon>
                            </span>
                            <span className='text-xs text-red-500'>
                              {totalOrdersWidget && totalOrdersWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={totalOrdersWidget && totalOrdersWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Delivered
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {ordersDeliveredWidget && ordersDeliveredWidget?.curCount}
                        </span>

                        {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent > '0' ? (
                          <>
                            <span className=''>
                              <CaretUpGreenIcon></CaretUpGreenIcon>
                            </span>
                            <span className='text-xs text-teal-500'>
                              {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <span className=''>
                              <CaretDownRedIcon></CaretDownRedIcon>
                            </span>
                            <span className='text-xs text-red-500'>
                              {ordersDeliveredWidget && ordersDeliveredWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={ordersDeliveredWidget && ordersDeliveredWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white p-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Pending
                    </div>
                    <div className='col-span-5 justify-self-center'>
                      <div className='flex items-center'>
                        <span className='pr-2 text-lg'>
                          {ordersPendingWidget && ordersPendingWidget?.curCount}
                        </span>

                        {ordersPendingWidget && ordersPendingWidget?.change_percent > '0' ? (
                          <>
                            <CaretUpGreenIcon></CaretUpGreenIcon>
                            <span className='text-xs text-teal-500'>
                              {ordersPendingWidget && ordersPendingWidget?.change_percent}%
                            </span>
                          </>
                        ) : (
                          <>
                            <CaretDownRedIcon></CaretDownRedIcon>
                            <span className='text-xs text-red-500'>
                              {ordersPendingWidget && ordersPendingWidget?.change_percent}%
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-center'>
                      <div>
                        <LineChart
                          width={180}
                          height={40}
                          data={ordersPendingWidget && ordersPendingWidget.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <Line
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/5 flex-col justify-end rounded-xl bg-white px-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-2 content-center items-center'>
                    <div className='col-span-12 text-xs font-medium leading-4 text-gray-800'>
                      Most popular recipient company
                    </div>
                    <div className='col-span-12 pb-3'>
                      <div className='flex items-center'>
                        <div className='pl-2 text-lg'>
                          {ordersCompanyWidget && ordersCompanyWidget?.count}
                        </div>
                        <div className='ml-2 text-xs'>
                          {ordersCompanyWidget && ordersCompanyWidget?.change_percent > '0' ? (
                            <>
                              <div className='flex text-teal-500'>
                                <CaretUpGreenIcon></CaretUpGreenIcon>
                                {ordersCompanyWidget && ordersCompanyWidget?.change_percent}%
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='flex text-red-500'>
                                <CaretDownRedIcon></CaretDownRedIcon>
                                {ordersCompanyWidget && ordersCompanyWidget?.change_percent}%
                              </div>
                            </>
                          )}
                        </div>
                        <div className='col-span-2 px-2 text-xs font-medium leading-4 text-gray-800'>
                          gifts to {ordersCompanyWidget && ordersCompanyWidget?.company}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex h-24 w-1/4 flex-col justify-end rounded-xl bg-white px-3 drop-shadow-lg'>
                  <div className='grid h-24 grid-rows-3 content-center items-center'>
                    <div className='col-span-12 pt-3 text-xs font-medium leading-4 text-gray-800'>
                      Most popular gift
                    </div>
                    <div className='col-span-5 row-span-2 justify-self-center'>
                      {/* <img
                        src={ordersGiftWidget && ordersGiftWidget.product_thumbnail_uri}
                        className='h-10'
                      ></img> */}
                      <div className='rounded-xl border bg-gray-200'>
                        <Avatar
                          square
                          src={ordersGiftWidget && ordersGiftWidget.product_thumbnail_uri}
                          className='size-10'
                        />
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-start pt-1'>
                      <div className='text-xs font-normal leading-4 text-black'>
                        {ordersGiftWidget && ordersGiftWidget.product_name}
                      </div>
                    </div>
                    <div className='col-span-7 justify-self-start'>
                      <div className='pb-3 text-xs font-medium leading-4 text-black'>
                        {ordersGiftWidget && ordersGiftWidget?.count} Gifted
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between py-3'>
                <div className='relative mr-5 flex h-12 w-full items-center overflow-hidden rounded-lg border border-slate-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
                  <SearchInput
                    onChange={searchData}
                    search={search}
                    placeholder='Search by order id, recipient, gift or company'
                  ></SearchInput>
                </div>
                <div className='w-fit'>
                  <Button
                    type='solidWithBorder'
                    className='focus:none border border-slate-200 bg-white text-gray-800 drop-shadow-lg hover:bg-gray-200'
                    rounded={true}
                    onClick={() => onToggle(showFilters)}
                  >
                    <ListIconBlack></ListIconBlack> <span className='text-gray-800'>Filter</span>
                  </Button>
                </div>
              </div>
              {showFilters && (
                <div className='z-100 flex flex-row flex-wrap items-center pb-2'>
                  {preFilterData && (
                    <div className='mr-2 w-36 items-center'>
                      <DropdownComponent
                        id='filterdropdown'
                        title='Add Filter'
                        data={preFilterData}
                        onSelect={handleFilter}
                        listHeader='CATEGORIES'
                      />
                    </div>
                  )}
                  {subFilterData && subFilterData.length > 0 && (
                    <div className='mr-2 w-52 items-center'>
                      <MultiDropdownWithSearch
                        id='subfilterdropdown'
                        title={selectedFilter?.name}
                        data={subFilterData}
                        parentKey={selectedFilter ? selectedFilter?.id : ''}
                        onSelect={toggleOption}
                        clearSubFilter={clearSubFilter}
                        selected={selectedSubFilter}
                        searchPlaceholder={filterTypePlaceholderMap.get(
                          selectedFilter ? selectedFilter.id : '',
                        )}
                      />
                    </div>
                  )}
                  {selectedSubFilter && !!selectedSubFilter.length && (
                    <>
                      {selectedSubFilter.map((filter) => (
                        <div key={filter.value} className='flex items-center gap-2 py-2'>
                          <DismissBadge
                            badgeText={filter.label}
                            onDismiss={() => toggleOption(filter)}
                          />
                        </div>
                      ))}
                      <button
                        className='h-8 w-28 rounded-full bg-white px-2 py-1 text-sm font-medium leading-4 tracking-wide text-gray-800'
                        onClick={() => setSelectedSubFilter([])}
                      >
                        Clear all
                      </button>
                    </>
                  )}
                </div>
              )}
              <div>
                <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
                  <TableHead className='overflow-hidden bg-gray-50'>
                    <TableRow>
                      <TableHeader className='px-2 text-gray-500'>ORDER ID</TableHeader>
                      <TableHeader className='text-gray-500'>GIFT</TableHeader>
                      <TableHeader className='text-gray-500'>QTY</TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToMyOrders(ORDER_SORT_COL_MAP.recepient)}
                        >
                          <span className='mr-1'>NAME</span>
                          {myOrdersOrderBy === ORDER_SORT_COL_MAP.recepient ? (
                            myOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToMyOrders(ORDER_SORT_COL_MAP.company)}
                        >
                          <span className='mr-1'>COMPANY</span>
                          {myOrdersOrderBy === ORDER_SORT_COL_MAP.company ? (
                            myOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToMyOrders(ORDER_SORT_COL_MAP.status)}
                        >
                          <span className='mr-1'>STATUS</span>
                          {myOrdersOrderBy === ORDER_SORT_COL_MAP.status ? (
                            myOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToMyOrders(ORDER_SORT_COL_MAP.created_at)}
                        >
                          <span className='mr-1'>CREATED AT</span>
                          {myOrdersOrderBy === ORDER_SORT_COL_MAP.created_at ? (
                            myOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>
                        <button
                          className='flex items-center'
                          onClick={() => applySortingToMyOrders(ORDER_SORT_COL_MAP.updated_at)}
                        >
                          <span className='mr-1'>UPDATED AT</span>
                          {myOrdersOrderBy === ORDER_SORT_COL_MAP.updated_at ? (
                            myOrdersOrderByType === 'asc' ? (
                              <CaretUp />
                            ) : (
                              <CaretDown />
                            )
                          ) : (
                            <CaretUpDown />
                          )}
                        </button>
                      </TableHeader>
                      <TableHeader className='text-gray-500'>TRACKING NUMBER</TableHeader>
                      <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody className='overflow-hidden bg-white'>
                    {myOrders.map((data: any) => (
                      <TableRow key={data.handle}>
                        <TableCell className='text-gray-800'>{data.order_number}</TableCell>
                        <TableCell className='text-zinc-500'>
                          <div className='flex items-center gap-4'>
                            <Avatar square src={data.product_image_uri} className='size-12' />
                            <div>
                              <div className='font-medium text-black'>{data.product_name}</div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className='text-black'>{data.quantity}</TableCell>
                        <TableCell className='text-gray-800'>{data.recepient_name}</TableCell>
                        <TableCell className='text-gray-800'>{data.recepient_company}</TableCell>
                        <TableCell className='text-zinc-500'>
                          <Badge
                            color={
                              data.order_status == 'Processing'
                                ? 'blue'
                                : data.order_status == 'Shipped'
                                  ? 'yellowTxtWht'
                                  : data.order_status == 'Delivered'
                                    ? 'greenTxtWht'
                                    : 'red'
                            }
                          >
                            {data.order_status}
                          </Badge>
                        </TableCell>
                        <TableCell className='text-black'>
                          <Tooltip message={format(data.created_at, 'MM/dd/yyyy HH:MM:SS')}>
                            {data.created_at ? format(data.created_at, 'MM/dd/yyyy') : ''}
                          </Tooltip>
                        </TableCell>
                        <TableCell className='text-black'>
                          <Tooltip message={format(data.updated_at, 'MM/dd/yyyy HH:MM:SS')}>
                            {data.updated_at ? format(data.updated_at, 'MM/dd/yyyy') : ''}
                          </Tooltip>
                        </TableCell>
                        <TableCell className='text-gray-800'>{data.tracking_number}</TableCell>
                        <TableCell>
                          <span className='inline-flex items-baseline'>
                            <span onClick={() => sendGift(data.id)} className='cursor-pointer px-2'>
                              <Tooltip message='Send a new gift'>
                                <ReOrderIcon />
                              </Tooltip>
                            </span>
                            <span
                              onClick={() => openOrderDetail(data)}
                              className='cursor-pointer px-2'
                            >
                              <Tooltip message='View details'>
                                <EyeIcon />
                              </Tooltip>
                            </span>
                            <span onClick={() => sendEmail(data.order_number)} className='cursor-pointer px-2'>
                              <Tooltip message='Contact support'>
                                <SupportIcon />
                              </Tooltip>
                            </span>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {myOrders && myOrders.length == 0 && (
                  <div className='pt-5 text-center text-gray-500'>
                    {'No data found. Please change your search filters'}
                  </div>
                )}
                {myOrders && myOrders.length > 0 && (
                  <div className='mb-5 mt-8 flex h-10 items-center justify-center'>
                    <Pagination
                      currentPage={myOrdersCurrentPage}
                      lastPage={myOrdersLastPage}
                      maxLength={5}
                      setCurrentPage={setMyOrdersCurrentPage}
                    />
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </Wrapper>
      {modal}
      {bulkUploadModal}
    </>
  );
};

export default OrdersListAdmin;
