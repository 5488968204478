import { Link } from 'react-router-dom';

type NavLinkItemProps = { title: string; imageUrl: string; redirect: string };

const NavLinkItem = ({ title, imageUrl, redirect }: NavLinkItemProps) => {
  return (
    <Link to={redirect} className='flex items-center justify-center gap-2'>
      <img src={imageUrl} />
      <span>{title}</span>
    </Link>
  );
};

export default NavLinkItem;
