import { useSearchParams } from 'react-router-dom';
import { GENERAL_ENQUIRY_FORM_URL, ORDER_ENQUIRY_FORM_URL } from '../constants/variable';
import { useEffect } from 'react';

function ContactUs() {
  const [searchParams, _setSearchParams] = useSearchParams();

  const orderId = searchParams.get('orderid');

  const supportUrl = GENERAL_ENQUIRY_FORM_URL;
  const orderSupportUrl = `${ORDER_ENQUIRY_FORM_URL}&orderid=${orderId}`;

  useEffect(() => {
    window.location.href = orderId ? orderSupportUrl : supportUrl;
  }, []);

  return null
}

export default ContactUs;
