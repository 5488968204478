import React, { ChangeEvent } from 'react';

interface Option {
  value: string;
  name: string | React.ReactNode;
}

interface SelectInputProps {
  options: Option[];
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  isRequired?: boolean;
  name?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  options,
  value,
  onChange,
  label,
  isRequired = false,
  name,
  ...otherProps
}) => {
  return (
    <div className='mb-4'>
      <div className='flex items-center justify-between'>
        <label className='mb-2 block text-sm font-medium dark:text-white' htmlFor='select'>
          {label}
        </label>
        {isRequired && (
          <span className='mb-2 block text-sm text-gray-500 dark:text-neutral-500'>Required</span>
        )}
      </div>
      <div className='relative'>
        <select
          name={name ?? ''}
          value={value ?? ''}
          onChange={onChange}
          className='mt-2 w-full min-w-[15rem] rounded-lg border bg-white p-2 shadow-md dark:divide-gray-700 dark:border dark:border-gray-700 dark:bg-gray-800'
          {...otherProps}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
