import Wrapper from '../../components/Wrapper';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../catalyst/table';
import { Avatar } from '../../catalyst/avatar';
import { Badge } from '../../catalyst/badge';
import ReOrderIcon from '../../components/Icons/ReOrderIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import SupportIcon from '../../components/Icons/SupportIcon';
import { PaginationConstants, ORDER_SORT_COL_MAP, ORDER_ENQUIRY_FORM_URL } from '../../constants/variable';
import SearchInput from '../../components/SearchInput';
import Pagination from '../../components/tablePagination/Pagination';
import { format } from 'date-fns';
import { Tab, Tabs } from '../../components/TabsItem';
import { Button } from '../../components/Button';
import { useModal } from '../../hooks/useModal';
import Datepicker from 'react-tailwindcss-datepicker';
import DropdownComponent, {
  DropdownItem,
} from '../../components/DropdownComponent/DropdownComponent';
import MultiDropdownWithSearch, {
  MultiDropdownItem,
} from '../../components/MultiDropdownWithSearch/MultiDropdownWithSearch';
import DismissBadge from '../../components/DismissBadge';
import ListIconBlack from '../../components/Icons/ListIconBlack';
import GiftIcon from '../../components/Icons/GiftIcon';
import Timeline from '../../components/Timeline/index';
import Tooltip from '../../components/Tooltip';
import ProductCard from '../../components/ProductCard';
import CaretUp from '../../components/Icons/CaretUp';
import CaretDown from '../../components/Icons/CaretDown';
import CaretUpDown from '../../components/Icons/CaretUpDown';
import { useToast } from '../../components/Toast/ToastProvider';

type OrderFilterOptions = {
  value: string;
  label: string;
};

type OrderFilter = {
  key: string;
  label: string;
  type: string;
  options: OrderFilterOptions[];
};

type OrderEvent = {
  status: string;
  happened_at: string;
  tracking_number: string;
};

const OrdersListUser = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const toast = useToast();
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [myOrders, setMyOrders] = useState([]);
  const [userHasOrders, setuserHasOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myOrderCount, setMyOrderCount] = useState();
  const [search, setSearch] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);

  const [orderPlacedBy, setOrderPlacedBy] = useState<string>();
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [orderEvents, setOrderEvents] = useState<OrderEvent[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [dateValue, setDateValue] = useState<any>({});
  const [orderFilterData, setOrderFilterData] = useState<OrderFilter[]>();
  const [preFilterData, setPreFilterData] = useState<DropdownItem[]>();
  const [subFilterData, setSubFilterData] = useState<MultiDropdownItem[]>();
  const [selectedFilter, setSelectedFilter] = useState<DropdownItem | undefined>();
  const [selectedSubFilter, setSelectedSubFilter] = useState<MultiDropdownItem[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState<string>(ORDER_SORT_COL_MAP.default);
  const [orderByType, setOrderByType] = useState<string>('desc');
  const [hideDropdownOptions, setHideDropdownOptions] = useState<boolean>(false);
  const navigate = useNavigate();

  const filterTypePlaceholderMap = new Map<string, string>();
  filterTypePlaceholderMap.set('sales_rep', 'Search by name or email');
  filterTypePlaceholderMap.set('recepients', 'Search by name or email');
  filterTypePlaceholderMap.set('company', 'Search by company');
  filterTypePlaceholderMap.set('gift', 'Search by product');

  let hasOrders: any;

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchPreFilterData(source);
    prefillData();
    setHideDropdownOptions(true);
    return () => source.cancel();
  }, []);

  const prefillData = async () => {
    if (searchParams.size > 0) {
      const subFilterList: MultiDropdownItem[] = [];
      const selectedDateValue: any = {};
      const selectedSubFilterObj: any = { id: '', name: '' };
      searchParams.forEach((value, key) => {
        switch (key) {
          case 'start_date':
            selectedDateValue.startDate = decodeURI(value);
            break;
          case 'end_date':
            selectedDateValue.endDate = decodeURI(value);
            break;
          case 'date_filter':
            // do nothing
            break;
          default: {
            if (selectedSubFilterObj?.id == '') {
              selectedSubFilterObj.id = key;
            }
            const itemList = value.split(',');
            for (const val of itemList) {
              const valueList = val.split('|');
              const selectedItemObj: MultiDropdownItem = {
                parentKey: key,
                label: valueList[1],
                value: valueList[0],
              };
              subFilterList.push(selectedItemObj);
            }
          }
        }
      });
      setSelectedSubFilter(() => {
        return subFilterList;
      });
      if (selectedSubFilterObj.id != '') {
        setSelectedFilter(selectedSubFilterObj);
      }
      if (selectedDateValue.startDate != '' && selectedDateValue.endDate != '') {
        setDateValue(selectedDateValue);
      }
    }
  };

  useEffect(() => {
    if (!selectedFilter || !preFilterData) {
      return;
    }
    if (selectedFilter.id != '' && selectedFilter.name == '') {
      for (const orderFilterObj of preFilterData) {
        if (orderFilterObj.id == selectedFilter.id) {
          resetSubFilterData(orderFilterObj.id);
          setSelectedFilter(orderFilterObj);
          setShowFilters(true);
          break;
        }
      }
    }
  }, [prefillData]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchMyOrders(source, search, true);
    return () => source.cancel();
  }, [search, searchParams, orderBy, orderByType]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchMyOrders(source, search, false);
    return () => source.cancel();
  }, [currentPage]);

  useEffect(() => {
    updateSearchParams();
  }, [selectedSubFilter, dateValue]);

  const updateSearchParams = () => {
    const paramMap = new Map<string, string>();
    if (selectedSubFilter) {
      for (const item of selectedSubFilter) {
        const mapValue = paramMap.get(item.parentKey);
        if (mapValue) {
          const newValue = `${mapValue},${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        } else {
          const newValue = `${item.value}|${item.label}`;
          paramMap.set(item.parentKey, newValue);
        }
      }
    }

    if (dateValue.startDate != null && dateValue.endDate != null) {
      paramMap.set('date_filter', 'custom');
      paramMap.set('start_date', dateValue.startDate);
      paramMap.set('end_date', dateValue.endDate);
    }
    if (paramMap.size > 0) {
      const searchParamObj: any = {};

      for (const [key, value] of paramMap) {
        searchParamObj[key] = value;
      }

      setSearchParams(searchParamObj);
    } else {
      setSearchParams({});
    }
  };

  function formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  const dateDefault = {
    startDate: formatDate(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    endDate: formatDate(new Date()),
  };

  useEffect(() => {
    const paramMap = new Map<string, string>();
    paramMap.set('date_filter', 'custom');
    return setDateValue(dateDefault);
  }, []);

  const fetchMyOrders = async (source: any, search: string | undefined, resetPage: boolean) => {
    const params: any = {};
    params.page = currentPage;
    params.search = search;
    setLoading(true);
    if (searchParams.size > 0) {
      searchParams.forEach((value, key) => {
        if (key !== 'date_filter' && key !== 'start_date' && key !== 'end_date') {
          const itemList = value.split(',');
          const updatedValues = [];
          for (const val of itemList) {
            const valueList = val.split('|');
            updatedValues.push(valueList[0]);
          }
          value = updatedValues.join(',');
        }
        params[key] = value;
      });
    }

    if (orderBy !== '' && orderByType !== '') {
      params.orderBy = orderBy;
      params.orderByType = orderByType;
    }

    const res = await axios
      .get(baseUrl + `/api/orders/getMyOrder`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setMyOrders(data.data.data);
          setuserHasOrders(data.data.hasOrders);
          setMyOrderCount(data.data.pagination.total_records);
          const totalPageCount = Math.ceil(
            parseInt(data.data.pagination.total_records) / PaginationConstants.ItemsPerPage,
          );
          setlastPage(totalPageCount);
          if (resetPage) {
            setCurrentPage(1);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        }
        setLoading(false);
        if (axios.isCancel(error)) return;
      });
  };

  const orderInfo = async (orderId: string | undefined) => {
    const res = await axios
      .get(baseUrl + '/api/orders/' + orderId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setOrderDetail(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOrderEvents = async (orderId: string) => {
    const res = await axios
      .get(baseUrl + '/api/orders/getOrderEvents/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          orderID: orderId,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          setOrderEvents(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchData = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  };

  function sendEmail(orderId: string): void {
    const url = ORDER_ENQUIRY_FORM_URL + `&orderid=${orderId}`;
    window.open(url, "_blank");
  }

  function openEditModal(data: any): void {
    console.log('Function not implemented.');
  }

  function sendGift() {
    navigate('/send-gift');
  }

  function reOrder(orderId: string) {
    navigate({
      pathname: '/send-gift/add-recipients',
      search: `?orderId=${orderId}`,
    });
  }

  function openOrderDetail(data: any): void {
    orderInfo(data.id);
    fetchOrderEvents(data.id);
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    if (userData && userData.email == data.sales_rep_email) {
      setOrderPlacedBy('YOU');
    } else if (userData) {
      setOrderPlacedBy(data.sales_rep_name);
    }

    openModal();
  }

  const onToggle = (isOpen: boolean) => {
    setShowFilters(!isOpen);
    // If isOpen=true then it means close action is triggered
    if (isOpen) {
      setSelectedSubFilter([]);
      setSubFilterData([]);
      setSelectedFilter(undefined);
    }
  };

  const handleFilter = (selectedItem: DropdownItem) => {
    resetSubFilterData(selectedItem.id);
    setSelectedFilter(selectedItem);
    setHideDropdownOptions(false);
  };

  const toggleOption = (selectedItem: MultiDropdownItem) => {
    setSelectedSubFilter((prevSelected) => {
      // if it's in, remove
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }

      const found = arrayIncludes(newArray, selectedItem);

      if (found) {
        return newArray.filter(
          (item) =>
            item.value != selectedItem.value ||
            (item.value == selectedItem.value && item.parentKey != selectedItem.parentKey),
        );
        // else, add
      } else {
        newArray.push(selectedItem);
        return newArray;
      }
    });
  };

  const clearSubFilter = (parentKey: string) => {
    setSelectedSubFilter((prevSelected) => {
      let newArray: MultiDropdownItem[] = [];
      if (prevSelected) {
        newArray = [...prevSelected];
      }
      return newArray.filter((item) => item.parentKey != parentKey);
    });
  };

  const arrayIncludes = (list: MultiDropdownItem[], item: MultiDropdownItem) => {
    let found = false;
    for (const arrayItem of list) {
      if (arrayItem.value == item.value && arrayItem.parentKey == item.parentKey) {
        found = true;
        break;
      }
    }

    return found;
  };

  const clearAll = () => {
    setSelectedSubFilter([]);
    setSubFilterData([]);
    setSelectedFilter(undefined);
  };

  const resetSubFilterData = (selectedKey: string) => {
    if (orderFilterData) {
      const dataList: MultiDropdownItem[] = [];
      for (const data of orderFilterData) {
        if (data.key === selectedKey) {
          for (const opt of data.options) {
            const dataTempObj: MultiDropdownItem = {
              value: opt.value,
              label: opt.label,
              parentKey: selectedKey,
            };
            dataList.push(dataTempObj);
          }
          break;
        }
        if (dataList.length > 0) {
          break;
        }
      }
      setSubFilterData(dataList);
    }
  };

  const fetchPreFilterData = async (source: any) => {
    const res = await axios
      .get(baseUrl + '/api/config/filter/myOrder', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setOrderFilterData(response.data.data);
        const dataList: DropdownItem[] = [];
        for (const data of response.data.data) {
          const filterObj = {
            id: data.key,
            name: data.label,
          };
          dataList.push(filterObj);
        }
        setPreFilterData(dataList);
        console.log(preFilterData);
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  };

  const handleValueChange = (newValue: any) => {
    setDateValue(newValue);
  };

  const applySorting = (columnName: string) => {
    if (orderBy === columnName && orderByType === 'asc') {
      setOrderBy(columnName);
      setOrderByType('desc');
      setCurrentPage(1);
    } else {
      setOrderBy(columnName);
      setOrderByType('asc');
      setCurrentPage(1);
    }
  };

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='relative max-h-[640px]'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => closeModal()}
            >
              &times;
            </button>
          </div>
          <div className='flex flex-row justify-center'>
            <p className='text-center text-xl font-bold'>ORDER ID {orderDetail.order_number}</p>
          </div>
          <div className='mx-10'>
            <Tabs>
              <Tab label='History'>
                <div>
                  <Timeline
                    orderID={orderDetail.order_number}
                    orderPlacedBy={orderPlacedBy}
                    inputData={orderEvents}
                  ></Timeline>
                  {/* <VerticalTimelineComponent></VerticalTimelineComponent> */}
                  <div className='border-blueGray-200 flex items-center justify-center space-x-4 rounded-b p-6'>
                    <Button type='outline' rounded={true} onClick={() => sendEmail(orderDetail.order_number)}>
                      Contact Support
                    </Button>
                    <Button onClick={() => sendGift()}>Send another gift</Button>
                  </div>
                </div>
              </Tab>
              <Tab label='Information'>
                <div>
                  <ProductCard
                    title={orderDetail.product_name}
                    imageURL={orderDetail.product_image_uri}
                  >
                    <p></p>
                  </ProductCard>
                  <div className='m-auto mt-10 grid grid-cols-2 items-stretch gap-x-2'>
                    <div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Name:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.first_name} {orderDetail.last_name}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Email:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.email}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Company:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.company}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Title:</span>{' '}
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.title}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className='text-sm font-semibold text-gray-800'>Address</span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.company}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.address_1}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.address_2}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.city}, {orderDetail.zipcode}
                        </span>
                      </div>
                      <div>
                        <span className='text-sm font-normal text-gray-600'>
                          {orderDetail.country}
                        </span>
                      </div>
                    </div>
                  </div>
                  {!orderDetail.is_digital_gift && orderDetail.printed_message && (
                    <div className='mt-6'>
                      <div className='text-sm text-gray-500'>Your printed message</div>
                      <div className='text-base font-normal text-gray-800'>
                        &quot;{orderDetail.printed_message}&quot;
                      </div>
                    </div>
                  )}
                  {orderDetail.email_message && (
                    <div className='mt-6'>
                      <div className='text-sm text-gray-500'>Your email message</div>
                      <div className='text-base font-normal text-gray-800'>
                        &quot;{orderDetail.email_message}&quot;
                      </div>
                    </div>
                  )}
                  <div className='border-blueGray-200 flex items-center justify-center space-x-4 rounded-b p-6'>
                    <Button type='outline' rounded={true} onClick={() => sendEmail(orderDetail.order_number)}>
                      Contact Support
                    </Button>
                    <Button onClick={() => sendGift()}>Send another gift</Button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    ),
  });

  return (
    <>
      <Wrapper className='flex flex-col px-20'>
        {!loading && !userHasOrders && (
          <>
            <div className='flex items-center'>
              <p className='py-6 text-left  text-xl font-semibold'>My Orders ({myOrderCount})</p>
            </div>
            <div className='flex items-center justify-center'>
              <div className='w-1/3 items-center justify-center'>
                <div>
                  <img src='/assets/online-gifting.svg' />
                </div>
                <div className='mt-5 text-center text-2xl font-bold'>No Gifts Sent Yet</div>
                <div className='mt-5 text-center text-gray-500'>
                  It appears that no gifts have been sent to clients at the moment. Sending gifts is
                  a thoughtful gesture to strengthen business relationships.
                </div>
                <div className='mt-5 text-center'>
                  <Button onClick={sendGift}>
                    <GiftIcon></GiftIcon>Send new gift
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {userHasOrders && myOrders && (
          <>
            <div className='flex items-center'>
              <div className='flex-auto justify-self-start'>
                <p className='py-6 text-left  text-2xl font-semibold'>My Orders ({myOrderCount})</p>
              </div>
              <div className='w-72 justify-self-end'>
                <Datepicker showShortcuts={true} onChange={handleValueChange} value={dateValue} />
              </div>
            </div>
            <div className='flex justify-between py-3'>
              <div className='relative mr-5 flex h-12 w-full items-center overflow-hidden rounded-lg border border-slate-200 bg-white drop-shadow-lg focus-within:shadow-lg'>
                <SearchInput
                  onChange={searchData}
                  search={search}
                  placeholder='Search by recipient, gift or company'
                ></SearchInput>
              </div>
              <div className='w-fit'>
                <Button
                  type='solidWithBorder'
                  className='focus:none border border-slate-200 bg-white text-gray-800 drop-shadow-lg hover:bg-gray-200'
                  rounded={true}
                  onClick={() => onToggle(showFilters)}
                >
                  <ListIconBlack></ListIconBlack> <span className='text-gray-800'>Filter</span>
                </Button>
              </div>
            </div>
            {showFilters && (
              <div className='z-100 flex flex-wrap items-center pb-2'>
                {preFilterData && (
                  <div className='mr-2 w-36 items-center'>
                    <DropdownComponent
                      id='filterdropdown'
                      title='Add Filter'
                      data={preFilterData}
                      onSelect={handleFilter}
                      listHeader='CATEGORIES'
                    />
                  </div>
                )}
                {subFilterData && subFilterData.length > 0 && (
                  <div className='mr-2 w-52 items-center'>
                    <MultiDropdownWithSearch
                      id='subfilterdropdown'
                      title={selectedFilter?.name}
                      data={subFilterData}
                      parentKey={selectedFilter ? selectedFilter?.id : ''}
                      onSelect={toggleOption}
                      clearSubFilter={clearSubFilter}
                      selected={selectedSubFilter}
                      searchPlaceholder={filterTypePlaceholderMap.get(
                        selectedFilter ? selectedFilter.id : '',
                      )}
                      hideDropdownOptions={hideDropdownOptions}
                    />
                  </div>
                )}
                {selectedSubFilter && !!selectedSubFilter.length && (
                  <>
                    {selectedSubFilter.map((filter) => (
                      <div key={filter.value} className='flex items-center gap-2 py-2'>
                        <DismissBadge
                          badgeText={filter.label}
                          onDismiss={() => toggleOption(filter)}
                        />
                      </div>
                    ))}
                    <button
                      className='h-8 w-28 rounded-full bg-white px-2 py-1 text-sm font-medium leading-4 tracking-wide text-gray-800'
                      onClick={() => clearAll()}
                    >
                      Clear all
                    </button>
                  </>
                )}
              </div>
            )}
            <div>
              <Table className='relative min-w-full divide-y divide-gray-200 border-b border-gray-200 shadow drop-shadow-lg sm:rounded-lg'>
                <TableHead className='overflow-hidden bg-gray-50'>
                  <TableRow>
                    <TableHeader className='px-2 text-gray-500'>ORDER ID</TableHeader>
                    <TableHeader className='text-gray-500'>GIFT</TableHeader>
                    <TableHeader className='text-gray-500'>QTY</TableHeader>
                    <TableHeader className='text-gray-500'>
                      <button
                        className='flex items-center'
                        onClick={() => applySorting(ORDER_SORT_COL_MAP.recepient)}
                      >
                        <span className='mr-1'>NAME</span>
                        {orderBy === ORDER_SORT_COL_MAP.recepient ? (
                          orderByType === 'asc' ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )
                        ) : (
                          <CaretUpDown />
                        )}
                      </button>
                    </TableHeader>
                    <TableHeader className='text-gray-500'>
                      <button
                        className='flex items-center'
                        onClick={() => applySorting(ORDER_SORT_COL_MAP.company)}
                      >
                        <span className='mr-1'>COMPANY</span>
                        {orderBy === ORDER_SORT_COL_MAP.company ? (
                          orderByType === 'asc' ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )
                        ) : (
                          <CaretUpDown />
                        )}
                      </button>
                    </TableHeader>
                    <TableHeader className='text-gray-500'>
                      <button
                        className='flex items-center'
                        onClick={() => applySorting(ORDER_SORT_COL_MAP.status)}
                      >
                        <span className='mr-1'>STATUS</span>
                        {orderBy === ORDER_SORT_COL_MAP.status ? (
                          orderByType === 'asc' ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )
                        ) : (
                          <CaretUpDown />
                        )}
                      </button>
                    </TableHeader>
                    <TableHeader className='text-gray-500'>
                      <button
                        className='flex items-center'
                        onClick={() => applySorting(ORDER_SORT_COL_MAP.created_at)}
                      >
                        <span className='mr-1'>CREATED AT</span>
                        {orderBy === ORDER_SORT_COL_MAP.created_at ? (
                          orderByType === 'asc' ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )
                        ) : (
                          <CaretUpDown />
                        )}
                      </button>
                    </TableHeader>
                    <TableHeader className='text-gray-500'>
                      <button
                        className='flex items-center'
                        onClick={() => applySorting(ORDER_SORT_COL_MAP.updated_at)}
                      >
                        <span className='mr-1'>UPDATED AT</span>
                        {orderBy === ORDER_SORT_COL_MAP.updated_at ? (
                          orderByType === 'asc' ? (
                            <CaretUp />
                          ) : (
                            <CaretDown />
                          )
                        ) : (
                          <CaretUpDown />
                        )}
                      </button>
                    </TableHeader>
                    <TableHeader className='text-gray-500'>TRACKING NUMBER</TableHeader>
                    <TableHeader className='text-gray-500'>ACTIONS</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody className='overflow-hidden bg-white'>
                  {myOrders.map((data: any) => (
                    <TableRow key={data.handle}>
                      <TableCell className='text-gray-800'>{data.order_number}</TableCell>
                      <TableCell className='text-zinc-500'>
                        <div className='flex items-center gap-4'>
                          <Avatar square src={data.product_image_uri} className='size-12' />
                          <div>
                            <div className='font-medium text-black'>{data.product_name}</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className='text-gray-800'>{data.quantity}</TableCell>
                      <TableCell className='text-gray-800'>{data.recepient_name}</TableCell>
                      <TableCell className='text-gray-800'>{data.recepient_company}</TableCell>
                      <TableCell className='text-zinc-500'>
                        <Badge
                          color={
                            data.order_status == 'Processing'
                              ? 'blue'
                              : data.order_status == 'Shipped'
                                ? 'yellowTxtWht'
                                : data.order_status == 'Delivered'
                                  ? 'greenTxtWht'
                                  : 'red'
                          }
                        >
                          {data.order_status}
                        </Badge>
                      </TableCell>
                      <TableCell className='text-gray-800'>
                        <Tooltip message={format(data.created_at, 'MM/dd/yyyy hh:mm:ss')}>
                          {data.created_at ? format(data.created_at, 'MM/dd/yyyy') : ''}
                        </Tooltip>
                      </TableCell>
                      <TableCell className='text-gray-800'>
                        <Tooltip message={format(data.updated_at, 'MM/dd/yyyy hh:mm:ss')}>
                          {data.updated_at ? format(data.updated_at, 'MM/dd/yyyy') : ''}
                        </Tooltip>
                      </TableCell>
                      <TableCell className='text-gray-800'>{data.tracking_number}</TableCell>
                      <TableCell>
                        <span className='inline-flex items-baseline'>
                          <span onClick={() => reOrder(data.id)} className='cursor-pointer px-2'>
                            <Tooltip message='Send a new gift'>
                              <ReOrderIcon />
                            </Tooltip>
                          </span>
                          <span
                            onClick={() => openOrderDetail(data)}
                            className='cursor-pointer px-2'
                          >
                            <Tooltip message='View order'>
                              <EyeIcon />
                            </Tooltip>
                          </span>
                          <span onClick={() => sendEmail(data.order_number)} className='cursor-pointer px-2'>
                            <Tooltip message='Contact support'>
                              <SupportIcon />
                            </Tooltip>
                          </span>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {!myOrders || myOrders.length == 0 && } */}
                </TableBody>
              </Table>
              {myOrders && myOrders.length == 0 && (
                <div className='pt-5 text-center text-gray-500'>
                  {'No data found. Please change your search filters'}
                </div>
              )}
              {myOrders && myOrders.length > 0 && (
                <div className='mb-5 mt-8 flex justify-center'>
                  <Pagination
                    currentPage={currentPage}
                    lastPage={lastPage}
                    maxLength={5}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Wrapper>
      {modal}
    </>
  );
};

export default OrdersListUser;
