const MailIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='mail'>
      <path
        id='Vector'
        d='M13.3333 2.66667H2.66665C1.93027 2.66667 1.33331 3.26362 1.33331 4V12C1.33331 12.7364 1.93027 13.3333 2.66665 13.3333H13.3333C14.0697 13.3333 14.6666 12.7364 14.6666 12V4C14.6666 3.26362 14.0697 2.66667 13.3333 2.66667Z'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M14.6666 4.66667L8.68665 8.46667C8.48083 8.59562 8.24286 8.66401 7.99998 8.66401C7.7571 8.66401 7.51913 8.59562 7.31331 8.46667L1.33331 4.66667'
        stroke='#2563EB'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default MailIcon;
