import * as React from 'react';
import { getPaginationItems } from './lib/Pagination';
import PageLink from './PageLink';
import ChevronsLeft from '../Icons/ChevronsLeft';
import ChevronsRight from '../Icons/ChevronsRight';

export type Props = {
  currentPage: number;
  lastPage: number;
  maxLength: number;
  setCurrentPage: (page: number) => void;
};

export default function Pagination({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
}: Props) {
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);
  {/* <ChevronsLeft /> */}
  // <ChevronsRight />

  return (
    <nav className="flex items-center justify-center h-10 border border-gray-200 rounded-md flex-wrap bg-white overflow-hidden" aria-label="Pagination">
      {currentPage != 1 &&
        <PageLink
          isPrev={true}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <span className='inline-flex items-center justify-center font-medium text-base leading-6 text-gray-600 mr-2'>
            {"«"}
            </span>Previous
        </PageLink>
      }
      {pageNums.map((pageNum, idx) => (
        <PageLink
          key={idx}
          active={currentPage === pageNum}
          disabled={isNaN(pageNum)}
          onClick={() => setCurrentPage(pageNum)}
        >
          <span>{!isNaN(pageNum) ? pageNum : '...'}</span>
        </PageLink>
      ))}
      {currentPage != lastPage && <PageLink
        isNext={true}
        disabled={currentPage === lastPage}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        Next <span className='font-medium text-base leading-6 text-gray-600 ml-2'>
          {"»"}
          </span>
      </PageLink>
      }
    </nav>
  );
}