import { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../hooks/useOutsideClick';
import TickIcon from '../Icons/CheckIcon';
import PlusCircle from '../Icons/PlusCircleIcon';

export interface DropdownItem {
  id: string;
  name: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  data: DropdownItem[];
  selectedId?: string;
  listHeader?: string;
  onSelect?: (selectedItem: DropdownItem) => void;
}

const DropdownComponent: React.FC<DropdownProps> = ({
  id,
  title = 'Select',
  data,
  selectedId,
  listHeader,
  onSelect,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined,
  );

  const handleChange = (item: DropdownItem) => {
    setSelectedItem(item);
    onSelect && onSelect(item);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  return (
    <div ref={dropdownRef} className='relative'>
      <div className='w-36'>
        <button
          id={id}
          aria-label='Toggle dropdown'
          aria-haspopup='true'
          aria-expanded={isOpen}
          type='button'
          onClick={() => setIsOpen(!isOpen)}
          className='flex w-full items-center justify-center rounded-full bg-white px-4 py-2 text-black h-8'
        >
          <PlusCircle></PlusCircle>
          <div className='ml-2 leading-4 font-medium text-sm tracking-wide'>{title}</div>
        </button>
      </div>
      {/* Open */}
      {isOpen && (
        <div
          aria-label='Dropdown menu'
          className='max-h-68 absolute left-0 top-full z-10 mt-1 w-64 overflow-y-auto rounded-xl bg-white py-2 shadow-md'
        >
          <ul
            role='menu'
            aria-labelledby={id}
            aria-orientation='vertical'
            className='p-3 leading-10'
          >
            {listHeader != '' && <p className='ml-2 px-3 text-slate-500'>{listHeader}</p>}
            {data?.map((item) => (
              <li
                key={item.id}
                onClick={() => handleChange(item)}
                className={`ml-2 flex w-full cursor-pointer items-center justify-between px-3 pr-8 hover:bg-gray-200`}
              >
                <span>{item.name}</span>
                {selectedItem?.id === item.id && (
                  <div>
                    <TickIcon></TickIcon>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
