import AccordionGroup from '../../../components/AccordionGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { Description, Label } from '../../../catalyst/fieldset';
import { Switch } from '../../../catalyst/switch';
import * as Headless from '@headlessui/react';
import ProductCard from '../../../components/ProductCard';
import DeleteRecipientModal from './DeleteRecipientNotification';
import ConfirmRecipientForm, { AddressFields } from './ConfirmRecipientForm';
import pick from 'lodash/pick';
import { omitProp } from '../AddRecipientStep';
import omit from 'lodash/omit';
import { recipientValuesDefault, useGiftForm } from '../GiftOrderContext';
import InputFormField from '../../../components/InputFormField';
import RecipientDetailsStatic from './RecipientDetailsStatic';
import MessageDetailsStatic from './MessageDetailsStatic';
import ValidateAddress from '../AddRecipientStep/ValidateAddress';
import GiftIncomingNotification from './GiftIncomingNotification';
import { fieldRequired } from '../helper';

interface GiftOrderProps {
  recipients: any;
  giftProduct: any;
  message: any;
  setRecipients: any;
}

type FormValues = {
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  title: string;
  email: string;
  internal_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  use_same_address: boolean;
  email_message: string;
};

type MessageFormValue = {
  email_message: string;
  printed_message: string;
};

const DigitalGiftOrder = ({ handleDeleteRecipient, setDisableSubmit }: any) => {
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [addressValidateModal, setAddressValidateModal] = useState(false);
  const [isEditMessage, setEditMessage] = useState(false);

  const recipientData = useRef(recipientValuesDefault);
  const messageData = useRef({});

  const { recipients, giftProduct, messages, setMessages, setRecipients } = useGiftForm();

  const methods: any = useForm<FormValues>({});

  const methodsMessage: any = useForm<MessageFormValue>({});

  useEffect(() => {
    if (isEditMessage) methodsMessage.reset(messages[0]);
  }, [isEditMessage]);

  const handleMessageEdit = (idx: number) => {
    setEditMessage(true);
    messageData.current = messages[0];
    setDisableSubmit(true);
  };

  const handleEditMessageCancel = () => {
    setMessages((prevState: any) => {
      prevState[0] = messageData.current;
      return prevState;
    });
    setEditMessage(false);
    setDisableSubmit(false);
  };

  const handleMessageSubmit = (data: any) => {
    if (methodsMessage.formState.isValid)
      setMessages((prevState: any) => {
        prevState[0] = data;
        return prevState;
      });
    setEditMessage(false);
    setDisableSubmit(false);
  };

  const handleConfirmDelete = (indx: number) => {
    handleDeleteRecipient(indx);
    setDeleteIndex(undefined);
  };

  const handleEditClick = (idx: number) => {
    setEditIndex(idx);
    setEditMode(true);
    recipientData.current = recipients[idx];
    setDisableSubmit(true);
  };

  const handleEditCancel = () => {
    if (editIndex)
      setRecipients((prevState: any) => {
        prevState[editIndex] = recipientData.current;
        return prevState;
      });
    setEditMode(false);
    setEditIndex(undefined);
    setDisableSubmit(false);
    recipientData.current = recipientValuesDefault;
  };

  const handleRecipientSubmit = (data: any, recipientIndex: number) => {
    if (methods.formState.isValid) {
      if (messages.length > 1) {
        setMessages((prevState: any) => {
          prevState[recipientIndex].printed_message = data.printed_message;
          prevState[recipientIndex].email_message = data.email_message;
          return prevState;
        });
      }
      setRecipients((prevState: any) => {
        prevState[recipientIndex] = data;
        return prevState;
      });
    }
    validateAddress();
  };

  const submitForm = () => {
    setEditMode(false);
    setEditIndex(undefined);
    setDisableSubmit(false);
    recipientData.current = recipientValuesDefault;
  };

  const validateAddress = () => {
    setAddressValidateModal(true);
  };

  const setAddressValidatedInRecipient = (
    recipientIndex: number,
    addressValid: boolean,
    placeId: string,
    errorMessages: [],
    addressWarnings: []
  ) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex]['address_validated'] = addressValid;
      prevState[recipientIndex]['address_id'] = placeId;
      prevState[recipientIndex]['address_errors'] = errorMessages;
      prevState[recipientIndex]['address_warning'] = addressWarnings;

      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec['address_validated'] = addressValid;
            rec['address_id'] = placeId;
            rec['address_errors'] = errorMessages;
            rec['address_warning'] = addressWarnings;
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const setAddressInRecipients = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex] = {
        ...prevState[recipientIndex],
        ...newAddress,
        address_errors: [],
        address_warning: addressWarnings,
        address_validated: true,
      };

      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec = { ...rec, ...pick(prevState[0], AddressFields), address_validated: true, address_errors: [], address_warning: addressWarnings };
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const keepAddress = (recipientIndex: number, addressValid: boolean, placeId: string, errorMessages: [], addressWarnings: []) => {
    if (typeof editIndex === 'number' && editIndex > -1)
      setAddressValidatedInRecipient(editIndex, addressValid, placeId, errorMessages, addressWarnings);
    setAddressValidateModal(false);
    submitForm();
  };

  const changeAddress = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    if (typeof editIndex === 'number' && editIndex > -1)
      setAddressInRecipients(newAddress, editIndex, addressWarnings);
    setAddressValidateModal(false);
    submitForm();
  };

  const totalQty = recipients.reduce((qty: number, rec: any) => (qty + rec.quantity), 0)

  return (
    <div className='flex flex-row justify-center gap-10'>
      <div className='grow-0 basis-2/5'>
        <AccordionGroup title='Gifts' titleClass='text-xl font-semibold'>
          <ProductCard title={giftProduct.title} imageURL={giftProduct?.thumbnail_url}>
            <p>
              <span className='text-sm font-medium'>{`${totalQty} selected`}</span>
            </p>
          </ProductCard>
        </AccordionGroup>
        {messages.length === 1 && (
          <AccordionGroup
            key={messages[0].email_message}
            title={'Message'}
            isEdit={!isEditMessage && !isEditMode}
            editEnabled={isEditMessage}
            handleEdit={handleMessageEdit}
            titleClass='text-xl font-semibold'
            listLocation={1}
            handleSave={methodsMessage.handleSubmit((d: any) => handleMessageSubmit(d))}
            handleCancel={handleEditMessageCancel}
          >
            <div>
              {!isEditMessage ? (
                <div className='w-full'>
                  <div className='mt-2 font-bold text-gray-400'>Your email messages</div>
                  <p className='mt-2'>{messages[0].email_message}</p>
                </div>
              ) : (
                <form
                  id={`message_${0}`}
                  key={`message_${0}`}
                  onSubmit={methodsMessage.handleSubmit(handleMessageSubmit)}
                >
                  <InputFormField
                    name='email_message'
                    label='Your email message'
                    register={methodsMessage.register}
                    errors={methodsMessage.errors}
                    inputType={'textarea'}
                    validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
                    isRequiredLabel={false}
                  />
                </form>
              )}
              <Headless.Field className='contianer mt-8 flex items-center gap-1'>
                <div className='flex flex-row'>
                  <div className='mt-2 basis-1/5'>
                    <Switch name={`message.${0}.incoming_email`} checked={true} color='sky' />
                  </div>
                  <GiftIncomingNotification onCheckboxChange={(event: any) => { console.log('change') }} />
                </div>
              </Headless.Field>
            </div>
          </AccordionGroup>
        )}
      </div>
      <div className={messages.length > 1 ? 'basis-3/5' : 'basis-1/2'}>
        {recipients.map((recipient: any, index: number) => (
          <AccordionGroup
            key={recipient.email}
            id={'confirm_recipient' + index}
            title={
              'Recipient ' + (index + 1) + ' ' + recipient.first_name + ' ' + recipient.last_name
            }
            handleDelete={(num: number) => handleConfirmDelete(num)}
            isDelete={recipients.length > 1}
            isEdit={!isEditMode && !isEditMessage}
            editEnabled={editIndex === index}
            listLocation={index + 1}
            handleEdit={(index: number) => handleEditClick(index)}
            handleSave={methods.handleSubmit((d: any) => handleRecipientSubmit(d, index))}
            handleCancel={handleEditCancel}
          >
            <>
              {editIndex !== index ? (
                <div key={index}>
                  <RecipientDetailsStatic recipient={recipient} product={giftProduct} />
                  <div>
                    {messages.length > 1 && (
                      <MessageDetailsStatic
                        messages={messages}
                        index={index}
                        isDigitalGift={true}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className='container'>
                  <FormProvider {...methods}>
                    <form
                      id={`recipient_${index}`}
                      key={`recipient_${index}`}
                      onSubmit={methods.handleSubmit(handleRecipientSubmit)}
                    >
                      <ConfirmRecipientForm
                        recipientIndex={index}
                        isMessageForm={messages.length > 1}
                        isDigital={true}
                        recipients={recipients}
                      />
                    </form>
                  </FormProvider>
                </div>
              )}
              {messages.length > 1 && (
                <div className='container'>
                  <Headless.Field className='contianer mt-8 flex items-center'>
                    <div className='flex flex-row'>
                      <div className='mt-2 basis-16'>
                        <Switch
                          name={`message.${index}.incoming_email`}
                          checked={messages[index].incoming_email}
                          color='sky'
                          onChange={(event) => {
                            setMessages((prevState: any) => {
                              const newMessage = [...prevState];
                              newMessage[index].incoming_email = true;
                              return newMessage;
                            });
                          }}
                        />
                      </div>
                      <GiftIncomingNotification
                        onCheckboxChange={(event: any) => {
                          setMessages((prevState: any) => {
                            const newMessage = [...prevState];
                            newMessage[index].incoming_email = true;
                            return newMessage;
                          });
                        }}
                        checked={messages[index].incoming_email}
                      />
                    </div>
                  </Headless.Field>
                </div>
              )}
            </>
          </AccordionGroup>
        ))}
        {addressValidateModal && typeof editIndex === 'number' && (
          <ValidateAddress
            recipient={recipients[editIndex]}
            recipientIndex={editIndex}
            changeAddress={changeAddress}
            keepAddress={keepAddress}
            closeModal={() => setAddressValidateModal(false)}
          />
        )}
      </div>
      {showModal && deleteIndex && (
        <DeleteRecipientModal
          setShowModal={setShowModal}
          deleteRecipient={handleConfirmDelete}
          index={deleteIndex}
        />
      )}
    </div>
  );
};

export default DigitalGiftOrder;
