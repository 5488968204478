import axios from "axios";
import { useEffect } from "react";
import {
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import Preline from './components/Preline';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from "./pages/Logout";
import ContactUs from './pages/ContactUs';
import Navbar from './components/navbar';
import ReactGA from 'react-ga4';
//import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from './pages/ResetPassword';
import Template from './pages/AdminTemplate';
import QuotaRequestsList from './pages/AdminQuotaRequests';
import SendGift from './pages/SendGift';
import UsersList from './pages/AdminUsers';
import OrdersListAdmin from './pages/AdminOrders';
import ProductsListAdmin from './pages/AdminProducts';
import ProtectedRoute from './components/ProtectedRoute';
import OrdersListUser from './pages/UserOrders';
import ProductsListUser from './pages/UserProducts';
import Providers from './Providers';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  ReactGA.initialize('G-LB3NPKQWC6');

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  useEffect(() => {
    getUser();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getUser();
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const Root = () => {
    const location = useLocation();

    useEffect(() => {
      getUser();
    }, [location])

    return <>
      <Navbar />
      <Preline />
      <Outlet />
    </>
  }

  const getUser = async () => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const token = localStorage.getItem('ACCESS_TOKEN');

      if (token) {
        await axios
          .get(baseUrl + `/api/users/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            localStorage.setItem('userData', JSON.stringify(data.data));
            window.dispatchEvent(new Event('storage'));
          })
          .catch((error) => {
            if (error?.response?.data?.status === 401) {
              localStorage.removeItem('userData');
              localStorage.removeItem('ACCESS_TOKEN');
              window.location.href = '/login';
              window.location.reload();
            } else if (error?.response?.data?.status === 400) {
              localStorage.removeItem('userData');
              localStorage.removeItem('ACCESS_TOKEN');
              window.location.href = '/login';
              window.location.reload();
            }
            console.log(error.response.data.error);
          });
      }
    };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Root/>}>
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/admin/quota-request' element={<QuotaRequestsList />} />
          <Route path='/admin/template' element={<Template />} />
          <Route path='/admin/user' element={<UsersList />} />
          <Route path='/admin/order' element={<OrdersListAdmin />} />
          <Route path='/admin/product' element={<ProductsListAdmin />} />
          <Route path='/user/order' element={<OrdersListUser />} />
          <Route path='/user/product' element={<ProductsListUser />} />
          <Route path='/send-gift/*' element={<SendGift />} />
          <Route
            path='/'
            element={
              (userData?.role === 'ADMIN' || userData?.role === 'MANAGER') ? (
                <Navigate replace to='/admin/order' />
              ) : (
                <Navigate replace to='/user/order' />
              )
            }
          />
          <Route path='*' element={<h1>No Page found</h1>} />
        </Route>
      </Route>
    )
  )

  return (
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  )
}

export default App;
