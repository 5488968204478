import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { recipientValuesDefault, useGiftForm } from './GiftOrderContext';
import { Button } from '../../components/Button';
import { Button as Button1 } from '../../catalyst/button';
import { isEqual } from 'lodash';
import { OrderStatus } from '../../constants/variable';

type AbandonCartProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const AbandonCart = ({
  children = <></>
}: AbandonCartProps) => {
  const { recipients, order } = useGiftForm();
  const {use_same_address, address_validated, address_warning, ...defaultRecipientData} = recipientValuesDefault;

  const isDirtyRecipient = (recipient: any) => {
    const {use_same_address, address_validated, address_warning, ...recipientData} = recipient;

    return !isEqual(recipientData, defaultRecipientData)
  }

  const blocker = useBlocker(({ nextLocation }) => {
    const nextPath = nextLocation.pathname;

    if (order.status == OrderStatus.SUCCESS) { return false }
    if (order.status == OrderStatus.ERROR) { return false }
    if (nextPath.includes("/send-gift/")) { return false }

    return recipients.some((r: any) => (isDirtyRecipient(r)))
  });

  useEffect(() => {
    if (blocker.state == "blocked") {
      openModal()
    } else {
      closeModal()
    }
  }, [blocker])

  const handleCancel = () => {
    if(blocker.reset) {
      blocker.reset();
      closeModal()
    }
  }

  const handleContinue = () => {
    const nextPath = blocker?.location?.pathname;

    if (nextPath == '/send-gift') {
      // To reload the form and start afresh
      window.location.href = '/send-gift'
    } else if (blocker.proceed) {
      blocker.proceed()
    }
  }

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='mt-10 flex flex-row justify-center'>
          <img src={'../../assets/hot-air-balloon.svg'}></img>
        </div>
        <div className='my-4 flex flex-row justify-center'>
          <p className='text-center text-xl font-bold'>
            Warning: unsaved gift information
          </p>
        </div>
        <div className='flex flex-row justify-center'>
          <p className='text-center mx-10 px-10'>
            You have unused information on this gift form. If you leave this page now, all unsaved data will be lost. Are you sure you want to proceed?
          </p>
        </div>
        <div className='border-blueGray-200 mb-3 flex items-center justify-center space-x-4 rounded-b p-6'>
          <Button className='w-50' type='outline' rounded={true} onClick={handleContinue}>
            Send new gift
          </Button>
          <Button1
            type='submit'
            color='blue'
            className='m-2 h-12 w-100 cursor-pointer rounded-lg px-6 text-base'
            onClick={handleCancel}
          >
            Proceed with current gift form
          </Button1>
        </div>
      </>
    )
  });

  return <>
    {children}
    {modal}
  </>
}

export default AbandonCart;
