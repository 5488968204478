import ModalBackdrop from '../../../components/ModalRevisited';
import { Button } from '../../../components/Button';

const DeleteRecipientModal = ({ setShowModal, deleteRecipient, index }: any) => {
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ModalBackdrop isOpen={true} onClose={handleCloseModal} width='w-1/4'>
      <div className='flex flex-row justify-center'>
        <div className='text-center'>
          <div className='flex items-center justify-center'>
            <img src='/assets/get-a-job-promotion.svg' />
          </div>
          <div className='mt-5 text-xl font-bold'>Delete recipient confirmation</div>
          <div className='mt-5 text-base font-medium'>
            {`Warning: This action is irreversible. All data will be removed. Are you sure you want to proceed?`}
          </div>
          <div className='my-5 flex justify-around'>
            <Button type='outline' onClick={handleCloseModal}>
              Cancel and go back
            </Button>
            <Button
              onClick={() => {
                deleteRecipient(index);
                setShowModal(false);
              }}
            >
              Remove recipient
            </Button>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

export default DeleteRecipientModal;
