import { formatPhoneNumber } from '../helper';

const RecipientDetailsStatic = ({ recipient, product }: any) => {
  const multipleVariants = (product.variants.length > 1);
  const recipientVariant = product.variants.find((variant: any) => variant.id === recipient.variant_id)
  const addressErrors = (recipient.address_errors || []).concat(recipient.address_warning)

  return (
    <>
      <div className='grid grid-flow-col gap-2'>
        <div className='col-span-4'>
          <div className='mt-2 flex flex-row gap-10'>
            <div className='basis-1/4 font-bold'>Name: </div>
            <div className=''>{recipient.first_name + ' ' + recipient.last_name}</div>
          </div>
          <div className='mt-2 flex flex-row gap-10'>
            <div className='basis-1/4 font-bold'>Email: </div>
            <div className=''>{recipient.email}</div>{' '}
          </div>
          <div className='mt-2 flex flex-row gap-10'>
            {' '}
            <div className='basis-1/4 font-bold'>Phone: </div>
            <div className=''>{formatPhoneNumber(recipient.phone)}</div>
          </div>
          <div className='mt-2 flex flex-row gap-10'>
            {' '}
            <div className='basis-1/4 font-bold'>Company: </div>
            <div className=''>{recipient.company}</div>
          </div>
          <div className='mt-2 flex flex-row gap-10'>
            <div className='basis-1/4 font-bold'>Title: </div>
            <div className=''>{recipient.title ?? ''}</div>{' '}
          </div>
          { multipleVariants && (
            <div className='mt-2 flex flex-row gap-10'>
              <div className='basis-1/4 font-bold'>Variant: </div>
              <div className=''>{recipientVariant?.title ?? ''}</div>{' '}
            </div>
          )}
          <div className='mt-2 flex flex-row gap-10'>
            <div className='basis-1/4 font-bold'>Quantity: </div>
            <div className=''>{recipient.quantity ?? 1}</div>{' '}
          </div>
        </div>
        <div className='col-span-1 mt-2 font-bold'>Address: </div>
        <div className='col-span-4 mt-1'>
          <div className='mt-1'>{recipient.address_1}</div>
          <div className='mt-1'>{recipient.address_2}</div>
          <div className='mt-1'>{`${recipient.city}, ${recipient.state}, ${recipient.zipcode}`}</div>
          <div className='mt-1'>{recipient.country}</div>
        </div>
      </div>
      { addressErrors.length > 0 &&
        <div className="bg-orange-100 border border-orange-400 px-4 py-3 rounded relative mt-2" role="alert">
          <strong className="font-bold">Please make sure the address is correct before you proceed</strong>
          <ul className="list-disc list-inside mt-2">
            { addressErrors.map((message: string, index: number) => (
              <li key='number'>{message}</li>
            ))}
          </ul>
        </div>
      }
    </>
)};

export default RecipientDetailsStatic;
