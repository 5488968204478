import ModalBackdrop from '../../../components/ModalRevisited';
import { Button } from '../../../components/Button';
import Info from '../../../components/Info';
import { useState } from 'react';
import { Switch } from '../../../catalyst/switch';

const GiftRecipientNotificationModal = ({ setShowModal, handleUpdate, disabled=false, isChecked=true }: any) => {
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ModalBackdrop
      title='Gift Recipient Email Notification'
      isOpen={true}
      onClose={handleCloseModal}
    >
      <div className='my-3'>
        <Info>
          <p>
            We will never reveal what the gift is or what the cost is to a recipient unless you
            mention this in your custom notes.
          </p>
        </Info>
      </div>
      <div className='flex flex-col'>
        <div className='mt-4 flex flex-row items-start rounded-md border-2 border-solid border-gray-100 px-1 py-4'>
          <img src='/assets/shopping-basket.svg' />
          <div className='ml-4 w-4/5'>
            <div className='text-base font-medium text-gray-800'>Gift Incoming</div>
            <div className='text-sm font-medium text-gray-500'>
              An inital confirmatioon email notification is sent automatically, unless you disable
              it on the order confirmation page.
            </div>
          </div>
          <div>
            <Switch
              name={`incoming_email`}
              checked={isChecked}
              color='sky'
              onChange={(checked: any) => {
                handleUpdate(checked);
              }}
              disabled={disabled}
            />
          </div>
        </div>

        <div className='mt-4 flex flex-row items-start rounded-md border-2 border-solid border-gray-100 px-1 py-4'>
          <img src='/assets/ship.svg' />
          <div className='ml-4 w-4/5'>
            <div className='text-base font-medium text-gray-800'>Gift Shipped</div>
            <div className='text-sm font-medium text-gray-500'>
              Sent with a tracking number to notify the recipient that the gift is on their way.
            </div>
          </div>
        </div>

        <div className='mt-4 flex flex-row items-start rounded-md border-2 border-solid border-gray-100 px-1 py-4'>
          <img src='/assets/gift2.svg' />
          <div className='ml-4 w-4/5'>
            <div className='text-base font-medium text-gray-800'>Gift Delivered</div>
            <div className='text-sm font-medium text-gray-500'>
              Sent to notify the recipient that purchase items are ready for pickup.
            </div>
          </div>
        </div>
      </div>
      <div className='item-center my-5 flex justify-center'>
        <Button onClick={handleCloseModal}>Got it!</Button>
      </div>
    </ModalBackdrop>
  );
};

export default GiftRecipientNotificationModal;
