import React, { useState } from 'react';
const Tabs = ({ children }: any) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  const handleClick = (e: any, newActiveTab: any) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
  };
  return (
    <div className='mx-autoborder-b w-full border-gray-200 dark:border-neutral-700'>
      <div>
        {children.map((child: any) => (
          <button
            key={child.props.label}
            className={`${
              activeTab === child.props.label ? 'border-b-2 border-blue-600 text-blue-600' : ''
            } mr-8 flex-1 py-2 text-2xl font-semibold`}
            onClick={(e) => handleClick(e, child.props.label)}
          >
            {child.props.label} {child.props.count ? '(' + child.props.count + ')' : ''}
          </button>
        ))}
      </div>
      <div className='py-4'>
        {children.map((child: any) => {
          if (child.props.label === activeTab) {
            return <div key={child.props.label}>{child.props.children}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};
const Tab = ({ label, count, children }: any) => {
  return <div className='hidden'>{children}</div>;
};
export { Tabs, Tab };
