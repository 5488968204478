const EyeIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.33337 7.99992C1.33337 7.99992 3.33337 3.33325 8.00004 3.33325C12.6667 3.33325 14.6667 7.99992 14.6667 7.99992C14.6667 7.99992 12.6667 12.6666 8.00004 12.6666C3.33337 12.6666 1.33337 7.99992 1.33337 7.99992Z'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EyeIcon;
