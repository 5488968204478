import axios from 'axios';
import { cx } from 'cva';
import { useForm } from 'react-hook-form';
import { useModal } from '../../hooks/useModal';
import InputFormField from '../../components/InputFormField';
import { Button } from '../../components/Button';
import { Button as Button1 } from '../../catalyst/button';
import { useToast } from '../../components/Toast/ToastProvider';
import ExclemationCircleFill from '../../components/Icons/ExclamationCircleFill';
import CaIcon from '../../components/Icons/CaIcon';
import UsIcon from '../../components/Icons/UsIcon';
import formatDate from '../../utils/formatDate';

interface ApproveProps {
  quotaRequest: any,
  onApprove: () => void,
}

type FormValues = {
  quotaUs: number;
  quotaCa: number;
  validTill: string;
};

const defaultAddTemplateValues = {
  quotaUs: 0,
  quotaCa: 0,
  validTill: '',
}

const Approve = ({ quotaRequest, onApprove }: ApproveProps) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('ACCESS_TOKEN');
  const toast = useToast();

  const TODAY = new Date();
  const minDate = TODAY.toLocaleString('lt-LT').split(' ')[0]
  const endOfMonthDate = new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0)
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const userUSQuota = quotaRequest.user_quota.US?.total_quota || 0;
  const userCAQuota = quotaRequest.user_quota.CA?.total_quota || 0;
  const userUSBalance = quotaRequest.user_quota.US?.balance || 0;
  const userCABalance = quotaRequest.user_quota.CA?.balance || 0;

  const getErrorClass = (errors: any, name: string) => {
    return errors && errors[name] ? 'border-red-500 shadow-md shadow-red-200' : 'border-gray-200';
  };

  const getClass = (name: string) => {
    const classes = cx('block w-full rounded-lg px-4 py-3 text-sm', getErrorClass(errors, name));
    return classes;
  };

  const {
    register,
    reset,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultAddTemplateValues,
  });

  const onModalClose = () => {
    reset(defaultAddTemplateValues);
    closeModal();
  };

  function openApproveModal(data: any): void {
    setValue('validTill', formatDate(endOfMonthDate))
    openModal();
  }

  const getUser = async () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const res = await axios
      .get(baseUrl + `/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        localStorage.setItem('userData', JSON.stringify(data.data));
        window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        } else if (error?.response?.data?.status === 400) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
          toast?.pushError(error?.response?.data?.error, 5000);
        }
      });
  };

  const handleApproveQuotaRequest = async (data: any) => {
    const formValidation = trigger();
    formValidation.then(async (val) => {
      if (val) {
        const quotaUS = Number(getValues().quotaUs);
        const quotaCA = Number(getValues().quotaCa);

        if (!quotaUS && !quotaCA) {
          toast?.pushError('Please select atleast 1 quota to approve!', 5000);
        } else {
          await approveQuotaRequest(data);
        }
      }
    });
  }

  const approveQuotaRequest = async (data: any) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const approvalData = {
      quotaUS: getValues().quotaUs,
      quotaCA: getValues().quotaCa,
      validTill: getValues().validTill,
    }
    axios
      .put(baseUrl + '/api/admin/quota/request/' + quotaRequest.id + '/approve', approvalData, config)
      .then((_response) => {
        onApprove()
        onModalClose()
        if(quotaRequest.sales_rep_email === userData.email) {
          getUser();
        }
        toast?.pushSuccess('Quota request approved successfully!', 5000);
      })
      .catch((error) => {
        toast?.pushError('Quota request approve failed! Please try again.', 5000);
        console.log(error.response.data.error);
      });
  };

  const { modal, openModal, closeModal } = useModal({
    children: (
      <>
        <div className='relative'>
          <div className='flex justify-end'>
            <button
              type='button'
              className='pr-5 pt-3 text-xl font-light'
              onClick={() => onModalClose()}
            >
              &times;
            </button>
          </div>
          <div className='flex flex-row justify-center'>
            <p className='text-center text-xl font-bold'>
              Quota Request
            </p>
          </div>
          <form onSubmit={handleSubmit(handleApproveQuotaRequest)} className='w-full rounded px-8 pb-0 pt-6'>
            <div className='py-2'>
              <label className="mb-2 block text-sm font-medium dark:text-white">User</label>
              <p>{`${quotaRequest.sales_rep_name} (${quotaRequest.sales_rep_email})`}</p>
            </div>
            <div className='py-2'>
              <label className="mb-2 block text-sm font-medium dark:text-white">Reason</label>
              <p>{quotaRequest.reason}</p>
            </div>
            <div className='columns-2 py-2 mb-2'>
              <div>
                <label className="mb-2 block text-sm font-medium dark:text-white">Current Allocation</label>
                <div className="flex items-center gap-2">
                  <CaIcon />
                  <p>{`${userCAQuota} (${userCABalance} remaining)`}</p>
                </div>
              </div>
              <div>
                <label className="mb-2 block text-sm font-medium dark:text-white">&nbsp;</label>
                <div className="flex items-center gap-2">
                  <UsIcon />
                  <p>{`${userUSQuota} (${userUSBalance} remaining)`}</p>
                </div>
              </div>
            </div>
            <div className='columns-2 py-2'>
              <div>
                <label className='mb-2 block text-sm font-medium dark:text-white'>Add Allocation</label>
                <div className='break-inside-avoid-column'>
                  <div className='relative flex items-center'>
                    {errors && errors['quotaUs'] && (
                      <div className='pointer-events-none absolute inset-y-0 right-10 top-1 flex items-center pl-3'>
                        <ExclemationCircleFill />
                      </div>
                    )}
                    <input
                      id='quotaCa'
                      type='text'
                      inputMode='numeric'
                      min='0'
                      {...register('quotaCa', {
                        required: 'This field is required',
                        min: { value: 0, message: 'Quota must be greater than or equal to 0' },
                      })}
                      className={`block w-full rounded-md border-gray-200 bg-white py-3 pl-10 pr-4 text-sm drop-shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${getClass('quotaCa')}`}
                      // onChange={(ev) => handleChange(ev)}
                    />
                    <div className='absolute left-4'>
                      <CaIcon />
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    {errors && errors['quotaCa'] && (
                      <p className='mt-2 text-xs text-red-500'>{errors['quotaCa'].message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className='mb-2 block text-sm font-medium dark:text-white'>&nbsp;</label>
                <div className='break-inside-avoid-column'>
                  <div className='relative flex items-center'>
                    {errors && errors['quotaUs'] && (
                      <div className='pointer-events-none absolute inset-y-0 right-10 top-1 flex items-center pl-3'>
                        <ExclemationCircleFill />
                      </div>
                    )}
                    <input
                      id='quotaUs'
                      type='text'
                      inputMode='numeric'
                      min='0'
                      {...register('quotaUs', {
                        required: 'This field is required',
                        min: { value: 0, message: 'Quota must be greater than or equal to 0' },
                      })}
                      className={`block w-full rounded-md border-gray-200 bg-white py-3 pl-10 pr-4 text-sm drop-shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 ${getClass('quotaUs')}`}
                      // onChange={(ev) => handleChange(ev)}
                    />
                    <div className='absolute left-4'>
                      <UsIcon />
                    </div>
                  </div>
                  <div className='flex flex-row'>
                    {errors && errors['quotaUs'] && (
                      <p className='mt-2 text-xs text-red-500'>{errors['quotaUs'].message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='py-3'>
              <InputFormField
                name='validTill'
                label='Valid Until'
                type='date'
                min={minDate}
                isRequired={true}
                isRequiredLabel
                register={register}
                validation={{ required: 'This field is required' }}
                errors={errors}
              />
            </div>
            <div className='border-blueGray-200 mb-3 flex items-center justify-center space-x-4 rounded-b p-6'>
              <Button type='outline' rounded={true} onClick={() => onModalClose()}>
                Cancel
              </Button>
              <Button1
                type='submit'
                color='blue'
                className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
              >
                Approve Request
              </Button1>
            </div>
          </form>
        </div>
      </>
    ),
  });

  return <>
    <span
      className='item cursor-pointer text-gray-500'
      onClick={() => openApproveModal(quotaRequest)}
    >
      Approve
    </span>
    {modal}
  </>
}

export default Approve;
