import { ReactNode, useState } from 'react';
import InfoIcon from './Icons/InfoIcon';
import CloseCrossIcon from './Icons/CloseCross';
import { color } from 'framer-motion';

interface InfoProps {
  children: ReactNode;
  onClose?: () => void;
  color?: string;
}

const Info = ({ onClose, color, children }: InfoProps) => {
  const [show, setShow] = useState(true);

  const closeInfo = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  const getClasses = (color: string | undefined) => {
    switch (color) {
      case 'yellow':
        return 'flex flex-row gap-1 rounded-lg border-yellow-500 bg-yellow-100 px-3 py-5 text-base text-yellow-700';
      case 'red':
        return 'flex flex-row gap-1 rounded-lg border-red-500 bg-red-100 px-3 py-5 text-base text-red-700';
      default:
        return 'flex flex-row gap-1 rounded-lg border-yellow-500 bg-yellow-100 px-3 py-5 text-base text-yellow-700';
    }
  };

  return (
    <>
      {show && (
        <div className={getClasses(color)} role='alert'>
          <p className='mt-1 flex-initial'>
            <InfoIcon strokeColor={color === 'red' ? '#991B1B' : '#854D0E'} />
          </p>
          <p className='mx-2 flex-1'>{children}</p>
          <p className='-mt-3 flex-initial cursor-pointer' onClick={() => closeInfo()}>
            <CloseCrossIcon />
          </p>
        </div>
      )}
    </>
  );
};

export default Info;
