import { Outlet } from 'react-router-dom';
import { cn } from '../lib/util';
import Info from './Info';

type WrapperProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const Wrapper = ({ children, className, ...props }: WrapperProps) => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  return (
    <main
      style={{ backgroundColor: '#F8FAFC' }}
      className={cn(['flex min-h-screen w-full flex-col pt-20', className])}
      {...props}
    >
      {children}
    </main>
  );
};

export default Wrapper;
