const CloseCrossIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='x'>
      <g id='Vector'>
        <path d='M12 4L4 12L12 4Z' fill='#EAB308' />
        <path d='M12 4L4 12' stroke='#EAB308' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <g id='Vector_2'>
        <path d='M4 4L12 12L4 4Z' fill='#EAB308' />
        <path d='M4 4L12 12' stroke='#EAB308' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </g>
  </svg>
);

export default CloseCrossIcon;
