const EditIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.66675 9V2.66666C2.66675 2.31304 2.80722 1.9739 3.05727 1.72385C3.30732 1.4738 3.64646 1.33333 4.00008 1.33333H9.66675L13.3334 5V13.3333C13.3334 13.687 13.1929 14.0261 12.9429 14.2761C12.6928 14.5262 12.3537 14.6667 12.0001 14.6667H8.33341'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.33325 1.33333V5.33333H13.3333'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.94675 8.40667C7.07676 8.27666 7.2311 8.17353 7.40096 8.10317C7.57083 8.03281 7.75289 7.9966 7.93675 7.9966C8.12061 7.9966 8.30267 8.03281 8.47253 8.10317C8.6424 8.17353 8.79674 8.27666 8.92675 8.40667C9.05676 8.53668 9.15989 8.69102 9.23025 8.86088C9.30061 9.03075 9.33682 9.21281 9.33682 9.39667C9.33682 9.58053 9.30061 9.76259 9.23025 9.93245C9.15989 10.1023 9.05676 10.2567 8.92675 10.3867L5.30008 14L2.66675 14.6667L3.32675 12.0333L6.94675 8.40667Z'
      stroke='#2563EB'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EditIcon;
