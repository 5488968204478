const MessageDetailsStatic = ({ messages, index, isDigitalGift }: any) => {
  return (
    <>
      <div className='mt-5'>
        {!isDigitalGift && (
          <>
            <div className='mt-2 font-bold text-gray-400'>Your printed message</div>
            <p className='mt-2'>{messages[index].printed_message}</p>
          </>
        )}
        {!!messages[index]?.email_message.trim() && (
          <div className='w-full'>
            <div className='mt-2 font-bold text-gray-400'>Your email messages</div>
            <p className='mt-2'>{messages[index].email_message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MessageDetailsStatic;
