import { useState } from 'react';
import { cx } from 'cva';
import TrashIcon from './Icons/TrashIcon';
import { Button } from '../components/Button';
import ChevronDownCircle from './Icons/ChevronDownCircle';

type AccordionpProps = {
  children: React.ReactNode;
  titleBadge?: React.ReactNode;
  title: string;
  id?: string;
  active?: boolean;
  handleDelete?: (num: number) => void;
  handleClear?: (num: number) => void;
  handleEdit?: (num: number) => void;
  handleSave?: () => void;
  handleCancel?: () => void;
  isDelete?: boolean;
  isClear?: boolean;
  listLocation?: number;
  hideChevron?: boolean;
  className?: string;
  titleClass?: string;
  isEdit?: boolean;
  editEnabled?: boolean;
};

const AccordionGroup = ({
  children,
  titleBadge,
  title,
  id,
  active = true,
  isClear = false,
  isDelete = false,
  isEdit = false,
  handleDelete,
  handleClear,
  handleEdit,
  handleSave,
  handleCancel,
  listLocation,
  hideChevron,
  className = '',
  titleClass = '',
  editEnabled,
}: AccordionpProps) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(active);

  const classes = cx('bg-white p-4 rounded-lg mb-5', className);

  const index = Number(listLocation) - 1;

  const handleEditClick = () => {
    if (handleEdit) handleEdit(index);
  };

  const handleSaveChanges = () => {
    if (handleSave) handleSave();
  };

  const handleCancelSave = () => {
    if (handleCancel) handleCancel();
  };

  return (
    <div className={classes}>
      <h2 className='flex justify-between'>
        <div className={titleClass}>
          {title} {titleBadge}
        </div>
        <div className=''>
          <div className='flex justify-around'>
            {accordionOpen && listLocation && (
              <>
                {isEdit && !editEnabled && (
                  <div
                    className='mr-5 mt-2 text-sm text-gray-500 hover:cursor-pointer'
                    onClick={handleEditClick}
                  >
                    Edit
                  </div>
                )}
                {editEnabled && (
                  <div className='mr-5 flex justify-between'>
                    <Button size='small' type='solid' onClick={handleSaveChanges}>
                      Save changes
                    </Button>
                    <Button size='small' type='link' onClick={handleCancelSave}>
                      Cancel
                    </Button>
                  </div>
                )}
                {isClear && (
                  <div
                    className='mr-5 mt-2 text-sm text-gray-500 hover:cursor-pointer'
                    onClick={() => handleClear && handleClear(index)}
                  >
                    Clear
                  </div>
                )}
                {isDelete && !editEnabled && (
                  <div
                    className='mr-5 mt-1 hover:cursor-pointer'
                    onClick={() => handleDelete && handleDelete(index)}
                  >
                    <TrashIcon />
                  </div>
                )}
              </>
            )}
            {!hideChevron && (
              <div>
                <button
                  className='flex w-full items-center justify-between py-1 text-left font-semibold'
                  onClick={(e) => {
                    e.preventDefault();
                    setAccordionOpen(!accordionOpen);
                  }}
                  aria-expanded={accordionOpen}
                >
                  <ChevronDownCircle />
                </button>
              </div>
            )}
          </div>
        </div>
      </h2>

      <div
        id={`accordion-text-${id}`}
        role='region'
        aria-labelledby={`accordion-title-${id}`}
        className={`mt-5 grid overflow-hidden text-sm text-slate-600 transition-all duration-300 ease-in-out ${accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}
      >
        <div className='overflow-hidden'>
          <p className='pb-3'>{children}</p>
        </div>
      </div>
    </div>
  );
};

export default AccordionGroup;
