import { useState, useEffect } from 'react';
import { useGiftForm, defaultMessageValues } from '../GiftOrderContext';
import { useForm } from 'react-hook-form';
import AccordionGroup from '../../../components/AccordionGroup';
import InputFormField from '../../../components/InputFormField';
import { Button } from '../../../components/Button';
import FileEditIcon from '../../../components/Icons/FileEdit';
import { fieldRequired } from '../helper';

type DigitalFormValues = {
  email_message: string;
};

export default function TemplateForm({
  isMultipleTemplate,
  messages,
  recipient,
  messageIndex = 0,
  onSubmit,
  validateForm,
  onValidationComplete,
  handleCustomizeForEachClick,
}: any) {
  const { recipients, giftProduct, setMessages } = useGiftForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm<DigitalFormValues>({
    defaultValues: messages[messageIndex] || defaultMessageValues,
  });

  useEffect(() => {
    if (validateForm) {
      const formValidation = trigger();
      formValidation.then((val) => {
        onValidationComplete(val, messageIndex);
      });
    }
  }, [validateForm, onValidationComplete]);

  const resetForm = () => {
    reset({ email_message: '' });
    const formValues = getValues();
    onSubmit(formValues, messageIndex);
  };

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    const fieldType = event.target.type;
    const fieldValue = fieldType === 'checkbox' ? event.target.checked : event.target.value;
    setValue(fieldName, fieldValue);
    const formValues = getValues();
    onSubmit(formValues, messageIndex);
  };

  return (
    <>
      <div className='grid-row grid w-2/5 gap-4'>
        <form name='send-gift-form'>
          <div className='ml-4 mt-4'>
            <AccordionGroup
              title={
                isMultipleTemplate || recipients.length === 1
                  ? `Recipient ${messageIndex + 1}. ${recipient.first_name + ' ' + recipient.last_name}`
                  : `Recipients(${recipients.length})`
              }
              handleClear={(num: number) => resetForm()}
              hideChevron={!isMultipleTemplate}
              isClear={true}
              listLocation={messageIndex + 1}
            >
              <div className='mt-4'>
                <InputFormField
                  name='email_message'
                  label='Your email message'
                  register={register}
                  errors={errors}
                  onBlur={handleBlur}
                  inputType={'textarea'}
                  validation={{ required: 'This field is required', validate: { present: fieldRequired } }}
                  isRequiredLabel={false}
                />
              </div>

              {recipients.length > 1 && !isMultipleTemplate && (
                <div className='container mt-5 flex justify-center'>
                  <Button type='outline' onClick={handleCustomizeForEachClick}>
                    <FileEditIcon />
                    Customise template for each recipient
                  </Button>
                </div>
              )}
            </AccordionGroup>
          </div>
        </form>
      </div>
    </>
  );
}
