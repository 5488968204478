const InfoIcon = ({ strokeColor }: any) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='info' clipPath='url(#clip0_1825_23057)'>
      <path
        id='Vector'
        d='M8.00001 10.6666V7.99998M8.00001 5.33331H8.00668M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 8.00001 14.6666C4.31811 14.6666 1.33334 11.6819 1.33334 7.99998C1.33334 4.31808 4.31811 1.33331 8.00001 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998Z'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1825_23057'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
