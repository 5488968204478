import { useEffect, useRef, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import useOutsideClick from '../hooks/useOutsideClick';
import TickIcon from '../Icons/CheckIcon';
import SearchIcon from '../Icons/SearchIcon';

export interface UserFilterQuota {
    key: string;
    label: string;
    options: UserFilterQuotaOptions[];
    parentKey: string;
}

export interface UserFilterQuotaOptions {
    label: string;
    value: number;
}

interface UserQuotaProps {
    id: string;
    title?: string;
    parentKey: string;
    data: UserFilterQuota[];
    selected?: UserFilterQuota[];
    onSelect: (selectedItem: UserFilterQuota, key: string, updatedValue: string) => void;
    clearSubFilter: (parentKey: string) => void;
    hideDropdownOptions?: boolean
}

const DropdownQuotaComponent: React.FC<UserQuotaProps> = ({
    id,
    title = 'Select',
    parentKey,
    selected,
    data,
    onSelect,
    clearSubFilter,
    hideDropdownOptions,
}: UserQuotaProps) => {
    useEffect(() => {
        if (!hideDropdownOptions) {
            setIsOpen(true);
        }
    }, [data])
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<UserFilterQuota[]>([]);
    let isOpenChevronClass = ''
    if (isOpen) {
        isOpenChevronClass = 'rotate-180'
    }
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });

    useEffect(() => {
        if (selected && selected.length > 0) {
            const selectedValueMap = new Map<string, number>();
            for (const selectedItem of selected) {
                for (const opt of selectedItem.options) {
                    const key = `${selectedItem.key}-${opt.label}`
                    selectedValueMap.set(key, opt.value);
                }
            }
            for (const selectedItem of data) {
                for (const opt of selectedItem.options) {
                    const key = `${selectedItem.key}-${opt.label}`
                    const selectedValue = selectedValueMap.get(key);
                    if (selectedValue) {
                        opt.value = selectedValue
                    }
                }
            }
            setDataList(data);
        } else {
            for (const selectedItem of data) {
                for (const opt of selectedItem.options) {
                    const key = `${selectedItem.key}-${opt.label}`
                    opt.value = 0
                }
            }
            setDataList(data);
        }

    }, [selected])

    const getSelectedUserQuotaObj = (list: UserFilterQuota[] | undefined, key: string, label: string) => {
        let userQuotaObj: UserFilterQuotaOptions = {
            label: '',
            value: 0,
        };
        if (list) {
            for (const arrayItem of list) {
                for (const opt of arrayItem.options) {
                    if (opt.label == label && arrayItem.key == key) {
                        userQuotaObj = opt
                        break;
                    }
                }
            }
        }

        return userQuotaObj;
    }

    return (
        <div ref={dropdownRef} className='relative'>
            <div className='w-52'>
                <button
                    id={id}
                    aria-label='Toggle dropdown'
                    aria-haspopup='true'
                    aria-expanded={isOpen}
                    type='button'
                    onClick={() => setIsOpen(!isOpen)}
                    className='flex w-full items-center justify-center rounded-full bg-gray-100 py-2 text-black h-8'
                >
                    <div className='ml-2 mr-4 leading-4 font-medium text-sm tracking-wide'>{title}</div>
                    <GoChevronDown
                        size={20}
                        className={`transform duration-500 ease-in-out ${isOpenChevronClass}`}
                    />
                </button>
            </div>
            {/* Open */}
            {isOpen && (
                <div aria-label='Sub-Dropdown menu' className='absolute bg-white w-80 max-h-68 overflow-y-auto py-2 rounded-xl shadow-md z-10 top-full left-0 mt-1'>
                    {dataList?.map((item) => (
                        <div className='px-5 ' key={item.key}>
                            <p className='px-3 ml-2  text-slate-500 uppercase'>{item.label}</p>

                            {item?.options?.map((opt) => {
                                return (
                                    <div className='my-3 px-3  ml-2' key={`${item.key}-${opt.label}`}>
                                        <label className='text-black uppercase'>{opt.label}</label>
                                        <input
                                            className='peer h-full w-full pr-2 text-sm text-gray-700 outline-none rounded-lg border-gray-200 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                                            type='number'
                                            min="0"
                                            id='quotadropdownsearchinput'
                                            name='quotadropdownsearchinput'
                                            value={opt.value}
                                            onChange={(e) => onSelect(item, opt.label, e.target.value)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                    <button type='button' className='ml-6 mb-2 text-slate-500' onClick={() => clearSubFilter(parentKey)} >Clear all</button>
                </div>
            )}
        </div>
    );
};

export default DropdownQuotaComponent;